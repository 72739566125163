import { Form, FormInstance, Input, message } from "antd";
import TextEditor from "common/TextEditor";
import React from "react";
import { addHomeManageQuery } from "../query";
import { UploadFile } from "antd/lib";
import UploadComponent from "common/Upload";
import { useQueryClient } from "react-query";
import { QUERY_CATEGORY_ADMIN } from "helpers";

type TProps = {
 form: FormInstance;
 onCloseModal: () => void;
 imageListUpdate?: UploadFile[];
};

type TFormValues = {
 redirect: string;
 buttonText: string;
 html: string;
};

const EditContentAboutUs = ({
 form,
 onCloseModal,
 imageListUpdate = [],
}: TProps) => {
 const [value, setValue] = React.useState<string>("");
 const [imageList, setImageList] =
  React.useState<UploadFile[]>(imageListUpdate);
 const queryClient = useQueryClient();

 const onFinish = async (values: TFormValues) => {
  if (imageList.length === 0) return message.warning("Vui lòng tải hình ảnh");
  const parameters = {
   ...values,
   imageUrl: imageList[0].name,
  };
  const response: any = await addHomeManageQuery(parameters);
  if (response.type === "error") return message.error(response.error);
  message.success("Cập nhật nội dung thành công");
  queryClient.invalidateQueries(QUERY_CATEGORY_ADMIN);
  setImageList([]);
  onCloseModal();
  window.location.reload();
 };
 return (
  <>
   <Form form={form} onFinish={onFinish} layout='vertical'>
    <Form.Item label='Tiêu đề' name='title' rules={[{ required: true }]}>
     <Input />
    </Form.Item>
    <Form.Item
     label='Chuyển trang'
     name='redirect'
     rules={[{ required: true }]}
    >
     <Input addonBefore='https://dtsolar.com.vn' />
    </Form.Item>
    <Form.Item
     rules={[{ required: true }]}
     label='Text Button'
     name='buttonText'
    >
     <Input />
    </Form.Item>
    <Form.Item rules={[{ required: true }]} label='Tóm tắt' name='summary'>
     <TextEditor
      onChange={setValue}
      value={form.getFieldsValue().summary || ""}
     />
    </Form.Item>
    <Form.Item
     rules={[{ required: true }]}
     label='Nội dung trang'
     name='contentPages'
    >
     <TextEditor
      onChange={setValue}
      value={form.getFieldsValue().contentPages || ""}
     />
    </Form.Item>
    <Form.Item
     rules={[{ required: true }]}
     label='Hình ảnh (thumbnail)'
     name='contentPages'
    >
     <UploadComponent
      multiple={false}
      resultUploadImage={setImageList}
      imageList={imageList}
     />
    </Form.Item>
   </Form>
  </>
 );
};

export default EditContentAboutUs;
