import infoCompany from "assets/json/Infomation";
import {
 ButtonFloatPhoneStyled,
 ButtonFloatZaloStyled,
} from "assets/styles/page/buttonFloat/_ButtonFloat";
import ContactInfo from "common/ContactInfo";
import { IconPhone, SimpleIconsZalo } from "icons";
import React from "react";
import { useNavigate } from "react-router-dom";

const ButtonFloatZalo = () => {
 return (
  <ButtonFloatZaloStyled>
   <a
    href={`http://zalo.me/${infoCompany.phoneNumber}`}
    className='pulsate-bck'
   >
    <SimpleIconsZalo className={"icon"} />
   </a>
  </ButtonFloatZaloStyled>
 );
};

export default ButtonFloatZalo;
