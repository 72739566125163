import { StyleBreadcrumb } from "assets/styles";
import React from "react";
import { NavLink } from "react-router-dom";
export type TypeBreadcrumb = {
 title: string;
 href: string | null;
};

type TProps = {
 breadcrumb: TypeBreadcrumb[];
};
const Breadcrumb = ({ breadcrumb }: TProps) => {
 return (
  <StyleBreadcrumb>
   <div className='breadcrumb'>
    <ul>
     {breadcrumb?.map((item: TypeBreadcrumb, index: number) => (
      <li key={index} className={`${item.href ? "navlink" : "active"}`}>
       {item.href ? (
        <div className='link'>
         <NavLink to={`${item?.href}`}>{item?.title}</NavLink>
         <i className='fa-solid fa-link'></i>
        </div>
       ) : (
        item?.title
       )}
      </li>
     ))}
    </ul>
   </div>
  </StyleBreadcrumb>
 );
};

export default Breadcrumb;
