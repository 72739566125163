import { StyledDetailProduct } from "assets/styles/page";
import Breadcrumb, { TypeBreadcrumb } from "common/Breadcrumb";
import Container from "common/Container";
import { DetailDataProduct } from "data";
import React from "react";
import CarouselDetailProduct from "./CarouselDetailProduct";
import InformationProduct from "./InformationDetailProduct";
import Description from "./Description";
import RelatedProducts from "../RelatedProducts";
import BrandsComponent from "components/Home/HomeComponent/Brands.component";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { TProduct } from "@types";
import _ from "lodash";
import { useLocation, useParams } from "react-router-dom";
import LoadingV2 from "common/V2/LoadingV2";
import { Empty } from "antd";

export interface TImagesProduct {
 urlImage: string;
 active: boolean;
}

export interface TDetailProduct {
 id: string;
 productCode: string;
 breadcrumb: TypeBreadcrumb[];
 title: string;
 images: TImagesProduct[];
 trademark: string;
 type: string;
 category: string;
 tags: string[];
 desc: string;
}
const Component = () => {
 const { product, isLoading } = useSelector(
  (state: RootState) => state.product
 );
 const [dataProduct, setDataProduct] = React.useState<TProduct>();
 const [breadcrumb, setBreadcrumb] = React.useState<TypeBreadcrumb[]>();
 const param = useParams();

 const selectProductBySLug = (products: TProduct[]) => {
  const result = _.filter(products, { productHref: param.slug });
  if (result.length > 0) {
   setDataProduct(result[0]);
   setBreadcrumb([
    {
     title: "Trang chủ",
     href: "/",
    },
    {
     title: "Sản phẩm",
     href: "/san-pham",
    },
    {
     title: result[0].productName,
     href: null,
    },
   ]);
  }
 };

 const scrollToTop = () => {
  window.scrollTo({
   top: 0,
   left: 0,
   behavior: "smooth",
  });
 };

 React.useEffect(() => {
  scrollToTop();
 }, [param.slug]);

 React.useEffect(() => {
  if (!isLoading) {
   selectProductBySLug(product);
  }
 }, [product, isLoading, param.slug]);

 return (
  <StyledDetailProduct>
   <Container>
    {dataProduct && breadcrumb ? (
     <>
      <Breadcrumb breadcrumb={breadcrumb} />
      <div className='container'>
       <div className='content'>
        <div className='product__detail'>
         <CarouselDetailProduct images={dataProduct.productImages} />
         <InformationProduct product={dataProduct} />
        </div>
        <Description description={dataProduct.productDescription} />

        <RelatedProducts />
        <div className='brands'>
         <BrandsComponent />
        </div>
       </div>
      </div>
     </>
    ) : (
     <LoadingV2 />
    )}
   </Container>
  </StyledDetailProduct>
 );
};

export default Component;
