import { Col, Flex, Form, Input, Row, Select } from "antd";
import { SearchProjectStyled } from "assets/styles/V2/Projects/Search.styled";
import Container from "common/Container";
import React from "react";

const Search = () => {
 const optionsType = [
  {
   label: "Tất cả",
   value: "all",
  },
  {
   label: "Hộ gia đình",
   value: "ho-gia-dinh",
  },
  {
   label: "Doanh nghiệp",
   value: "doanh-nghiep",
  },
  {
   label: "Nông nghiệp",
   value: "nong-nghiep",
  },
 ];
 const optionsSolution = [
  {
   label: "Tất cả",
   value: "all",
  },
  {
   label: "Hệ thống hòa lưới",
   value: "he-thong-hoa-luoi",
  },
  {
   label: "Hệ thống Hybrid",
   value: "he-thong-hybrid",
  },
  {
   label: "Hệ thống bơm nước",
   value: "he-thong-bom-nuoc",
  },
  {
   label: "Hệ thống chiếu sáng",
   value: "he-thong-chieu-sang",
  },
 ];
 const [formSearchProject] = Form.useForm();
 return (
  <SearchProjectStyled>
   <Container>
    <Flex justify='center'>
     <Form form={formSearchProject} className='form'>
      <Row className='form-item' gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
       <Col className='gutter-row' span={6}>
        <Form.Item name='content'>
         <Input placeholder='Tìm kiếm ... ' className='search-input' />
        </Form.Item>
       </Col>
       <Col className='gutter-row' span={6}>
        <Form.Item name='content'>
         <Select
          showSearch
          placeholder='Loại hình'
          options={optionsType}
          className='search-input'
         />
        </Form.Item>
       </Col>
       <Col className='gutter-row' span={6}>
        <Form.Item name='content'>
         <Select
          showSearch
          placeholder='Giải pháp'
          options={optionsSolution}
          className='search-input'
         />
        </Form.Item>
       </Col>
       <Col className='gutter-row' span={6}>
        <button className='submit' onClick={() => formSearchProject.submit()}>
         TÌM KIẾM
        </button>
       </Col>
      </Row>
     </Form>
    </Flex>
   </Container>
  </SearchProjectStyled>
 );
};

export default Search;
