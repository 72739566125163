import { AboutUsHomeStyled } from "assets/styles";
import { useNavigate } from "react-router-dom";
import Container from "common/Container";
import { Image, Typography } from "antd";
import { DoubleRightOutlined } from "@ant-design/icons";
import AdminEditButton from "./AdminEditButton";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { API_SERVER } from "helpers/variable";
import { TDataAboutUs } from "components/admin/Content/UIManage/homeManage/edit/query";

export interface TAboutUsHome {
 title: string;
 desc: string;
 button: {
  href: string;
  text: string;
 };
}

type TProps = {
 href?: string | undefined;
 buttonText?: string | undefined;
 admin?: boolean;
 propsAdmin?: TDataAboutUs[];
};

const { Text, Title } = Typography;

const AboutUsComponent = ({
 href = undefined,
 buttonText = undefined,
 admin = false,
 propsAdmin = [],
}: TProps) => {
 const navigate = useNavigate();
 let { aboutUs } = useSelector((state: RootState) => state.aboutUs);
 aboutUs = admin ? propsAdmin : aboutUs;
 href = href || aboutUs?.[0]?.redirect;
 buttonText = buttonText || aboutUs?.[0]?.buttonText;
 const handleSwitchPage = (href: string | undefined) => {
  if (href) navigate(href);
 };

 return (
  <AboutUsHomeStyled>
   {aboutUs.length !== 0 && (
    <Container>
     <div className='about'>
      <div className={`about__left`}>
       <div className='about__left-heading'>
        <h3 className='title'>{aboutUs[0].title}</h3>
       </div>
       <div className='about__left-desc'>
        <p>
         <div
          className='sun-editor-editable solution__content'
          dangerouslySetInnerHTML={{ __html: aboutUs[0].summary }}
         />
        </p>
       </div>

       <div className='about__left-button'>
        <button className='button' onClick={() => handleSwitchPage(href)}>
         {buttonText}
         <DoubleRightOutlined className='button-plus' />
        </button>
       </div>
      </div>
      <div className='about__right'>
       <Image
        preview={false}
        className='image'
        width='100%'
        height='100%'
        src={API_SERVER + "/" + aboutUs[0].imageUrl}
       />
      </div>
     </div>
    </Container>
   )}
  </AboutUsHomeStyled>
 );
};

export default AboutUsComponent;
