import { MaxScreenDevice } from "assets/screens/DeviceScreens";
import styled from "styled-components";
export const SolutionPackageStyled = styled.section`
 .navigation-custom {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
  display: flex;
  align-items: center;
  gap: 10px;
  button {
   background-color: ${({ theme }) => theme.colors.main_yellow};
   border: none;
   padding: 10px;
   cursor: pointer;
   border-radius: 5px;
   .fa-angle-left,
   .fa-angle-right {
    transition: transform 0.4s ease;
   }
   i {
    font-size: 18px;
   }
   &:hover {
    .fa-angle-left {
     transform: translateX(-2px);
    }
    .fa-angle-right {
     transform: translateX(2px);
    }
   }
  }
 }

 .solution-package {
  &__heading {
   text-align: center;
   h2 {
    font-size: 25px;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.black};
    margin-bottom: 10px;
    text-transform: uppercase;
   }
   p {
    font-size: 18px;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.black};
    text-transform: uppercase;
   }
  }
  &__carousel {
   margin-top: 50px;
   .carousel {
    padding-top: 80px;
   }
   &-item {
    background-color: ${({ theme }) => theme.colors.green[200]};
    border: 1px solid ${({ theme }) => theme.colors.gray[400]};
    border-radius: 10px;
    padding: 40px;
    transition: all 0.4s ease;
    .top,
    .bottom {
     display: flex;
     align-items: center;
     justify-content: start;
     padding-bottom: 20px;
     border-bottom: 1px solid ${({ theme }) => theme.colors.gray[400]};
     .content {
      width: 50%;
     }
     h4 {
      font-size: 20px;
      font-weight: 400;
      text-transform: uppercase;
      color: ${({ theme }) => theme.colors.black};
     }
     p {
      font-size: 18px;
      font-weight: 500;
      margin-top: 10px;
     }
    }
    .bottom {
     margin-top: 20px;
     border-bottom: none;
    }
    .button {
     transition: all 0.4s ease;
     margin-top: 20px;
     button {
      width: 100%;
      background-color: ${({ theme }) => theme.colors.main_yellow};
      padding: 10px 0;
      border: none;
      border-radius: 20px;
      font-size: 15px;
      font-weight: 400;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 20px;
      cursor: pointer;
      transition: all 0.4s ease;
      i {
       transition: transform 0.4s ease;
      }
      &:hover {
       i {
        transform: translateX(5px);
       }
      }
     }
    }
   }
  }
 }
 @media only screen and (${MaxScreenDevice.tablet}) {
  padding: 0 20px;
  .solution-package {
   &__heading {
    h2 {
     font-size: 20px;
    }
    p {
     font-size: 16px;
    }
   }
   &__carousel {
    margin-top: 20px;
   }
  }
 }
`;

export const ContentModalStyled = styled.div`
 padding: 0 20px;
 min-height: 60vh;
 .modal-content {
  &__heading {
   text-align: center;
   margin: 20px 0;
   h2 {
    font-size: 25px;
    font-weight: 500;
   }
  }
 }
 label {
  padding: 0 10px;
  font-size: 17px;
  font-weight: 400;
 }
 .form-input {
  height: 40px;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.colors.gray[100]};
  &.textarea {
   height: 100px;
   border-radius: 10px;
  }
  .ant-select-selector {
   background-color: ${({ theme }) => theme.colors.gray[100]};
   border-radius: 10px;
  }
  .ant-input-wrapper {
   height: 100%;
  }
  input {
   background-color: ${({ theme }) => theme.colors.gray[100]};
   height: 100%;
  }
 }
 .button-send {
  display: flex;
  align-items: center;
  justify-content: center;
  button {
   width: 40%;
   background-color: ${({ theme }) => theme.colors.main_yellow};
   padding: 13px;
   border-radius: 20px;
   font-size: 15px;
   font-weight: 500;
   border: none;
   margin: 30px 0 50px;
   cursor: pointer;
  }
 }
 .viewProduct {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  margin: 20px 0 0;
  .mobile {
   display: none;
  }
  .information-product {
   display: flex;
   align-items: start;
   gap: 20px;
   .image {
    width: 100px;
   }
   .info {
    padding: 10px 20px;
   }
  }
 }
 @media only screen and (${MaxScreenDevice.tablet}) {
  padding: 0;
  .modal-content {
   &__heading {
    h2 {
     font-size: 20px;
    }
   }
   &__form {
    &-flex {
     flex-direction: column;
     row-gap: 0px !important;
     .ant-form-item {
      margin-bottom: 15px;
     }
    }
    &-input {
     max-width: 100% !important;
     input {
      width: 100% !important;
     }
    }
   }
  }
  .button-send {
   button {
    width: 100%;
    margin: 20px 0;
   }
  }
  .viewProduct {
   flex-direction: column;
   gap: 20px;
   margin: 20px 0 0;
   .input-desktop {
    display: none;
   }
   .mobile {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
   }

   .information-product {
    flex-direction: column;
    .image {
     width: 100%;
    }
    .info {
     padding: 10px 0;
    }
   }
  }
 }
`;
