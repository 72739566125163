import { getQueryHelper, patchQueryHelper, postQueryHelper } from "helpers";

export type TParameterAddHistorySendMail = {
 typeEmail: string;
 customerName: string;
 customerPhone: string;
 customerEmail: string;
 time: string;
 status: number;
 data: String;
};

export const getOrderQuery = () => {
 return getQueryHelper("/order/get-all-order");
};
export const addHistorySendEmail = (
 parameters: TParameterAddHistorySendMail
) => {
 return postQueryHelper("/send-email-success/add", parameters);
};
export const updateOrderQuery = (id: string, parameters: any) => {
 return patchQueryHelper(`/order/update-status-send-email/${id}`, parameters);
};
