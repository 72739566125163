import { MaxScreenDevice } from "assets/screens/DeviceScreens";
import styled from "styled-components";

export const ServicePageStyled = styled.main`
 margin-top: 100px;
 .service-page {
  display: flex;
  align-items: start;
  gap: 20px;
  &__content {
   a {
    text-decoration: none;
   }
   width: 75%;
  }
  &__right {
   width: 25%;
  }
 }
 @media only screen and (${MaxScreenDevice.laptop}) {
  .service-page {
   &__content {
    width: 65%;
    img {
     width: 100%;
    }
   }
   &__right {
    width: 35%;
   }
  }
 }
 @media only screen and (${MaxScreenDevice.tablet}) {
  margin: 50px 0;
  .service-page {
   flex-direction: column-reverse;
   &__content {
    width: 100%;
    padding: 0 20px;
    p {
     text-align: justify;
    }
    h1 {
     span {
      font-size: 25px;
     }
    }
    img {
     width: 100%;
     height: 30vh;
    }
   }
   &__right {
    padding: 0 20px;
    width: 100%;
   }
  }
 }
`;
