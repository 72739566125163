import styled from "styled-components";
export const StyleAdminLayout = styled.div`
 .ant-layout {
  .ant-layout-sider {
   max-width: 15% !important;
   width: 100% !important;
   flex: unset !important;
  }
  .ant-layout-header {
   padding: 0 20px !important;
  }
 }
`;
