import { MaxScreenDevice } from "assets/screens/DeviceScreens";
import styled from "styled-components";
export const ProductPortfolioStyled = styled.div`
 .product-portfolio {
  width: 100%;
  &__heading {
   display: flex;
   align-items: center;
   gap: 10px;
   background-color: ${({ theme }) => theme.colors.main_green};
   padding: 10px 15px;
   color: ${({ theme }) => theme.colors.white};
   border: 1px solid ${({ theme }) => theme.colors.main_green};
   h2 {
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
   }
  }
  &__list {
   border-bottom: none;
   width: 100%;
   ul {
    width: 100%;
    .item {
     text-decoration: none;
     color: ${({ theme }) => theme.colors.black};
     cursor: pointer;
     &:hover {
      color: ${({ theme }) => theme.colors.main_green};
     }
     &.active {
      color: ${({ theme }) => theme.colors.green[800]};
      width: 100%;
      background-color: ${({ theme }) => theme.colors.green[200]};
     }
     li {
      padding: 10px 10px 10px 20px;
      list-style: none;
      border-bottom: 1px solid ${({ theme }) => theme.colors.main_green};
      border-right: 1px solid ${({ theme }) => theme.colors.main_green};
      border-left: 1px solid ${({ theme }) => theme.colors.main_green};
      p {
       display: -webkit-box;
       -webkit-line-clamp: 2;
       -webkit-box-orient: vertical;
       overflow: hidden;
       text-overflow: ellipsis;
      }
     }
    }
   }
  }
 }
 @media only screen and (${MaxScreenDevice.tablet}) {
  width: 100%;
  .product-portfolio {
   &__list {
    max-width: 100%;
    width: 100%;
   }
  }
 }
`;
