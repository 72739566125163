import { FooterStyled } from "assets/styles";
import {
 TContactInformation,
 TSocial,
} from "components/Home/HomeComponent/Contact.component";

import React from "react";
import Container from "./Container";
import parse from "html-react-parser";
import { NavLink, useNavigate } from "react-router-dom";
import { API_SERVER } from "helpers/variable";
import FooterData, {
 TFooterData,
 TServiceFooter,
} from "assets/json/FooterData";
import { TMenuChild } from "assets/json/MenuHeader";
import useSelection from "antd/es/table/hooks/useSelection";
import { RootState } from "store";
import { useSelector } from "react-redux";
import { TSolution } from "@types";

interface TFooterService {
 label: string;
 items: {
  label: string;
  href: string;
 }[];
}
interface TFooterSolution {
 label: string;
 items: {
  label: string;
  href: string;
 }[];
}

interface TCopyRight {
 year: number | string;
 icon: string;
 label: string;
}

interface THotline {
 label: string;
 phoneNumber: string;
 href: string;
}

export interface TFooter {
 information: string;
 //  social: TSocial[];
 contactInformation: {
  label: string;
  information: TContactInformation[];
 };
 solution: TFooterSolution;
 services: TFooterService;
 copyright: TCopyRight;
 hotline: THotline;
}

const Footer = () => {
 const { solution } = useSelector((state: RootState) => state.solution);
 const {
  contactInformation,
  copyright,
  hotline,
  information,
  services,
 }: TFooterData = FooterData;
 const navigate = useNavigate();
 const handleSwitchHome = () => {
  navigate("/");
 };
 const renderSocial = (social: TSocial, key: number) => (
  <a
   key={key}
   href={social.href}
   style={{
    backgroundColor: social.backgroundColor,
   }}
   title={social.label}
   target='window'
  >
   {parse(social.icon)}
  </a>
 );

 const renderContactInformation = (
  information: TContactInformation,
  key: number
 ) => (
  <a href={information.href} className='item' key={key}>
   {information.icon && (
    <div className='item__icon'>{parse(information.icon)}</div>
   )}
   {information.label && (
    <div className='item__label'>
     <p>{information.label}: </p>
    </div>
   )}
   <div className='item__content'>
    <p>{information.content}</p>
   </div>
  </a>
 );

 const renderService = (service: TServiceFooter) => (
  <div className='service'>
   <div className='service__title'>
    <h3>{service.label}</h3>
   </div>
   <ul className='service__list'>
    {service.items.map((item: TMenuChild) => (
     <li key={item.id}>
      <NavLink to={item.href}>{item.title}</NavLink>
     </li>
    ))}
   </ul>
  </div>
 );
 const renderSolution = (service: TSolution[]) => (
  <div className='service'>
   <div className='service__title'>
    <h3>Giải pháp</h3>
   </div>
   <ul className='service__list'>
    {service.map((item: TSolution) => (
     <li key={item.solutionId}>
      <NavLink to={`/giai-phap/${item.solutionSlug}`}>
       {item.solutionName}
      </NavLink>
     </li>
    ))}
   </ul>
  </div>
 );

 return (
  <FooterStyled>
   <Container>
    <div className='footer'>
     <div className='footer__left'>
      <div className='information'>
       {/* <div className='logo'>
        <img
         style={{
          cursor: "pointer",
         }}
         src={`/images/logo-dt.jpg`}
         alt='DT-SOLAR'
         onClick={handleSwitchHome}
        />
       </div> */}
       <p>{information}</p>
      </div>
      {/* <div className='social'>
       {social.map((item: TSocial, key: number) => renderSocial(item, key))}
      </div> */}
      <div className='footer__center'>
       <div className='title'>
        <h3>{contactInformation.label}</h3>
       </div>
       <div className='content'>
        {contactInformation.information.map(
         (information: TContactInformation, key: number) =>
          renderContactInformation(information, key)
        )}
       </div>
      </div>
     </div>
     <div className='footer__right'>
      <div className='product-solution'>
       <div className='footer__right-item'>{renderService(services)}</div>
       {solution && solution.length > 0 && (
        <div className='footer__right-item'>{renderSolution(solution)}</div>
       )}
      </div>
      <div className='contact'>
       <h3>Nhận thông tin</h3>
       <p>
        Đăng ký để nhận tin tức về sản phẩm và giải pháp mới nhất từ DTSolar
       </p>
       <div className='input'>
        <input type='email' placeholder='Email' />
        <i className='fa-regular fa-paper-plane'></i>
       </div>
      </div>
     </div>
    </div>
   </Container>
   <div className='footer-bottom'>
    <Container>
     <div className='copyright'>
      <div className='copyright__left'>
       <p>
        Copyright {copyright.year} {parse(copyright.icon)} {copyright.label}
       </p>
      </div>
      <div className='copyright__right'>
       <a href={hotline.href}>
        {hotline.label}: {hotline.phoneNumber}
       </a>
      </div>
     </div>
    </Container>
   </div>
  </FooterStyled>
 );
};

export default Footer;
