import styled from "styled-components";
export const SocialStyled = styled.div`
 margin-top: 50px;
 .social {
  display: flex;
  align-items: center;
  gap: 20px;
  &__item {
   a {
    font-size: 20px;
   }
   .facebook {
    color: #007bff;
   }
   .youtube {
    color: #f70000;
   }
   .twitter {
    color: #1da1f2;
   }
   .instagram {
    color: #c13584;
   }
   .tiktok {
    color: ${({ theme }) => theme.colors.black};
   }
  }
 }
`;
