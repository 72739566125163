import { Image, Modal } from "antd";
import { AboutEnterpriseStyled } from "assets/styles/V2/Enterprise/About.enterprise.styled";
import Container from "common/Container";
import { PlayCircleOutlined } from "@ant-design/icons";
import React from "react";
import ReactPlayer from "react-player";
import ContactModal from "components/Home/common/ContactModal";

const AboutEnterprise = () => {
 const [visibleVideo, setVisibleVideo] = React.useState<boolean>(false);
 const [visibleContactModal, setVisibleContactModal] =
  React.useState<boolean>(false);
 const introduce = `<p style="text-align: justify"><span style="font-size: 18px">Hệ thống điện mặt trời giúp doanh nghiệp tiết kiệm đến hàng tỷ đồng tiền điện mỗi năm. Cắt giảm tối đa chi phí điện cho doanh nghiệp sản xuất và đặc biệt ở khung giá điện giờ cao điểm 4.587 đồng/kWh đối với khối kinh doanh – thương mại. Khả năng tiết kiệm tùy thuộc vào diện tích mái khả dụng và mức đầu tư công suất hệ thống năng lượng mặt trời.<br></span></p><p style="text-align: justify"><span style="font-size: 18px">Hệ thống điện năng lượng mặt trời hòa lưới có tấm pin hấp thu ánh sáng mặt trời và được bộ inverter chuyển hóa thành nguồn điện sạch để cấp cho các thiết bị điện.</span></p><p style="text-align: justify"><span style="font-size: 18px">Đồng thời, nâng cao uy tín, giá trị thương hiệu, hướng đến hình ảnh doanh nghiệp xanh thân thiện với môi trường.</span></p>`;
 const handleCloseModal = () => {
  setVisibleVideo(false);
 };
 return (
  <AboutEnterpriseStyled>
   <ContactModal
    setCloseModal={setVisibleContactModal}
    visible={visibleContactModal}
   />
   {visibleVideo && (
    <Modal
     open={visibleVideo}
     onCancel={handleCloseModal}
     centered
     closeIcon={null}
     width={"80%"}
     footer={null}
    >
     <ReactPlayer
      url='https://www.youtube.com/watch?v=h6-VubFRoIM'
      width='100%'
      height='calc(100vh - 100px)'
      controls={true}
      playing={visibleVideo}
     />
    </Modal>
   )}
   <Container>
    <div className='about-enterprise'>
     <div className='about-enterprise__left'>
      <div className='heading'>
       <h1>giải pháp cho doanh nghiệp</h1>
      </div>
      <div className='desc'>
       <div
        style={{ backgroundColor: "transparent" }}
        className='sun-editor-editable'
        dangerouslySetInnerHTML={{ __html: introduce }}
       />
      </div>
      <div className='button-see-more'>
       <button onClick={() => setVisibleContactModal(true)}>
        Nhận tư vấn và báo giá
       </button>
      </div>
     </div>
     <div className='about-enterprise__right'>
      <img
       src='https://cdn.pixabay.com/photo/2016/05/07/04/25/office-1377047_640.jpg'
       alt='tu-van-bao-gia'
      />
     </div>
    </div>
   </Container>
  </AboutEnterpriseStyled>
 );
};

export default AboutEnterprise;
