import { BenefitEnterpriseStyled } from "assets/styles/V2/Enterprise/Benefit.enterprise.styled";
import Container from "common/Container";
import React from "react";
import DataBenefitEnterPrise from "jsons/BenfitEnterprise.json";

export interface BenefitEnterprise {
 label: string;
 description: string;
}

const BenefitEnterprise = () => {
 return (
  <BenefitEnterpriseStyled>
   <Container>
    <div className='benefit'>
     <div className='benefit__heading'>
      <h2>
       ĐIỆN MẶT TRỜI ĐẦU TƯ 1 LẦN, <br /> LỢI ÍCH ĐẾN 30 NĂM
      </h2>
     </div>
     <div className='benefit__items'>
      {DataBenefitEnterPrise.map((item: BenefitEnterprise, index: number) => (
       <div className='benefit__items-item' key={index}>
        <img src='icons/benefits.png' alt='benefits' />
        <div className='content'>
         <h3>{item.label}</h3>
         <p className='desc'>{item.description}</p>
        </div>
       </div>
      ))}
     </div>
    </div>
   </Container>
  </BenefitEnterpriseStyled>
 );
};

export default BenefitEnterprise;
