import { SolutionDataV2, CategoryDataV2 } from "data/v2";
import React from "react";
import { TCategory, TProduct } from "@types";
import { BestSellerStyled } from "assets/styles/HomeStyled/_BestSeller.styled";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/grid";
import "swiper/css/pagination";
import { Grid, Pagination } from "swiper/modules";
import _ from "lodash";
import { Empty, Flex, Image, Typography } from "antd";
import { API_SERVER } from "helpers/variable";
import { EyeOutlined } from "@ant-design/icons";
import { title } from "process";
import Container from "common/Container";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { useNavigate } from "react-router-dom";
export interface TContentSolution {
 content: {
  heading: string;
  title: string;
  desc: string;
  use?: string;
  payback?: string;
  image: string;
  href: string;
 };
}

export interface TSolutionTabs extends TContentSolution {
 title: string;
 image: string | JSX.Element;
 id: string;
}
const { Paragraph } = Typography;
const BestSellerComponent: React.FC = () => {
 const { category } = useSelector((state: RootState) => state.category);
 const { product } = useSelector((state: RootState) => state.product);
 const [idTab, setIdTab] = React.useState<string>("");
 const [dataTabContent, setDataTabContent] = React.useState<any[]>([]);
 const navigate = useNavigate();

 const selectDataByCategory = (idCategory: string) => {
  const result = _.filter(product, { categoryCode: idCategory });
  if (result.length === 0) {
   return setDataTabContent([]);
  }
  const dataProduct = result.map((item: TProduct) => {
   return {
    id: item.productId,
    productName: item.productName,
    productCategory: item.productParameter,
    productCode: item.productCode,
    productImages: item.productImages.map(
     (image: string) => API_SERVER + "/" + image
    ),
    productTrademark: item.productTrademark,
    categoryTitle: item.categoryTitle,
    productHref: item.productHref,
   };
  });
  setDataTabContent(
   dataProduct.length > 4 ? dataProduct.slice(0, 4) : dataProduct
  );
 };

 React.useEffect(() => {
  if (category.length > 0 && product.length > 0) {
   setIdTab(category[0].id);
   selectDataByCategory(category[0].id);
  }
 }, [category, product]);

 const handleClickTab = (contentTab: TCategory) => {
  const { id } = contentTab;
  setIdTab(id);
  selectDataByCategory(id);
 };

 return (
  <BestSellerStyled>
   <Container>
    <div className='bestseller'>
     <div className='bestseller__left'>
      <div className='bestseller__left-heading'>
       <h3>THIẾT BỊ & PHỤ KIỆN</h3>
      </div>
      <div className='bestseller__left-tabs'>
       {category.map((category: TCategory) => {
        return (
         <div
          className={`item ${category.id === idTab ? "active" : ""}`}
          key={category.id}
          style={{
           justifyContent: "center",
          }}
          onClick={() => handleClickTab(category)}
         >
          <div className='title'>
           <h3>{category.title}</h3>
          </div>
          {/* {tab.image} */}
         </div>
        );
       })}
      </div>
     </div>
     {dataTabContent?.length === 0 ? (
      <Flex
       justify='center'
       align='center'
       style={{ width: "100%", padding: "20px 0" }}
      >
       <Empty description={<span>KHÔNG CÓ DỮ LIỆU</span>}></Empty>
      </Flex>
     ) : (
      <div className='bestseller__right'>
       {dataTabContent.map((data: any) => (
        <div key={data.id} className='bestseller__right-item'>
         <div className='bestseller__right-item-image'>
          <Image.PreviewGroup items={data.productImages}>
           <Image
            preview={true}
            width={180}
            height={200}
            className='image'
            src={data.productImages[0]}
           />
          </Image.PreviewGroup>
         </div>
         <div className='bestseller__right-item-content'>
          <div>
           <div className='title'>
            <h4>{data.productName}</h4>
           </div>
           <p>
            {data.productTrademark && (
             <>
              <span>
               <strong>Thương hiệu: </strong>
               {data.productTrademark}
              </span>
              |
             </>
            )}
            <span>
             <strong>Loại: </strong>
             {data.categoryTitle}
            </span>
           </p>
           <p>
            <span>
             <strong>Mã sản phẩm: </strong>
             {data.productCode}
            </span>
           </p>
          </div>

          <button onClick={() => navigate(`san-pham/${data.productHref}`)}>
           XEM CHI TIẾT
           <i className='fa-solid fa-angle-right'></i>
          </button>
         </div>
        </div>
       ))}
      </div>
     )}
    </div>
   </Container>
  </BestSellerStyled>
 );
};

export default BestSellerComponent;
