import { MaxScreenDevice } from "assets/screens/DeviceScreens";
import styled from "styled-components";
export const SolutionStyled = styled.div`
 .solution {
  margin: 50px 0;
  width: 100%;
  min-height: 80vh;
  display: flex;
  align-items: start;
  gap: 20px;
  &__left {
   width: 30%;
   margin-left: 100px;
   &-heading {
    h1 {
     font-size: 30px;
     font-weight: 600;
    }
    p {
     margin: 15px 0;
     font-size: 16px;
    }
   }
   &-tabs {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    margin-top: 50px;
    .item {
     cursor: pointer;
     transition: all 0 4s ease;
     padding: 20px;
     border-radius: 4px;
     border: 1px solid ${({ theme }) => theme.colors.gray[300]};
     .title {
      h3 {
       font-size: 16px;
       font-weight: 500;
      }
     }
     i {
      margin-top: 10px;
      font-size: 45px;
      object-fit: contain !important;
     }
     &:hover,
     &.active {
      border-color: ${({ theme }) => theme.colors.main_blue};
      i,
      h3 {
       color: ${({ theme }) => theme.colors.main_blue};
      }
     }
    }
   }
  }
  &__right {
   width: 70%;
   position: relative;
   .gradient {
    width: 100%;
    background: linear-gradient(to right, #19191acf 77%, transparent);
    opacity: 0.5;
    position: absolute;
    /* width: 100%; */
    height: 100%;
   }
   .content {
    padding: 0 100px;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    color: ${({ theme }) => theme.colors.white};
    display: flex;
    align-items: start;
    justify-content: center;
    flex-direction: column;
    gap: 15px;
    transition: all 0.4s cubic-bezier(0.445, 0.05, 0.55, 0.95);

    h4 {
     font-size: 15px;
     font-weight: 400;
     text-transform: uppercase;
    }
    h3 {
     font-size: 25px;
     font-weight: 500;
     text-transform: uppercase;
    }
    p {
     font-size: 14px;
     font-weight: 500;
     line-height: 150%;
    }
    .time {
     display: flex;
     align-items: center;
     gap: 50px;
     strong {
      font-size: 15px;
      font-weight: 400;
     }
     p {
      font-size: 30px;
      font-weight: 600;
     }
    }
    .see-more {
     background-color: ${({ theme }) => theme.colors.main_yellow};
     border: 0;
     padding: 10px 20px;
     border-radius: 5px;
     font-size: 17px;
     font-weight: 500;
     margin-top: 40px;
     color: ${({ theme }) => theme.colors.black};
     cursor: pointer;
     transition: all 0.4s ease;
     &:hover {
      transform: translateY(5px);
      color: ${({ theme }) => theme.colors.white};
      background-color: ${({ theme }) => theme.colors.main_blue};
     }
    }
   }
   .background-image {
    width: 100%;
    height: 90vh;
    transition: all 0.4s cubic-bezier(0.445, 0.05, 0.55, 0.95);
    img {
     transition: all 0.4s cubic-bezier(0.445, 0.05, 0.55, 0.95);
     width: 100%;
     height: 100%;
    }
   }
  }
  &__content {
   width: calc(75% - 20px);
  }
  &__list-news {
   max-width: 25%;
  }
 }
 display: flex;
 align-items: center;
 justify-content: start;
 gap: 20px;
 @media only screen and (${MaxScreenDevice.tablet}) {
  width: 100%;
  height: 100%;
  padding: 20px 0;
  .solution {
   flex-direction: column;
   height: 100%;
   &__content {
    width: calc(100% - 40px);
   }
   &__left,
   &__right {
    width: calc(100% - 40px);
    height: 100%;
   }
   &__left {
    margin-left: 0;
    padding: 0 20px !important;
    &-heading {
     padding: 0 20px;
     h1 {
      font-size: 20px;
     }
     p {
      margin: 15px 0;
      font-size: 14px;
     }
    }
    &-tabs {
     grid-template-columns: 1fr 1fr;
     margin-top: 20px;
     gap: 10px;
     padding: 0 20px;
     .item {
      cursor: pointer;
      transition: all 0 4s ease;
      border-radius: 4px;
      border: 1px solid ${({ theme }) => theme.colors.gray[300]};
      .title {
       h3 {
        font-size: 16px;
        font-weight: 500;
       }
      }
      i {
       margin-top: 10px;
       font-size: 45px;
       object-fit: contain !important;
      }
      &:hover,
      &.active {
       border-color: ${({ theme }) => theme.colors.main_blue};
       i,
       h3 {
        color: ${({ theme }) => theme.colors.main_blue};
       }
      }
     }
    }
   }
   &__right {
    width: 100%;
    .gradient {
     background: linear-gradient(to bottom, #30303294 77%, transparent);
    }
    .content {
     z-index: 2;
     width: calc(100% - 40px);
     height: 100%;
     padding: 0 20px;
     align-items: center;
     h4 {
      text-align: center;
      font: 15px;
     }
     h3 {
      text-align: center;
      font-size: 18px;
     }
     p {
      font-size: 13.5px;
      text-align: center;
      display: -webkit-box;
      -webkit-line-clamp: 8;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
     }
     .time {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 20px;
      strong {
       font-size: 13.5px;
      }
      p {
       font-size: 18px;
       max-width: 50px;
      }
     }
     .see-more {
      background-color: ${({ theme }) => theme.colors.main_yellow};
      border: 0;
      padding: 8px 16px;
      border-radius: 5px;
      font-size: 15px;
      font-weight: 500;
      margin-top: 40px;
      color: ${({ theme }) => theme.colors.black};
      cursor: pointer;
      transition: all 0.4s ease;
      &:hover {
       transform: translateY(5px);
       color: ${({ theme }) => theme.colors.white};
       background-color: ${({ theme }) => theme.colors.main_blue};
      }
     }
    }
    .background-image {
     width: 100%;
     height: 90vh;
     transition: all 0.4s cubic-bezier(0.445, 0.05, 0.55, 0.95);
     img {
      transition: all 0.4s cubic-bezier(0.445, 0.05, 0.55, 0.95);
      width: 100%;
      height: 100%;
     }
    }
   }
   &__list-news {
    max-width: 100%;
    padding: 0 20px;
   }
  }
 }
`;
