import { Flex, Table } from "antd";
import React from "react";
import { columnConfirmOrder } from "./columns";
import { TResultOrder } from "./Action";

type TProps = {
 dataProduct: TResultOrder;
};

const ConfirmOrder = ({ dataProduct }: TProps) => {
 return (
  <>
   <Table columns={columnConfirmOrder} dataSource={dataProduct.products} />
   <Flex
    justify='end'
    style={{
     padding: "30px 20px",
    }}
   >
    <div>
     <Flex
      justify='space-between'
      style={{
       width: 200,
      }}
     >
      <strong>Tổng :</strong> {dataProduct.priceTotal}
     </Flex>
     <Flex
      justify='space-between'
      style={{
       width: 200,
      }}
     >
      <strong>Giảm giá :</strong> - {dataProduct.discountTotal}
     </Flex>
     <Flex
      justify='space-between'
      style={{
       width: 200,
      }}
     >
      <strong>Tổng thanh toán:</strong> {dataProduct.priceAfterDiscount}
     </Flex>
    </div>
   </Flex>
  </>
 );
};

export default ConfirmOrder;
