import { MinScreenDevice } from "assets/screens/DeviceScreens";
import styled from "styled-components";

export const StyledBrands = styled.div`
 .swiper-wrapper {
  display: flex;
  align-items: center;
  img {
   width: 100%;
   height: 100%;
   object-fit: contain;
  }
 }
 @media only screen and (${MinScreenDevice.mobileS}) {
  .swiper-wrapper {
   width: 60vw;
   height: 150px;
   a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
   }
   img {
    width: 50%;
   }
  }
 }
 @media only screen and (${MinScreenDevice.custom(600)}) {
  .swiper-wrapper {
   width: 100vw;
   padding: 0 20px;
   img {
    width: 100%;
   }
  }
 }
`;
