import Table from "antd/es/table";
import React from "react";
import { Button, Flex, message } from "antd";
import { TCarousel } from "@types";
import _ from "lodash";
import { update } from "./fetch";
import { useQueryClient } from "react-query";
import { QUERY_CAROUSEL_ADMIN } from "helpers";
import { columns } from "./Columns";

export interface DataTypeCarousel extends TCarousel {
 key: string;
 image: string;
 imageUrl: string;
 active: boolean;
 order: number;
}
type TProps = {
 isLoading: boolean;
 dataTable: TCarousel[];
};

const TableCarousel = ({ isLoading, dataTable }: TProps) => {
 const queryClient = useQueryClient();
 const [selectedRowKeys, setSelectedRowKeys] = React.useState<string[]>([]);
 const [loading, setLoading] = React.useState<boolean>(false);
 const tableData: DataTypeCarousel[] = dataTable?.map((data: TCarousel) => ({
  ...data,
  image: data.imageUrl,
  key: data.id,
 }));

 const handlePublishCarousel = () => {
  setLoading(true);
  selectedRowKeys.map((id: string) => {
   update(id, {
    active: true,
   });
  });
  message.success("Cập nhật trạng thái thành công");
  queryClient.invalidateQueries(QUERY_CAROUSEL_ADMIN);
  setLoading(false);
  setSelectedRowKeys([]);
 };

 const onSelectedDefault = () => {
  const filterActive = _.filter(dataTable, { active: true }).map(
   (item: TCarousel) => item.id
  );
  setSelectedRowKeys(filterActive);
 };

 const onSelectChange = (newSelectedRowKeys: any[]) => {
  setSelectedRowKeys(newSelectedRowKeys);
 };

 const rowSelection = {
  selectedRowKeys,
  onChange: onSelectChange,
  getCheckboxProps: (record: DataTypeCarousel) => ({
   disabled: record.active, // Column configuration not to be checked
   name: record.imageUrl,
  }),
 };

 //  React.useEffect(() => {
 //   onSelectedDefault();
 //  }, [dataTable]);

 const hasSelected = selectedRowKeys.length > 0;
 return (
  <>
   {hasSelected && (
    <Flex gap={20} style={{ marginBottom: 20 }}>
     <Button type='primary' onClick={handlePublishCarousel} loading={loading}>
      Đăng
     </Button>
    </Flex>
   )}
   <Table
    rowSelection={rowSelection}
    loading={isLoading}
    columns={columns}
    dataSource={tableData}
   />
  </>
 );
};

export default TableCarousel;
