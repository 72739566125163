import React from "react";
import { FormInformationStyled } from "./styled";
import { Form, Input, Select, message } from "antd";
import LocationVN from "jsons/location_vn.json";
import _ from "lodash";
import PhoneInput from "antd-phone-input";
import { TInformationEstimation } from "./SystemEstimation";

type TProps = {
 form: any;
 onNext: (values: TInformationEstimation) => void;
};

const FormInformation = ({ form, onNext }: TProps) => {
 const [localVN, setLocalVN] = React.useState<any>(null);
 const [listTinhTP, setListTinhTP] = React.useState<
  { value: string; label: string }[]
 >([]);
 const [listQuanHuyen, setListQuanHuyen] = React.useState<any>([]);
 const [listPhuongXa, setListPhuongXa] = React.useState<any>([]);
 const [address, setAddress] = React.useState<any>();

 const formatOptionsCity = () => {
  const location: any = LocationVN;
  const newListTinh_TP: { value: string; label: string }[] = [];
  location.map((location: any) => {
   newListTinh_TP?.push({
    value: location.code,
    label: location.name,
   });
  });
  setListTinhTP(newListTinh_TP);
  setLocalVN(location);
 };

 const handleChangeTinhTP = (value: string, option: any) => {
  const newDataQuan_Huyen: any = [];
  _.filter(localVN, { code: value })?.[0]?.districts?.map((item: any) =>
   newDataQuan_Huyen.push({
    value: item.id,
    label: item.name,
    ...item,
   })
  );
  setListQuanHuyen(newDataQuan_Huyen);
  setAddress((prev: any) => ({
   ...prev,
   tinhTP: option.label,
  }));
 };

 const handleChangeQuanHuyen = (value: string, option: any) => {
  const newDataPhuongXa: any = [];
  const filterDataPhuongXa = _.filter(listQuanHuyen, { value: value })?.[0];
  filterDataPhuongXa?.wards?.map((item: any) =>
   newDataPhuongXa.push({
    value: item.id,
    label: item.prefix + " " + item.name,
    ...item,
   })
  );
  filterDataPhuongXa?.streets?.map((item: any) =>
   newDataPhuongXa.push({
    value: item.id,
    label: item.prefix + " " + item.name,
    ...item,
   })
  );
  filterDataPhuongXa?.project?.map((item: any) =>
   newDataPhuongXa.push({
    value: item.id,
    label: item.prefix + " " + item.name,
    ...item,
   })
  );
  setListPhuongXa(newDataPhuongXa);
  setAddress((prev: any) => ({
   ...prev,
   quan_huyen: option.label,
  }));
 };

 const formatValueSearch = (value: any) => {
  return value
   .toLowerCase()
   .normalize("NFD")
   .replace(/[\u0300-\u036f]/g, "")
   .replace(/đ/g, "d")
   .replace(/Đ/g, "D");
 };

 const onFinish = (values: any) => {
  if (
   !values.name ||
   !values.phone ||
   !values.tinh_tp ||
   !values.quan_huyen ||
   !values.xa_phuong
  ) {
   return message.warning(
    "Vui lòng nhập thông tin của bạn trước khi xem kết quả"
   );
  }
  onNext({
   name: values.name,
   phoneNumber: "0" + values.phone.areaCode + values.phone.phoneNumber,
   address: address,
  });
 };

 React.useEffect(() => {
  formatOptionsCity();
 }, []);

 return (
  <FormInformationStyled>
   <Form layout='vertical' form={form} onFinish={onFinish}>
    <div className='form-information-input'>
     <Form.Item label='Tên của bạn:' name='name' required>
      <Input className='input-form' />
     </Form.Item>
     <Form.Item required label='Số điện thoại:' name='phone'>
      <PhoneInput className='input-form' onlyCountries={["vn"]} />
     </Form.Item>
     {/* <Form.Item></Form.Item> */}
     {/* <Form.Item required label='Email:' name='email'>
      <Input className='input-form' />
     </Form.Item> */}
     <Form.Item required label='Tỉnh / Thành phố:' name='tinh_tp'>
      <Select
       showSearch
       placeholder='Tìm kiếm Tỉnh / Thành Phố'
       optionFilterProp='children'
       filterOption={(input, option) =>
        formatValueSearch(option?.label ?? "").includes(
         formatValueSearch(input)
        )
       }
       onChange={handleChangeTinhTP}
       className='input-form'
       options={listTinhTP}
      />
     </Form.Item>
     <Form.Item required label='Quận / Huyện:' name='quan_huyen'>
      <Select
       showSearch
       style={{ width: "100%" }}
       placeholder='Quận / Huyện'
       optionFilterProp='children'
       className='input-form'
       onChange={handleChangeQuanHuyen}
       filterOption={(input, option) =>
        formatValueSearch(option?.label ?? "").includes(
         formatValueSearch(input)
        )
       }
       options={listQuanHuyen}
      />
     </Form.Item>
     <Form.Item required label='Xã Phường:' name='xa_phuong'>
      <Select
       showSearch
       style={{ width: "100%" }}
       onChange={(value: string, option: any) => {
        setAddress((prev: any) => ({
         ...prev,
         phuong_xa: option.label,
        }));
       }}
       placeholder='Phường / Xã / Thị trấn / Đường'
       optionFilterProp='children'
       className='input-form'
       filterOption={(input, option) =>
        formatValueSearch(option?.label ?? "").includes(
         formatValueSearch(input)
        )
       }
       options={listPhuongXa}
      />
     </Form.Item>
    </div>
   </Form>
  </FormInformationStyled>
 );
};

export default FormInformation;
