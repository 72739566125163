import React from "react";
import Carousel from "common/Carousel";
import { ContactPage } from "../Pages/contact";
import AboutUsComponent from "./AboutUs.component";
import SolutionComponent from "./Solution.component";
import ProjectComponent from "./Project.component";
import BestSellerComponent from "./BestSeller.component";
import NewV2Component from "./NewV2.component";
import Process from "common/Process";
import { ServiceOfDTSolar } from "../Pages/SeriveOfDTSolar";
import SystemEstimation from "../Pages/Household/SystemEstimation";
import SolutionPackage from "../Pages/Household/SolutionPackage";

const Component = () => {
 return (
  <>
   <Carousel />
   {/* <BannerComponent /> */}
   <AboutUsComponent />
   <ServiceOfDTSolar />
   {/* <SolutionComponent /> */}
   <SystemEstimation />
   <div
    style={{
     padding: "50px 0",
    }}
   >
    <SolutionPackage />
   </div>

   <ProjectComponent />
   <BestSellerComponent />
   <NewV2Component />
   <Process />
   {/* <ReviewsComponent /> */}
   <ContactPage />
   {/* <BrandsComponent />
   <ServiceComponent />
   <CharacteristicComponent />
   <NewsComponents />
   <ContactPage /> */}
  </>
 );
};

export default Component;
