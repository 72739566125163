import React from "react";
import NewPostComponent from "../NewPost.component";
import { NewsPageStyled } from "assets/styles/page/news/_News.styled";
import Container from "common/Container";
import { CardsStyled } from "assets/styles/_Card";
import { TNews } from "@types";
import NewCard from "./Card";
import { useSelector } from "react-redux";
import { RootState } from "store";
import PaginationComponent from "common/Pagination";

const Components = () => {
 const { news } = useSelector((state: RootState) => state.news);
 const [newDataCurrent, setNewDataCurrent] = React.useState<TNews[]>([]);
 return (
  <NewsPageStyled>
   <Container>
    <h1 className='h1-title'>DANH SÁCH TIN TỨC</h1>
    <div className='new-page'>
     <div className='new-page__category'>
      <NewPostComponent />
     </div>
     <div className='new-page__content'>
      <CardsStyled numberColumn={3}>
       {newDataCurrent?.map((item: TNews) => (
        <NewCard newItem={item} key={item.id} />
       ))}
      </CardsStyled>
      <PaginationComponent
       dataAll={news || []}
       dataCurrent={setNewDataCurrent}
       total={newDataCurrent.length}
       numberView={9}
      />
     </div>
    </div>
   </Container>
  </NewsPageStyled>
 );
};

export default Components;
