import { ColumnsType } from "antd/es/table";
import { DataTypeCarousel } from "./Table.carousel";
import { TParametersCarousel } from "../Carousel";
import { Avatar, Tag } from "antd";
import { API_SERVER } from "helpers/variable";
import { TCarousel } from "@types";
import Action from "./Action";

export const columns: ColumnsType<DataTypeCarousel> = [
 {
  title: "Hình ảnh",
  dataIndex: "image",
  align: "center",
  render: (_, record: TParametersCarousel) => (
   <Avatar shape='square' size={100} src={API_SERVER + "/" + record.imageUrl} />
  ),
 },
 {
  title: "Tên hình ảnh",
  dataIndex: "imageUrl",
 },
 {
  title: "Order",
  dataIndex: "order",
 },
 {
  title: "Trạng thái",
  dataIndex: "active",
  render: (active: boolean) => {
   return (
    <>
     {active ? (
      <Tag color='green'>Công khai</Tag>
     ) : (
      <Tag color='red'>Riêng tư</Tag>
     )}
    </>
   );
  },
 },
 {
  title: "",
  dataIndex: "action",
  align: "center",
  render: (_, record: TCarousel) => <Action record={record} />,
 },
];
