import { MaxScreenDevice } from "assets/screens/DeviceScreens";
import styled from "styled-components";
export const ResultSearchPages = styled.div`
 margin-top: 200px;
 h1 {
  text-transform: uppercase;
 }
 .result {
  padding: 50px 100px;
  &-title {
   margin: 50px 0;
   text-align: center;
   font-size: 30px;
   font-weight: 600;
  }
 }
 @media only screen and (${MaxScreenDevice.tablet}) {
  margin-top: 100px !important;
  padding: 0 20px;
  .result {
   padding: 50px 0;
   &-title {
    margin: 50px 0;
   }
  }
 }
`;
