import AboutEnterprise from "./About.enterprise";
import BenefitEnterprise from "./Benefit.enterprise";
import { ServiceOfDTSolar } from "../SeriveOfDTSolar";
import AboutUsComponent from "components/Home/HomeComponent/AboutUs.component";
import BrandsComponent from "components/Home/HomeComponent/Brands.component";
import Banner from "common/Banner";
import React from "react";

const breadcrumb = [
 {
  title: "Trang Chủ",
  href: "/",
 },
 {
  title: "Doanh nghiệp",
  href: null,
 },
];

const Component = () => {
 const scrollToTop = () => {
  window.scrollTo({
   top: 0,
   left: 0,
   behavior: "smooth",
  });
 };

 React.useEffect(() => {
  scrollToTop();
 }, []);
 return (
  <>
   <Banner breadcrumb={breadcrumb} />
   <AboutEnterprise />
   <BenefitEnterprise />
   <ServiceOfDTSolar />
   <AboutUsComponent href='/lien-he' buttonText='NHẬN TƯ VẤN VÀ BÁO GIÁ' />
   <BrandsComponent />
  </>
 );
};

export default Component;
