import { getQueryHelper } from "helpers";
const getAllSolutionPackage = async () => {
 const response = await getQueryHelper(
  "/solution-packages/get-all-solution-package/active"
 );
 if (response) {
  return response;
 }
};

export const solutionPackageService = {
 getAllSolutionPackage,
};
