import { StyleSidebar } from "assets/styles/admin";
import React from "react";
import SidebarLinks from "./SidebarLinks";
import routes from "routes";

const Sidebar = () => {
 return (
  <StyleSidebar>
   <div className='top'>
    <div className='logo'>
     <img
      src='/images/logo-dt.png'
      alt='logo'
      style={{ width: 80, height: 80, objectFit: "contain" }}
     />
    </div>
    <div className='toggle'>
     {/* <img src='/images/toggle-sidebar.png' alt='toggle' /> */}
    </div>
   </div>
   <ul className='mb-auto pt-1'>
    <SidebarLinks routes={routes} />
   </ul>
  </StyleSidebar>
 );
};

export default Sidebar;
