import { ColumnsType } from "antd/es/table";
import { TNewsData } from "./Component";
import { Tag, message, Typography, Image } from "antd";
import { API_SERVER } from "helpers/variable";
import Action from "./Action";

const { Link } = Typography;

export const columns: ColumnsType<TNewsData> = [
 {
  title: "Thumbnail",
  dataIndex: "imageThumbnail",
  key: "imageThumbnail",
  render(value) {
   return <Image src={`${API_SERVER}/${value}`} />;
  },
 },
 {
  title: "Tiêu đề",
  dataIndex: "title",
  key: "title",
 },
 {
  title: "Tóm tắt",
  dataIndex: "summary",
  key: "summary",
 },
 {
  title: "Ngày tạo",
  dataIndex: "date",
  key: "date",
 },
 //  {
 //   title: "Chuyển hướng",
 //   dataIndex: "productHref",
 //   key: "productHref",
 //   render(value, record: any) {
 //    const handleClick = () =>
 //     message.info("Vui lòng công khai sản phẩm trước khi xem chuyển hướng");
 //    return (
 //     <>
 //      {record.productActive === 1 ? (
 //       <a href={window.location.origin + "/san-pham/" + value} target='window'>
 //        Xem chi tiết
 //       </a>
 //      ) : (
 //       <Link onClick={handleClick}>Xem chi tiết</Link>
 //      )}
 //     </>
 //    );
 //   },
 //  },
 //  {
 //   title: "Nội dung trang",
 //   dataIndex: "html",
 //   key: "html",
 //   width: 150,
 //   render(value, record, index) {
 //    return <Link>XEM TRƯỚC</Link>;
 //   },
 //  },
 {
  title: "Trạng thái",
  dataIndex: "active",
  key: "active",
  render(active, record) {
   let text: string, color: string;
   if (active === 1) {
    text = "Công khai";
    color = "#87d068";
   } else {
    text = "Chưa công khai";
    color = "#f50";
   }
   return <Tag color={color}>{text}</Tag>;
  },
 },
 {
  title: "Chỉnh sửa",
  dataIndex: "active",
  key: "active",
  render(_, record) {
   return <Action record={record} />;
  },
 },
];
