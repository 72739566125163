import {
 Button,
 Flex,
 Form,
 Image,
 Input,
 InputNumber,
 Modal,
 message,
} from "antd";
import React from "react";
import { TOrder } from "types";
import { EyeOutlined } from "@ant-design/icons";
import { useQuery, useQueryClient } from "react-query";
import {
 QUERY_ORDER_ADMIN,
 QUERY_PRODUCT_ADMIN,
 patchQueryHelper,
 postQueryHelper,
} from "helpers";
import { getProductQuery } from "../products/query";
import LoadingV2 from "common/V2/LoadingV2";
import _ from "lodash";
import { TCartItems, TProduct } from "@types";
import { API_SERVER } from "helpers/variable";
import CurrencyInput from "react-currency-input-field";
import {
 TParameterAddHistorySendMail,
 addHistorySendEmail,
 updateOrderQuery,
} from "./query";
import ConfirmOrder from "./ConfirmOrder";
import { formatter } from "components/admin/common/formatPrice";
import FormPriceRP from "./FormPriceRP";
import moment from "moment";

export type TResultProduct = {
 image: string;
 name: string;
 quantity: string;
 price: string;
 priceTotal: string;
 discount: string;
};
export type TResultOrder = {
 priceTotal: string;
 discountTotal: string;
 priceAfterDiscount: string;
 products: TResultProduct[];
};

export interface TCartItemsAdmin extends TCartItems {
 price: number;
 priceTotal: number;
 discount: number;
}
type Props = {
 record: TOrder;
};
type TPriceProduct = {
 productId: string;
 price: number;
};

const Action = ({ record }: Props) => {
 const [visibleModal, setVisibleModal] = React.useState<boolean>(false);
 const [dataProductState, setDataProductState] = React.useState<
  TCartItemsAdmin[]
 >([]);
 const [loading, setLoading] = React.useState(false);

 const [resultOrder, setResultOrder] = React.useState<any>();
 const [formEditProductPrice] = Form.useForm();
 const [modalConfirm, setModalConfirm] = React.useState({
  visible: false,
  okText: "Xác nhận",
  cancel: "Hủy bỏ",
  width: "50%",
 });
 const queryClient = useQueryClient();

 const { data: dataProduct, isLoading }: any = useQuery(
  QUERY_PRODUCT_ADMIN,
  () => getProductQuery()
 );

 const onSelectProduct = () => {
  const productArr: TCartItemsAdmin[] = [];
  JSON.parse(record.productId).map(
   (product: { productId: string; quantity: number }) => {
    const filter = _.filter(dataProduct, { productId: product.productId });
    if (filter.length > 0) {
     productArr.push({
      ...filter[0],
      cartQuantity: product.quantity,
      productImages: JSON.parse(filter[0].productImages),
      price: 0,
      priceTotal: 0,
      discount: 0,
     });
    }
   }
  );
  setDataProductState(productArr);
  setVisibleModal(true);
 };

 const onChangeValueProduct = React.useCallback(
  (price: any, index: number, quantity: number) => {
   dataProductState[index].priceTotal = Number(price) * quantity;
   dataProductState[index].price = Number(price);
   setDataProductState(dataProductState);
  },
  [setDataProductState, dataProductState]
 );

 const onChangeDiscountProduct = React.useCallback(
  (discount: any, index: number) => {
   dataProductState[index].discount = Number(discount);
   setDataProductState(dataProductState);
  },
  [setDataProductState, dataProductState]
 );

 const formatDataProduct = () => {
  let priceTotal: number = 0;
  let discountTotal: number = 0;
  dataProductState.map((product: TCartItemsAdmin) => {
   priceTotal += product.priceTotal;
   discountTotal += (product.discount * product.priceTotal) / 100;
  });
  const result = {
   to: record.customerEmail,
   subject: "Bảng Báo Giá Sản Phẩm và Dịch Vụ",
   customerName: record.customerName,
   priceTotal: formatter.format(priceTotal),
   discountTotal: formatter.format(discountTotal),
   priceAfterDiscount: formatter.format(priceTotal - discountTotal),
   products: dataProductState.map((product: TCartItemsAdmin) => ({
    image: API_SERVER + "/" + product.productImages[0],
    name: product.productName,
    quantity: product.cartQuantity,
    price: formatter.format(product.price),
    priceTotal: formatter.format(product.priceTotal),
    discount: formatter.format((product.discount * product.priceTotal) / 100),
   })),
  };
  setResultOrder(result);
 };

 const onFinish = async () => {
  setLoading(true);
  const parametersHistory: TParameterAddHistorySendMail = {
   customerEmail: record.customerEmail,
   customerName: record.customerName,
   customerPhone: record.customerPhone,
   data: JSON.stringify(resultOrder),
   status: 1,
   time: moment().format("DD/MM/YYYY HH:mm:ss"),
   typeEmail: "BÁO GIÁ",
  };
  const response: any = await postQueryHelper(
   "/mail/send-email/bao-gia",
   resultOrder
  );
  if (response.message === "success") {
   await updateOrderQuery(record.id, {
    status: 1,
   });
   //  await addHistorySendEmail({
   //   ...parametersHistory,
   //   status: 1,
   //  });
   queryClient.invalidateQueries(QUERY_ORDER_ADMIN);
   message.success("Gửi Email báo giá thành công.");
   setTimeout(() => {
    setLoading(false);
    setVisibleModal(false);
   }, 1000);
  } else {
   await addHistorySendEmail({
    ...parametersHistory,
    status: 0,
   });
   message.error(response);
   setTimeout(() => {
    setLoading(false);
   }, 1000);
  }
 };

 return (
  <div>
   <Modal
    open={visibleModal}
    title='THÔNG TIN BÁO GIÁ'
    okText={modalConfirm.okText}
    cancelText={modalConfirm.cancel}
    confirmLoading={loading}
    width={modalConfirm.width}
    onCancel={() => {
     if (modalConfirm.visible) {
      setModalConfirm({
       visible: false,
       okText: "Xác nhận",
       cancel: "Hủy bỏ",
       width: "50%",
      });
     } else {
      setVisibleModal(false);
     }
    }}
    onOk={() => {
     if (modalConfirm.visible) {
      onFinish();
     } else {
      formatDataProduct();
      setModalConfirm({
       visible: true,
       okText: "Gửi Email",
       cancel: "Quay lại",
       width: "70%",
      });
     }
    }}
   >
    {isLoading ? (
     <LoadingV2 />
    ) : (
     <>
      {!modalConfirm.visible ? (
       <FormPriceRP
        dataProduct={dataProductState}
        form={formEditProductPrice}
        onFinish={onFinish}
        onChangePriceProduct={onChangeValueProduct}
        setDiscount={onChangeDiscountProduct}
       />
      ) : (
       <ConfirmOrder dataProduct={resultOrder} />
      )}
     </>
    )}
   </Modal>
   <Button type='primary' onClick={onSelectProduct} icon={<EyeOutlined />} />
  </div>
 );
};

export default Action;
