import { MaxScreenDevice } from "assets/screens/DeviceScreens";
import styled from "styled-components";
export const FormInformationStyled = styled.div`
 .form-information-input {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  .input-form {
   height: 40px;
   .ant-input-wrapper {
    height: 100%;
   }
   input {
    height: 100%;
   }
  }
 }
 @media only screen and (${MaxScreenDevice.laptop}) {
  .form-information-input {
   grid-template-columns: repeat(1, 1fr);
   gap: 0;
  }
 }
`;

export const EstimationStyled = styled.div`
 .estimation {
  display: flex;
  align-items: center;
  justify-content: space-between;
  &__item {
   &-label {
    margin: 20px 0;
    h3 {
     line-height: 100% !important;
     color: ${({ theme }) => theme.colors.black};
     font-size: 16px;
     text-transform: uppercase;
    }
   }
   &-result {
    margin: 20px 0;
    p {
     line-height: 100% !important;
     color: ${({ theme }) => theme.colors.black};
     font-size: 16px;
     font-weight: 500;
     text-align: center;
    }
   }
   &-icon {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
     width: 100%;
     height: 100%;
     object-fit: contain;
     path {
      fill: ${({ theme }) => theme.colors.main_yellow};
      color: ${({ theme }) => theme.colors.main_yellow};
      stroke-width: 1px;
     }
    }
   }
  }
 }
 @media only screen and (${MaxScreenDevice.laptop}) {
  .estimation {
   flex-direction: column;
  }
 }
`;
