import styled from "styled-components";
export const ContactInfoStyled = styled.div`
 .contact-info {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  &-item {
   display: flex;
   align-items: start;
   gap: 10px;
   i {
    color: ${({ theme }) => theme.colors.main_green};
    font-size: 16px;
    width: 20px;
   }
   .label {
    color: ${({ theme }) => theme.colors.black};
    font-size: 16px;
    a {
     text-decoration: none;
     transition: all 0.4s ease;
     color: ${({ theme }) => theme.colors.gray[600]};
     &:hover {
      color: ${({ theme }) => theme.colors.main_green};
     }
    }
   }
  }
 }
`;
