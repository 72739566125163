import { CarouselManage } from "components/admin/Content/UIManage";
import { HomeManageComponentAdmin } from "components/admin/Content/UIManage";
import { CategoryPageAdmin } from "components/admin/Content/category";
import { MessagePageAdmin } from "components/admin/Content/message";
import { NewsPageAdmin } from "components/admin/Content/news";
import { OrderPageAdmin } from "components/admin/Content/order";
import {
 AddProductManage,
 ProductManage,
} from "components/admin/Content/products";
import { ProjectPageAdmin } from "components/admin/Content/project";
import { SolutionPageAdmin } from "components/admin/Content/solution";
import { SolutionPackageAdmin } from "components/admin/Content/solutionPackage";
import {
 AboutUsIcon,
 CarbonProduct,
 DashiconsCategory,
 DashiconsPlus,
 EosIconsProjectOutlined,
 ImageIcon,
 MessageIcon,
 SolutionOutlined,
} from "icons";

import { TRoutes } from "types";

const AdminRoute: TRoutes[] = [
 {
  layout: "admin",
  path: "/admin",
  title: "main",
  views: [
   //  {
   //   name: "Thống kê",
   //   path: "",
   //   component: <MainDashboard />,
   //   icon: <i className='fa-solid fa-home'></i>,
   //  },
   //  {
   //   name: "Quản lý công việc",
   //   path: "quan-ly-cong-viec",
   //   component: <JobManage />,
   //   icon: <i className='fa-solid fa-clipboard'></i>,
   //  },
   {
    name: "Tin nhắn / Thông tin tư vấn",
    path: "tin-nhan-thong-tin-tu-van",
    component: <MessagePageAdmin />,
    icon: <MessageIcon />,
   },
   {
    name: "Đơn hàng",
    path: "don-hang",
    component: <OrderPageAdmin />,
    icon: <i className='fa-solid fa-clipboard'></i>,
   },
  ],
 },

 {
  layout: "admin",
  path: "/admin",
  title: "Quản lý sản phẩm",
  views: [
   {
    name: "Tất cả sản phẩm",
    path: "product",
    component: <ProductManage />,
    icon: <CarbonProduct />,
   },
   {
    name: "Thêm sản phẩm",
    path: "product/add",
    component: <AddProductManage />,
    icon: <DashiconsPlus />,
   },
  ],
 },
 {
  layout: "admin",
  path: "/admin",
  title: "Danh mục sản phẩm",
  views: [
   {
    name: "Danh mục sản phẩm",
    path: "danh-muc-san-pham",
    component: <CategoryPageAdmin />,
    icon: <DashiconsCategory />,
   },
  ],
 },
 {
  layout: "admin",
  path: "/admin",
  title: "Quản lý",
  views: [
   {
    name: "Giải pháp",
    path: "giai-phap",
    component: <SolutionPageAdmin />,
    icon: <SolutionOutlined />,
   },
   {
    name: "Dự án",
    path: "du-an-da-thuc-hien",
    component: <ProjectPageAdmin />,
    icon: <EosIconsProjectOutlined />,
   },
   {
    name: "Tin tức",
    path: "tin-tuc",
    component: <NewsPageAdmin />,
    icon: <EosIconsProjectOutlined />,
   },
   {
    name: "Gói giải pháp",
    path: "goi-giai-phap",
    component: <SolutionPackageAdmin />,
    icon: <EosIconsProjectOutlined />,
   },
  ],
 },
 {
  layout: "admin",
  path: "/admin",
  title: "KHÁC",
  views: [
    {
     name: "Giới thiệu về DTSolar",
     path: "gioi-thieu",
     component: <HomeManageComponentAdmin />,
     icon: <AboutUsIcon />,
    },
   //  {
   //   name: "Menu",
   //   path: "navbar-manage",
   //   component: <NavbarManage />,
   //   icon: <MenuIcon />,
   //  },
   {
    name: "Carousel",
    path: "carousel-manage",
    component: <CarouselManage />,
    icon: <ImageIcon />,
   },
  ],
 },
];
export default AdminRoute;
