import Banner from "common/Banner";
import React from "react";
import Solution from "./Solution";
import SystemEstimation from "./SystemEstimation";
import SolutionPackage from "./SolutionPackage";
import AboutUsComponent from "components/Home/HomeComponent/AboutUs.component";
import BrandsComponent from "components/Home/HomeComponent/Brands.component";

const Component = () => {
 const breadcrumb = [
  {
   title: "Trang Chủ",
   href: "/",
  },
  {
   title: "Hộ Gia Đình",
   href: null,
  },
 ];

 const scrollToTop = () => {
  window.scrollTo({
   top: 0,
   left: 0,
   behavior: "smooth",
  });
 };

 React.useEffect(() => {
  scrollToTop();
 }, []);

 return (
  <>
   <Banner breadcrumb={breadcrumb} />
   <Solution />
   <SystemEstimation />
   <SolutionPackage />
   <AboutUsComponent href='/lien-he' buttonText='NHẬN TƯ VẤN VÀ BÁO GIÁ' />
   <BrandsComponent />
  </>
 );
};

export default Component;
