import { SolutionPackageStyled } from "assets/styles/V2/HouseHold/SolutionPackage";
import Container from "common/Container";
import React from "react";
import { Navigation, Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Form, Modal } from "antd";
import ContactModal from "components/Home/common/ContactModal";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { TSolutionPackage } from "@types";
import { formatter } from "components/admin/common/formatPrice";

const SolutionPackage = () => {
 const [selectedSolutionPackage, setSelectedSolutionPackage] = React.useState<{
  [key: string]: string;
 }>();
 const { solutionPackage } = useSelector(
  (state: RootState) => state.solutionPackage
 );

 const handleClickSupport = (data: TSolutionPackage) => {
  setSelectedSolutionPackage({
   "Công suất": data.solutionPackagePerformance + " kWp",
   "Số tấm pin": data.solutionPackagePanels + " Tấm pin",
   "Điện tạo ra": data.solutionPackageElectricity + " kWp",
   "Số tiền tiết kiệm": formatter.format(Number(data.solutionPackageMoney)),
  });
  setVisibleContactModal(true);
 };

 const [visibleContactModal, setVisibleContactModal] =
  React.useState<boolean>(false);
 const swiperRef = React.useRef<any>(null);

 const CustomNavigation = () => (
  <div className='navigation-custom'>
   <button
    onClick={() => {
     swiperRef.current.slidePrev();
    }}
   >
    <i className='fa-solid fa-angle-left'></i>
   </button>
   <button
    onClick={() => {
     swiperRef.current.slideNext();
    }}
   >
    <i className='fa-solid fa-angle-right'></i>
   </button>
  </div>
 );

 return (
  <SolutionPackageStyled>
   <ContactModal
    setCloseModal={setVisibleContactModal}
    visible={visibleContactModal}
    dataCustomize={selectedSolutionPackage}
   />
   <Container>
    <div className='solution-package'>
     <div className='solution-package__heading'>
      <h2>CÁC GÓI GIẢI PHÁP</h2>
      <p>Lựa chọn hệ thống phù hợp với nhu cầu của bạn</p>
     </div>
     <div className='solution-package__carousel'>
      <Swiper
       modules={[Navigation, Autoplay]}
       spaceBetween={50}
       slidesPerView={2}
       loop={true}
       autoplay={{
        delay: 3000,
        disableOnInteraction: false,
       }}
       onSwiper={(swiper) => {
        swiperRef.current = swiper;
       }}
       className='carousel'
       breakpoints={{
        200: {
         slidesPerView: 1,
         spaceBetween: 20,
         centeredSlides: true,
        },
        688: {
         slidesPerView: 2,
        },
       }}
      >
       {solutionPackage?.map((solutionPackage: TSolutionPackage) => (
        <SwiperSlide key={solutionPackage.solutionPackageId}>
         <div className='solution-package__carousel-item'>
          <div className='top'>
           <div className='content'>
            <h4>Công suất</h4>
            <p>{solutionPackage.solutionPackagePerformance} (kWp)</p>
           </div>
           <div className='content'>
            <h4>Số tấm pin</h4>
            <p>{solutionPackage.solutionPackagePanels} (Tấm pin)</p>
           </div>
          </div>
          <div className='bottom'>
           <div className='content'>
            <h4>ĐIỆN TẠO RA</h4>
            <p>{solutionPackage.solutionPackageElectricity} (kWp)</p>
           </div>
           <div className='content'>
            <h4>Số tiền TIẾT kiệm</h4>
            <p>
             {formatter.format(Number(solutionPackage.solutionPackageMoney))}
            </p>
           </div>
          </div>
          <div className='button'>
           <button onClick={() => handleClickSupport(solutionPackage)}>
            NHẬN TƯ VẤN VÀ BÁO GIÁ <i className='fa-solid fa-arrow-right'></i>
           </button>
          </div>
         </div>
        </SwiperSlide>
       ))}
       <CustomNavigation />
      </Swiper>
     </div>
    </div>
   </Container>
  </SolutionPackageStyled>
 );
};

export default SolutionPackage;
