import { TNews } from "@types";
import React from "react";
import { useNavigate } from "react-router-dom";
import parse from "html-react-parser";
import { NewsCardStyle } from "assets/styles/page/news/_News.styled";
import { API_SERVER } from "helpers/variable";

type TProps = {
 newItem: TNews;
};

const NewCard = ({ newItem }: TProps) => {
 const navigate = useNavigate();
 const handleClickNew = (href: string) => {
  navigate(`/tin-tuc/${href}`);
 };
 return (
  <NewsCardStyle onClick={() => handleClickNew(newItem.href)}>
   <div className='image'>
    <img src={`${API_SERVER}/${newItem.imageThumbnail}`} alt={newItem.title} />
   </div>
   <div className='content'>
    <div className='content__title'>
     <h3>{newItem.title}</h3>
    </div>
    <div className='content__date'>
     <p>{newItem.date}</p>
    </div>
    <div className='content__subtitle'>{newItem.summary}</div>
   </div>
  </NewsCardStyle>
 );
};

export default NewCard;
