import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import { FreeMode, Navigation, Thumbs } from "swiper/modules";
import { CarouselDetailProductStyled } from "assets/styles/page/detailProduct/_Carousel.detailProduct";
import { API_SERVER } from "helpers/variable";

const CarouselDetailProduct = ({ images }: { images: string[] }) => {
 const [thumbsSwiper, setThumbsSwiper] = React.useState<any>(null);

 const RenderImage = () => {
  return (
   <>
    {images.map((image: string, key: number) => (
     <SwiperSlide key={key}>
      <img src={`${API_SERVER}/${image}`} alt={`${image}`} />
     </SwiperSlide>
    ))}
   </>
  );
 };

 return (
  <CarouselDetailProductStyled>
   <div className='carousel'>
    <Swiper
     loop={true}
     spaceBetween={10}
     slidesPerView={1}
     thumbs={{
      swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null,
     }}
     modules={[FreeMode, Navigation, Thumbs]}
     className='mySwiper2'
    >
     {RenderImage()}
    </Swiper>
   </div>
   <div className='image__list'>
    <Swiper
     onSwiper={(value) => {
      setThumbsSwiper(value);
     }}
     spaceBetween={10}
     slidesPerView={4}
     freeMode={true}
     watchSlidesProgress={true}
     modules={[FreeMode, Navigation, Thumbs]}
     className='mySwiper'
    >
     {RenderImage()}
    </Swiper>
   </div>
  </CarouselDetailProductStyled>
 );
};

export default CarouselDetailProduct;
