import {
 deleteQueryHelper,
 getQueryHelper,
 patchQueryHelper,
 postQueryHelper,
} from "helpers";

export const getAllProjectData = () => {
 return getQueryHelper("/project/get-all-project");
};
export const postDataProjectData = (parameters: { image: string }) => {
 return postQueryHelper("/project/post-image-project", parameters);
};
export const updateProjectData = (
 parameters: { active: number },
 idProject: string
) => {
 return patchQueryHelper(
  `/project/update-project-data/${idProject}`,
  parameters
 );
};

export const deleteProjectData = (id: string) => {
 return deleteQueryHelper(`/project/delete-project-data/${id}`);
};
