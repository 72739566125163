import { MaxScreenDevice } from "assets/screens/DeviceScreens";
import styled from "styled-components";
export const ProjectStyled = styled.div`
 .project {
  &-title {
   margin: 100px 0 50px;
   h1 {
    text-align: center;
    font-size: 35px;
    font-weight: 600;
    text-transform: uppercase;
   }
  }
 }
 @media only screen and (${MaxScreenDevice.tablet}) {
  .project {
   &-title {
    margin: 50px 0 20px;
   }
  }
 }
`;
