import { getQueryHelper } from "helpers";
const getAllNavbar = async () => {
 const response = await getQueryHelper("/navbar/active");
 if (response) {
  return response;
 }
};

export const navbarService = {
 getAllNavbar,
};
