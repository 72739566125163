import {
 deleteQueryHelper,
 getQueryHelper,
 patchQueryHelper,
 postQueryHelper,
} from "helpers";
import { TFromValues } from "./type";
import { TValueUpdateSolutionPackage } from "./Action";

export const getAllSolutionPackage = () => {
 return getQueryHelper("/solution-packages/get-all-solution-package");
};
export const addSolutionPackage = (parameter: TFromValues) => {
 return postQueryHelper("/solution-packages/add-solution-package", parameter);
};
export const updateSolutionPackage = (
 id: string,
 parameter: TValueUpdateSolutionPackage
) => {
 return patchQueryHelper(
  `/solution-packages/update-solution-package-by-id/${id}`,
  parameter
 );
};
export const deleteSolutionPackage = (id: string) => {
 return deleteQueryHelper(
  `/solution-packages/delete-solution-package-by-id/${id}`
 );
};
