import styled from "styled-components";

export const NewPostStyled = styled.div`
 width: 100%;
 .title {
  background-color: ${({ theme }) => theme.colors.blue[800]};
  border-radius: 5px;
  margin: 0 0 10px 0;
  padding: 10px 15px;
  display: flex;
  align-items: center;
  gap: 10px;
  i {
   color: ${({ theme }) => theme.colors.white};
  }
  h2 {
   font-size: 16px;
   font-weight: 500;
   color: ${({ theme }) => theme.colors.white};
  }
 }
 .content {
  border-radius: 5px;
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.gray[200]};
  .post-list {
   display: flex;
   flex-direction: column;
   &__item {
    display: flex;
    align-items: start;
    gap: 10px;
    padding: 10px 8px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray[200]};
    cursor: pointer;
    transition: all 0.4s ease;
    text-decoration: none;
    .image {
     width: 70px;
     height: 70px;
     img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 10px;
     }
    }
    .content-new {
     width: calc(100% - 80px);
     .date {
      p {
       font-size: 13px;
       color: ${({ theme }) => theme.colors.gray[400]};
      }
     }
     .label {
      margin-top: 10px;
      h4 {
       font-size: 14px;
       font-weight: 400px;
       color: ${({ theme }) => theme.colors.black};
       display: -webkit-box;
       -webkit-line-clamp: 2;
       -webkit-box-orient: vertical;
       overflow: hidden;
       text-overflow: ellipsis;
      }
     }
    }
    &:hover {
     background-color: ${({ theme }) => theme.colors.gray[100]};
    }
   }
  }
 }
`;
