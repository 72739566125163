import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { TSolutionPackage } from "@types";
import { solutionPackageService } from "features/services";

export type TSolutionPackageRedux = {
 solutionPackage: TSolutionPackage[] | null;
 isError: boolean;
 isSuccess: boolean;
 isLoading: boolean;
 message: string;
};

const initialState: TSolutionPackageRedux = {
 solutionPackage: null,
 isError: false,
 isSuccess: false,
 isLoading: false,
 message: "",
};

export const getAllSolutionPackage = createAsyncThunk(
 "solutionPackage/all",
 async () => {
  try {
   return await solutionPackageService.getAllSolutionPackage();
  } catch (error: any) {
   const message =
    (error.response && error.response.data && error.response.data.message) ||
    error.message ||
    error.toString();
   return message;
  }
 }
);

export const solutionPackageSlice = createSlice({
 name: "solutionPackage",
 initialState,
 reducers: {
  allSolutionPackageActive: (state: TSolutionPackageRedux) => {
   state.isLoading = false;
   state.isSuccess = false;
   state.isError = false;
   state.message = "";
  },
 },
 extraReducers(builder) {
  builder
   .addCase(getAllSolutionPackage.pending, (state: TSolutionPackageRedux) => {
    state.isLoading = true;
   })
   .addCase(
    getAllSolutionPackage.fulfilled,
    (state: TSolutionPackageRedux, action: any) => {
     state.isLoading = false;
     state.isSuccess = true;
     state.solutionPackage = action.payload;
    }
   )
   .addCase(
    getAllSolutionPackage.rejected,
    (state: TSolutionPackageRedux, action: any) => {
     state.isLoading = false;
     state.isError = true;
     state.message = action.payload;
    }
   );
 },
});

export const { allSolutionPackageActive } = solutionPackageSlice.actions;
export default solutionPackageSlice.reducer;
