import { QUERY_SOLUTION_PACKAGE_ADMIN } from "helpers";
import React from "react";
import { useQuery, useQueryClient } from "react-query";
import { addSolutionPackage, getAllSolutionPackage } from "./query";
import Heading from "components/admin/common/Header";
import { Button, Form, Modal, Table, message } from "antd";
import { columns } from "./columns";
import { TFromValues } from "./type";
import FormContent from "./FormContent";

const Component = () => {
 const [visibleModal, setVisibleModal] = React.useState<boolean>(false);
 const [isLoadingConfirm, setIsLoadingConfirm] = React.useState<boolean>(false);

 const [formSolutionPackage] = Form.useForm();
 const queryClient = useQueryClient();

 const { data: dataSolutionPackage, isLoading }: any = useQuery(
  QUERY_SOLUTION_PACKAGE_ADMIN,
  () => getAllSolutionPackage()
 );

 const handleCloseModal = () => {
  formSolutionPackage.resetFields();
  setVisibleModal(false);
 };

 const onSubmit = React.useCallback(
  async (values: TFromValues) => {
   const response: any = await addSolutionPackage(values);
   setIsLoadingConfirm(false);
   if (response?.type === "error") return message.error(response.error);
   queryClient.invalidateQueries(QUERY_SOLUTION_PACKAGE_ADMIN);
   message.success("Thêm thành công");
   formSolutionPackage.resetFields();

   setVisibleModal(false);
  },
  [setIsLoadingConfirm, setVisibleModal, formSolutionPackage]
 );
 return (
  <>
   <Modal
    open={visibleModal}
    title='GÓI GIẢI PHÁP'
    okText='Cập nhật'
    cancelText='Hủy'
    onCancel={handleCloseModal}
    confirmLoading={isLoadingConfirm}
    onOk={() => {
     setIsLoadingConfirm(true);
     formSolutionPackage.submit();
    }}
   >
    <FormContent form={formSolutionPackage} onSubmit={onSubmit} type='add' />
   </Modal>
   <Heading title='DỰ ÁN ĐÃ THỰC HIỆN'>
    <Button
     onClick={() => setVisibleModal(true)}
     type='primary'
     icon={<i className='fa-solid fa-plus'></i>}
    >
     THÊM
    </Button>
   </Heading>
   <Table
    columns={columns}
    dataSource={dataSolutionPackage}
    loading={isLoading}
   />
  </>
 );
};

export default Component;
