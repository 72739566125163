import { LockOutlined, UnlockOutlined } from "@ant-design/icons";
import { Button, Flex, message } from "antd";
import { QUERY_PROJECT_ADMIN } from "helpers";
import { useQueryClient } from "react-query";
import RemoveButton from "components/admin/common/RemoveButton";
import { TDataProjectAdmin } from "./Table.project";
import { deleteProjectData, updateProjectData } from "./fetch";
type TProps = {
 record: TDataProjectAdmin;
};

const Action = ({ record }: TProps) => {
 const queryClient = useQueryClient();

 const handleRemoveCarousel = (id: string) => {
  const response: any = deleteProjectData(id);
  if (response?.type === "error") {
   message.error(response.error);
  }
  queryClient.invalidateQueries(QUERY_PROJECT_ADMIN);
  message.success("Xóa dữ liệu thành công");
 };
 const handleUpdateStatus = async (id: string, active: number) => {
  const response: any = await updateProjectData({ active }, id);
  if (response.type === "error") {
   return message.error(response.error);
  }
  message.success("Cập nhật dữ liệu thành công");
  queryClient.invalidateQueries(QUERY_PROJECT_ADMIN);
 };

 return (
  <Flex gap={10} justify='center'>
   {record.projectActive === 0 ? (
    <Button
     type='dashed'
     icon={<UnlockOutlined />}
     onClick={() => handleUpdateStatus(record.projectId, 1)}
    />
   ) : (
    <Button
     type='dashed'
     danger
     icon={<LockOutlined />}
     onClick={() => handleUpdateStatus(record.projectId, 0)}
    />
   )}
   <RemoveButton
    onClick={() => handleRemoveCarousel(record.projectId)}
    id={record.projectId}
   />
  </Flex>
 );
};

export default Action;
