import { Card, Pagination } from "antd";
import Meta from "antd/es/card/Meta";
import { ProjectListStyled } from "assets/styles/V2/Projects/List.styled";
import Container from "common/Container";
import React from "react";

const ProjectList = () => {
 return (
  <ProjectListStyled>
   <Container>
    <div className='project-list'>
     {Array.from({ length: 16 }).map((item: any, index: number) => (
      <Card
       hoverable
       key={index}
       className='item'
       cover={
        <img
         className='image-project'
         alt={`project-image-${index + 1}`}
         src='images/image-project-demo.jpg'
        />
       }
      >
       <div className='content'>
        <div className='title'>
         <h3>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime,
          impedit. Tempora unde ipsum est sint molestiae sit repellat commodi
          asperiores. Mollitia voluptate cumque sequi similique. Nihil accusamus
          impedit quisquam. Nisi!
         </h3>
        </div>
        <div className='button-see-more'>
         <button>
          XEM THÊM <i className='fa-solid fa-angles-right'></i>
         </button>
        </div>
       </div>
      </Card>
     ))}
    </div>
    <div className='pagination'>
     <Pagination defaultCurrent={1} total={50} />
    </div>
   </Container>
  </ProjectListStyled>
 );
};

export default ProjectList;
