import React from "react";
import { Pagination, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { CarouselStyled } from "assets/styles";
import { RootState } from "store";
import { useSelector } from "react-redux";
import { TCarousel } from "@types";
import { API_SERVER } from "helpers/variable";

const Carousel = () => {
 const swiperRef = React.useRef<any>(null);
 const carouselData = useSelector((state: RootState) => state.carousel);
 const CustomNavigation = () => (
  <div className='navigation-custom'>
   <button
    onClick={() => {
     swiperRef.current.slidePrev();
    }}
   >
    <i className='fa-solid fa-angle-left'></i>
   </button>
   <button
    onClick={() => {
     swiperRef.current.slideNext();
    }}
   >
    <i className='fa-solid fa-angle-right'></i>
   </button>
  </div>
 );

 return (
  <CarouselStyled>
   {carouselData.carousel && (
    <Swiper
     modules={[Pagination, Navigation]}
     pagination={{
      clickable: true,
     }}
     spaceBetween={50}
     slidesPerView={1}
     onSwiper={(swiper) => {
      swiperRef.current = swiper;
     }}
    >
     {carouselData?.carousel.map((item: TCarousel, index: number) => (
      <SwiperSlide key={index}>
       <img
        className='image-swiper'
        src={API_SERVER + "/" + item.imageUrl}
        alt={`${index}`}
       />
      </SwiperSlide>
     ))}
     <CustomNavigation />
    </Swiper>
   )}
  </CarouselStyled>
 );
};

export default Carousel;
