import React from "react";
import { CardStyled } from "assets/styles/_Card";
import { NavLink, useNavigate } from "react-router-dom";
import { API_SERVER } from "helpers/variable";
import { TProduct } from "@types";

interface TProps {
 item: TProduct;
}

const Card = ({ item }: TProps) => {
 const navigate = useNavigate();
 return (
  <CardStyled>
   <div className='card__image'>
    <img
     src={`${API_SERVER}/${item.productImages[0]}`}
     alt={item.productName}
    />
   </div>
   <div className='card__content'>
    <div className='card__content-title'>
     <NavLink to={`/san-pham/${item.productHref}`}>{item.productName}</NavLink>
    </div>
    <div
     className='card__content-link'
     onClick={() => navigate(`/san-pham/${item.productHref}`)}
    >
     <p>XEM CHI TIẾT</p>
     <i className='fa-solid fa-angle-right'></i>
    </div>
   </div>
  </CardStyled>
 );
};

export default Card;
