/* eslint-disable @typescript-eslint/no-explicit-any */

export const SimpleIconsZalo = (props: any) => (
 <svg
  xmlns='http://www.w3.org/2000/svg'
  x='0px'
  y='0px'
  width='100'
  height='100'
  viewBox='0 0 48 48'
 >
  <path
   fill='#2962ff'
   d='M15,36V6.827l-1.211-0.811C8.64,8.083,5,13.112,5,19v10c0,7.732,6.268,14,14,14h10	c4.722,0,8.883-2.348,11.417-5.931V36H15z'
  ></path>
  <path
   fill='#eee'
   d='M29,5H19c-1.845,0-3.601,0.366-5.214,1.014C10.453,9.25,8,14.528,8,19	c0,6.771,0.936,10.735,3.712,14.607c0.216,0.301,0.357,0.653,0.376,1.022c0.043,0.835-0.129,2.365-1.634,3.742	c-0.162,0.148-0.059,0.419,0.16,0.428c0.942,0.041,2.843-0.014,4.797-0.877c0.557-0.246,1.191-0.203,1.729,0.083	C20.453,39.764,24.333,40,28,40c4.676,0,9.339-1.04,12.417-2.916C42.038,34.799,43,32.014,43,29V19C43,11.268,36.732,5,29,5z'
  ></path>
  <path
   fill='#2962ff'
   d='M36.75,27C34.683,27,33,25.317,33,23.25s1.683-3.75,3.75-3.75s3.75,1.683,3.75,3.75	S38.817,27,36.75,27z M36.75,21c-1.24,0-2.25,1.01-2.25,2.25s1.01,2.25,2.25,2.25S39,24.49,39,23.25S37.99,21,36.75,21z'
  ></path>
  <path
   fill='#2962ff'
   d='M31.5,27h-1c-0.276,0-0.5-0.224-0.5-0.5V18h1.5V27z'
  ></path>
  <path
   fill='#2962ff'
   d='M27,19.75v0.519c-0.629-0.476-1.403-0.769-2.25-0.769c-2.067,0-3.75,1.683-3.75,3.75	S22.683,27,24.75,27c0.847,0,1.621-0.293,2.25-0.769V26.5c0,0.276,0.224,0.5,0.5,0.5h1v-7.25H27z M24.75,25.5	c-1.24,0-2.25-1.01-2.25-2.25S23.51,21,24.75,21S27,22.01,27,23.25S25.99,25.5,24.75,25.5z'
  ></path>
  <path
   fill='#2962ff'
   d='M21.25,18h-8v1.5h5.321L13,26h0.026c-0.163,0.211-0.276,0.463-0.276,0.75V27h7.5	c0.276,0,0.5-0.224,0.5-0.5v-1h-5.321L21,19h-0.026c0.163-0.211,0.276-0.463,0.276-0.75V18z'
  ></path>
 </svg>
);

export const IconPhone = (props: any) => (
 <svg
  width='1em'
  height='1em'
  viewBox='0 0 512 512'
  xmlns='http://www.w3.org/2000/svg'
  {...props}
 >
  <path
   fill='#000000'
   d='m493.4 24.6l-104-24c-11.3-2.6-22.9 3.3-27.5 13.9l-48 112c-4.2 9.8-1.4 21.3 6.9 28l60.6 49.6c-36 76.7-98.9 140.5-177.2 177.2l-49.6-60.6c-6.8-8.3-18.2-11.1-28-6.9l-112 48C3.9 366.5-2 378.1.6 389.4l24 104C27.1 504.2 36.7 512 48 512c256.1 0 464-207.5 464-464c0-11.2-7.7-20.9-18.6-23.4'
  ></path>
 </svg>
);

export const EditIcon = (props: any) => (
 <svg
  width='1.13em'
  height='1em'
  viewBox='0 0 576 512'
  xmlns='http://www.w3.org/2000/svg'
  {...props}
 >
  <path
   fill='#000000'
   d='m402.3 344.9l32-32c5-5 13.7-1.5 13.7 5.7V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V112c0-26.5 21.5-48 48-48h273.5c7.1 0 10.7 8.6 5.7 13.7l-32 32c-1.5 1.5-3.5 2.3-5.7 2.3H48v352h352V350.5c0-2.1.8-4.1 2.3-5.6m156.6-201.8L296.3 405.7l-90.4 10c-26.2 2.9-48.5-19.2-45.6-45.6l10-90.4L432.9 17.1c22.9-22.9 59.9-22.9 82.7 0l43.2 43.2c22.9 22.9 22.9 60 .1 82.8M460.1 174L402 115.9L216.2 301.8l-7.3 65.3l65.3-7.3zm64.8-79.7l-43.2-43.2c-4.1-4.1-10.8-4.1-14.8 0L436 82l58.1 58.1l30.9-30.9c4-4.2 4-10.8-.1-14.9'
  ></path>
 </svg>
);
export const AboutUsIcon = (props: any) => (
 <svg
  width='1em'
  height='1em'
  viewBox='0 0 48 48'
  xmlns='http://www.w3.org/2000/svg'
  {...props}
 >
  <path
   fill='#2196F3'
   d='M37 40H11l-6 6V12c0-3.3 2.7-6 6-6h26c3.3 0 6 2.7 6 6v22c0 3.3-2.7 6-6 6'
  ></path>
  <g fill='#fff'>
   <path d='M22 20h4v11h-4z'></path>
   <circle cx='24' cy='15' r='2'></circle>
  </g>
 </svg>
);

export const MessageIcon = (props: any) => (
 <svg
  width='1em'
  height='1em'
  viewBox='0 0 26 26'
  xmlns='http://www.w3.org/2000/svg'
  {...props}
 >
  <path
   fill='#000000'
   d='M23 4H3C1.3 4 0 5.3 0 7v12c0 1.7 1.3 3 3 3h20c1.7 0 3-1.3 3-3V7c0-1.7-1.3-3-3-3m.8 15.4L16 13.8l-3 2l-3.1-2l-7.7 5.6l6.3-6.5l-7.7-6L13 13.5L25.1 7l-7.6 6z'
  ></path>
 </svg>
);
export const MenuIcon = (props: any) => (
 <svg
  width='1em'
  height='1em'
  viewBox='0 0 512 512'
  xmlns='http://www.w3.org/2000/svg'
  {...props}
 >
  <path
   fill='#000000'
   d='M186.2 139.6h139.6V0H186.2zM372.4 0v139.6H512V0zM0 139.6h139.6V0H0zm186.2 186.2h139.6V186.2H186.2zm186.2 0H512V186.2H372.4zM0 325.8h139.6V186.2H0zM186.2 512h139.6V372.4H186.2zm186.2 0H512V372.4H372.4zM0 512h139.6V372.4H0z'
  ></path>
 </svg>
);

export const ImageIcon = (props: any) => (
 <svg
  width='1.13em'
  height='1em'
  viewBox='0 0 576 512'
  xmlns='http://www.w3.org/2000/svg'
  {...props}
 >
  <path
   fill='#000000'
   d='M480 416v16c0 26.51-21.49 48-48 48H48c-26.51 0-48-21.49-48-48V176c0-26.51 21.49-48 48-48h16v208c0 44.112 35.888 80 80 80zm96-80V80c0-26.51-21.49-48-48-48H144c-26.51 0-48 21.49-48 48v256c0 26.51 21.49 48 48 48h384c26.51 0 48-21.49 48-48M256 128c0 26.51-21.49 48-48 48s-48-21.49-48-48s21.49-48 48-48s48 21.49 48 48m-96 144l55.515-55.515c4.686-4.686 12.284-4.686 16.971 0L272 256l135.515-135.515c4.686-4.686 12.284-4.686 16.971 0L512 208v112H160z'
  ></path>
 </svg>
);

export const CarbonProduct = (props: any) => (
 <svg
  width='1em'
  height='1em'
  viewBox='0 0 32 32'
  xmlns='http://www.w3.org/2000/svg'
  {...props}
 >
  <path fill='#000000' d='M8 18h6v2H8zm0 4h10v2H8z'></path>
  <path
   fill='#000000'
   d='M26 4H6a2.003 2.003 0 0 0-2 2v20a2.003 2.003 0 0 0 2 2h20a2.003 2.003 0 0 0 2-2V6a2.003 2.003 0 0 0-2-2m-8 2v4h-4V6ZM6 26V6h6v6h8V6h6l.001 20Z'
  ></path>
 </svg>
);

export const EosIconsProjectOutlined = (props: any) => (
 <svg
  width='1em'
  height='1em'
  viewBox='0 0 24 24'
  xmlns='http://www.w3.org/2000/svg'
  {...props}
 >
  <circle cx='12' cy='6' r='1' fill='#000000'></circle>
  <path
   fill='#000000'
   d='M6 17h12v2H6zm4-5.17l2.792 2.794l3.932-3.935L18 12V8h-4l1.31 1.275l-2.519 2.519L10 9l-4 4l1.414 1.414z'
  ></path>
  <path
   fill='#000000'
   d='M19 3h-3.298a4.497 4.497 0 0 0-.32-.425l-.01-.012a4.426 4.426 0 0 0-2.89-1.518a2.577 2.577 0 0 0-.964 0a4.426 4.426 0 0 0-2.89 1.518l-.01.012a4.497 4.497 0 0 0-.32.424V3H5a3.003 3.003 0 0 0-3 3v14a3.003 3.003 0 0 0 3 3h14a3.003 3.003 0 0 0 3-3V6a3.003 3.003 0 0 0-3-3m1 17a1 1 0 0 1-1 1H5a1.001 1.001 0 0 1-1-1V6a1.001 1.001 0 0 1 1-1h4.55a2.5 2.5 0 0 1 4.9 0H19a1.001 1.001 0 0 1 1 1Z'
  ></path>
 </svg>
);

export const SolutionOutlined = (props: any) => (
 <svg
  width='1em'
  height='1em'
  viewBox='0 0 1024 1024'
  xmlns='http://www.w3.org/2000/svg'
  {...props}
 >
  <path
   fill='#000000'
   d='M688 264c0-4.4-3.6-8-8-8H296c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8h384c4.4 0 8-3.6 8-8zm-8 136H296c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8h384c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8M480 544H296c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8h184c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8m-48 308H208V148h560v344c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8V108c0-17.7-14.3-32-32-32H168c-17.7 0-32 14.3-32 32v784c0 17.7 14.3 32 32 32h264c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8m356.8-74.4c29-26.3 47.2-64.3 47.2-106.6c0-79.5-64.5-144-144-144s-144 64.5-144 144c0 42.3 18.2 80.3 47.2 106.6c-57 32.5-96.2 92.7-99.2 162.1c-.2 4.5 3.5 8.3 8 8.3h48.1c4.2 0 7.7-3.3 8-7.6C564 871.2 621.7 816 692 816s128 55.2 131.9 124.4c.2 4.2 3.7 7.6 8 7.6H880c4.6 0 8.2-3.8 8-8.3c-2.9-69.5-42.2-129.6-99.2-162.1M692 591c44.2 0 80 35.8 80 80s-35.8 80-80 80s-80-35.8-80-80s35.8-80 80-80'
  ></path>
 </svg>
);

export const DashiconsCategory = (props: any) => (
 <svg
  width='1em'
  height='1em'
  viewBox='0 0 20 20'
  xmlns='http://www.w3.org/2000/svg'
  {...props}
 >
  <path fill='#000000' d='M5 7h13v10H2V4h7l2 2H4v9h1z'></path>
 </svg>
);

export const DashiconsPlus = (props: any) => (
 <svg
  width='1em'
  height='1em'
  viewBox='0 0 20 20'
  xmlns='http://www.w3.org/2000/svg'
  {...props}
 >
  <path fill='#000000' d='M17 7v3h-5v5H9v-5H4V7h5V2h3v5z'></path>
 </svg>
);

export const SolarSunsetLineDuotone = (props: any) => (
 <svg
  width='1em'
  height='1em'
  viewBox='0 0 24 24'
  xmlns='http://www.w3.org/2000/svg'
  {...props}
 >
  <g fill='none' stroke='#000000' strokeWidth='1.5'>
   <path strokeLinecap='round' strokeLinejoin='round' d='M8 22h8'></path>
   <path
    strokeLinecap='round'
    strokeLinejoin='round'
    d='M5 19h14'
    opacity='.5'
   ></path>
   <path strokeLinecap='round' strokeLinejoin='round' d='M2 16h20'></path>
   <path d='M12 6a6 6 0 0 0-4.5 9.969h9A6 6 0 0 0 12 6Z' opacity='.5'></path>
   <path
    strokeLinecap='round'
    strokeLinejoin='round'
    d='M12 6v7m0 0l2-2m-2 2l-2-2'
   ></path>
   <path
    strokeLinecap='round'
    d='M12 2v1m10 9h-1M3 12H2m17.07-7.07l-.392.393M5.322 5.322l-.393-.393'
    opacity='.5'
   ></path>
  </g>
 </svg>
);
export const MaterialSymbolsLightSolarPowerOutline = (props: any) => (
 <svg
  width='1em'
  height='1em'
  viewBox='0 0 24 24'
  xmlns='http://www.w3.org/2000/svg'
  {...props}
 >
  <path
   fill='#000000'
   d='m3.212 20.962l1.596-7.981h14.365l1.596 7.98zM3.692 4V3h2.712v1zm.758 15.962h7.05V17.5H4.946zM6.01 9.229l-.714-.689L7.21 6.627l.713.688zM5.154 16.5H11.5v-2.52H5.65zM12 7.02q-1.69 0-2.864-1.175q-1.174-1.174-1.174-2.864h1q0 1.266.886 2.152q.887.886 2.154.886t2.152-.886q.884-.886.884-2.152h1q0 1.69-1.174 2.864T12 7.02m-.5 4.173V8.481h1v2.711zm1 8.77h7.03l-.495-2.462H12.5zm0-3.462h6.327l-.496-2.52H12.5zm5.529-7.252l-1.927-1.933l.688-.688l1.952 1.908zM17.596 4V3h2.712v1z'
  ></path>
 </svg>
);
export const StreamlineShoppingBuildingRealHomeTowerBuildingHouseEstate = (
 props: any
) => (
 <svg
  width='1em'
  height='1em'
  viewBox='0 0 14 14'
  xmlns='http://www.w3.org/2000/svg'
  {...props}
 >
  <path
   fill='none'
   stroke='#000000'
   strokeLinecap='round'
   strokeLinejoin='round'
   d='M8.5 13.5h-8V4l4-3.5l4 3.5v9.5zm0 0h5v-7h-5m-4 7v-2M3 8.5h3m-3-3h3'
  ></path>
 </svg>
);

export const SubwayPower = (props: any) => (
 <svg
  width='1em'
  height='1em'
  viewBox='0 0 512 512'
  xmlns='http://www.w3.org/2000/svg'
  {...props}
 >
  <path
   fill='#000000'
   d='M439.6 0H204.9L55.4 256h149.5l-128 256l341.3-320H247.5z'
  ></path>
 </svg>
);
export const MdiSolarPanelLarge = (props: any) => (
 <svg
  width='1em'
  height='1em'
  viewBox='0 0 24 24'
  xmlns='http://www.w3.org/2000/svg'
  {...props}
 >
  <path
   fill='#000000'
   d='M3 6h3V3H3zm5-3v3h3V3zm5 0v3h3V3zm5 0v3h3V3zm3 5h-3v3h3zm0 5h-3v3h3zm-5 3v-3h-3v3zm-5 0v-3H8v3zm-5 0v-3H3v3zm-3-5h3V8H3zm10-3v3h3V8zM8 8v3h3V8zM3 1h18a2 2 0 0 1 2 2v13a2 2 0 0 1-2 2h-6v3h3v2h-5v-5h-2v5H6v-2h3v-3H3a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2'
  ></path>
 </svg>
);
