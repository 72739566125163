export const ButtonList = [
 ["undo", "redo"],
 ["removeFormat", "blockquote"],
 ["bold", "underline", "italic", "fontSize"],
 ["fontColor", "hiliteColor"],
 ["align", "horizontalRule", "list"],
 ["table", "link", "image"],
 ["showBlocks", "codeView", "preview"],
 //  [
 //   {
 //    name: "merge_tag",
 //    dataCommand: "merge_tag",
 //    buttonClass: "",
 //    title: "Mẫu",
 //    dataDisplay: "submenu",
 //    innerHTML: "<span>Mẫu</span>",
 //   },
 //  ],
];
