import { deleteQueryHelper, patchQueryHelper } from "helpers";
import { TParametersCarousel } from "../Carousel";

export const remove = (id: string) => {
 return deleteQueryHelper(`/carousel/${id}`);
};
export const update = (
 id: string | undefined,
 parameters: TParametersCarousel
) => {
 return patchQueryHelper(`/carousel/${id}`, parameters);
};
