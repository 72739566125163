import React from "react";
import { FieldTimeOutlined } from "@ant-design/icons";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";

// import required modules
import { EffectFade, Navigation, Pagination, Autoplay } from "swiper/modules";

import { ProjectStyled } from "assets/styles/V2/_Project.styled";
import { Image } from "antd";
import Container from "common/Container";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { TProject } from "@types";
import { API_SERVER } from "helpers/variable";
const ProjectComponent = () => {
 const { project }: any = useSelector((state: RootState) => state.project);
 const swiperRef = React.useRef<any>(null);

 const CustomNavigation = () => (
  <div className='navigation-custom'>
   <button
    onClick={() => {
     swiperRef.current.slidePrev();
    }}
   >
    <i className='fa-solid fa-angle-left'></i>
   </button>
   <button
    onClick={() => {
     swiperRef.current.slideNext();
    }}
   >
    <i className='fa-solid fa-angle-right'></i>
   </button>
  </div>
 );
 return (
  <ProjectStyled backgroundImage='https://datsolar.com/wp-content/uploads/2020/06/2-layersfse.jpg'>
   <div className='project__heading'>
    <h2>DỰ ÁN ĐÃ THỰC HIỆN</h2>
    {/* <p>
     Lorem, ipsum dolor sit amet consectetur adipisicing elit. Ipsam illo cumque
     aut.
    </p> */}
   </div>
   <Container>
    <Swiper
     modules={[Pagination, Navigation, Autoplay]}
     pagination={{
      clickable: true,
     }}
     autoplay={{
      delay: 5000,
      disableOnInteraction: false,
     }}
     loop={true}
     spaceBetween={50}
     slidesPerView={1}
     onSwiper={(swiper) => {
      swiperRef.current = swiper;
     }}
     className='project-carousel'
    >
     {/* <CustomNavigation /> */}
     {project?.map((item: TProject, index: number) => (
      <SwiperSlide key={item.projectId} className='project__item'>
       <Image preview={false} src={`${API_SERVER}/${item.projectImage}`} />
      </SwiperSlide>
     ))}
    </Swiper>
   </Container>
  </ProjectStyled>
 );
};

export default ProjectComponent;
