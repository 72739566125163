import { MaxScreenDevice } from "assets/screens/DeviceScreens";
import styled from "styled-components";
export const ContactFormStyled = styled.div`
 .contact-form {
  margin: 50px 0 0;
  &__heading {
   h2 {
    color: ${({ theme }) => theme.colors.black};
    font-size: 30px;
    font-weight: 600;
    text-align: center;
    text-transform: uppercase;
    padding-bottom: 10px;
    position: relative;
    &::after {
     content: "";
     width: 70px;
     height: 2px;
     background-color: ${({ theme }) => theme.colors.black};
     position: absolute;
     left: 50%;
     bottom: 0;
     transform: translateX(-50%);
    }
   }
  }
  &__form {
   margin: 50px 0 100px;
   .info {
    .form-input {
     height: 50px;
     width: 100%;
    }
   }
   .form-input {
    background-color: ${({ theme }) => theme.colors.gray[100]};
    border: none;
    outline: none;
    padding: 0 20px;
    border-radius: 8px;
    transition: all 0.4s ease;
    border: 1px solid ${({ theme }) => theme.colors.gray[200]};
    &::placeholder {
     font-size: 15px;
     color: ${({ theme }) => theme.colors.gray[500]};
    }
    &:focus {
     border-color: ${({ theme }) => theme.colors.main_green};
    }
   }
   textarea {
    margin-top: 20px;
    padding: 18px !important;
    width: calc(100% - 40px);
   }
  }
  &__submit {
   float: right;
   button {
    margin-top: 20px;
    padding: 8px 30px;
    border-radius: 8px;
    background-color: ${({ theme }) => theme.colors.main_green};
    color: ${({ theme }) => theme.colors.white};
    font-size: 15px;
    cursor: pointer;
    border: none;
    transition: all 0.5s ease;
    &:hover {
     background-color: ${({ theme }) => theme.colors.main_yellow};
     color: ${({ theme }) => theme.colors.black};
     transform: translateY(5px);
    }
   }
  }
 }
 @media only screen and (${MaxScreenDevice.custom(800)}) {
  .contact-form {
   margin: 50px 0 0;
   &__heading {
    h2 {
     font-size: 18px;
    }
    .desc {
     p {
      font-size: 14px;
      max-width: 100%;
     }
    }
   }
   &__form {
    margin: 50px 0 100px;
    .info {
     flex-direction: column;
     .ant-col-8 {
      max-width: 100%;
     }
     .form-input {
     }
    }
   }
   &__submit {
    width: 100%;
    button {
     width: 100%;
     padding: 15px 0;
    }
   }
  }
 }
`;
