import { MaxScreenDevice } from "assets/screens/DeviceScreens";
import styled from "styled-components";

export const StyleBreadcrumb = styled.div`
 width: 100%;
 padding: 30px 0 0;
 /* height: 286px; */
 background-color: ${({ theme }) => theme.colors.white};
 display: flex;
 align-items: center;
 h1 {
  margin: 0;
  padding: 0;
 }
 .breadcrumb {
  ul {
   display: flex;
   align-items: center;
   gap: 7px;
   list-style-type: none;
   list-style: none !important;
   li {
    list-style: none !important;
    list-style-type: none;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    margin: 0;
    padding: 0;
    .link {
     display: flex;
     align-items: center;
     gap: 5px;
     a {
      color: ${({ theme }) => theme.colors.black};
      text-decoration: none;
      transition: all 0.3s ease;
      &:hover {
       color: ${({ theme }) => theme.colors.main_green};
      }
     }
     i {
      font-size: 11px;
     }
    }
    &.active {
     color: ${({ theme }) => theme.colors.main_green};
    }
   }
  }
 }

 @media only screen and (${MaxScreenDevice.custom(800)}) {
  display: none;
 }
`;
