import { MaxScreenDevice } from "assets/screens/DeviceScreens";
import styled from "styled-components";
export const InfoDetailProductStyled = styled.div`
 position: relative;
 width: 50%;
 height: 80%;
 padding: 11px 24px 0;
 .detail-product {
  &__info {
   display: flex;
   align-items: start;
   justify-content: start;
   flex-direction: column;
   gap: 15px;
   &-category {
    margin-top: 20px;
   }
   &-title {
    h1 {
     font-size: 20px;
     font-weight: 500;
     line-height: 30px;
     letter-spacing: 0.2px;
     color: ${({ theme }) => theme.colors.black};
     margin-bottom: 0;
    }
   }
   &-code {
    font-size: 15px;
    font-weight: 400;
    display: flex;
    align-items: center;
    gap: 10px;
   }
   &-trademark-type {
    p {
     font-size: 15px;
     font-weight: 400;
     display: flex;
     align-items: center;
     gap: 15px;
    }
   }
   &-tag {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 5px;
    p {
     font-size: 14px;
     font-weight: 700;
     line-height: 24px;
     letter-spacing: 0.2px;
     margin-bottom: 0;
    }
    .title {
     p {
      color: ${({ theme }) => theme.colors.black};
     }
    }
    .list {
     display: flex;
     align-items: center;
     justify-content: flex-start;
     flex-wrap: wrap;
     p {
      color: ${({ theme }) => theme.colors.black};
     }
    }
   }
  }
  &__bottom {
   margin-top: 30px;
   display: flex;
   align-items: center;
   justify-content: flex-start;
   gap: 10px;
   .caculator {
    background-color: ${({ theme }) => theme.colors.main_yellow};
    border: 1px solid ${({ theme }) => theme.colors.main_yellow};
   }
   button {
    color: ${({ theme }) => theme.colors.white};
    border-radius: 5px;
    background-color: ${({ theme }) => theme.colors.main_green};
    padding: 10px 50px;
    font-size: 14px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.2px;
    border: 1px solid ${({ theme }) => theme.colors.main_green};
    transition: all 0.4s ease;
    cursor: pointer;
    i {
     margin-right: 10px;
    }
    &:hover {
     background-color: transparent;
     color: ${({ theme }) => theme.colors.main_green};
    }
   }
   .icon__wishlist {
    padding: 10px;
    cursor: pointer;
    transition: all 0.4s ease;
    border: 1px solid transparent;
    border-radius: 5px;
    &:hover {
     border-color: ${({ theme }) => theme.colors.black};
     color: ${({ theme }) => theme.colors.black};
    }
   }
  }
 }
 @media only screen and (${MaxScreenDevice.custom(800)}) {
  width: 100%;
  padding: 20px 0;
  .detail-product {
   &__info {
    margin-top: 50px;
   }
  }
  .image__list {
   height: 100%;
  }
  .bottom {
   position: unset;
   margin-top: 20px;
   flex-direction: column;
  }
 }
`;
