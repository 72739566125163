import { getQueryHelper } from "helpers";
const getAllSolution = async () => {
 const response = await getQueryHelper("/solution/get-solution/active");
 if (response) {
 }
 return response;
};

export const solutionService = {
 getAllSolution,
};
