import React from "react";
import { TSolutionAdmin } from "./Component";
import HeaderSolution from "./HeaderSolution";

type TProps = {
 dataSolution: TSolutionAdmin;
};

const ViewSolutionContent = ({ dataSolution }: TProps) => {
 return (
  <>
   <div
    style={{
     minHeight: "80vh",
     height: "80vh",
     overflowY: "auto",
    }}
   >
    <div className='header'>
     <HeaderSolution dataSolution={dataSolution} />
    </div>
    <div
     className='sun-editor-editable'
     dangerouslySetInnerHTML={{ __html: dataSolution.solutionHtmlFile }}
    />
   </div>
  </>
 );
};

export default ViewSolutionContent;
