import React from "react";
import { Button, Flex, Modal, Typography } from "antd";
import TableCarousel from "./carouselComponent/Table.carousel";
import UploadComponent from "common/Upload";
import { QUERY_CAROUSEL_ADMIN, getQueryHelper, postQueryHelper } from "helpers";
import type { UploadFile } from "antd/es/upload/interface";
import { useQuery, useQueryClient } from "react-query";

const { Title } = Typography;

export type TParametersCarousel = {
 imageUrl?: string;
 active?: boolean;
 order?: number;
};

const fetcherGetCarousel = () => () => {
 return getQueryHelper("/carousel");
};

const fetchPostCarousel = (parameters: TParametersCarousel) => {
 return postQueryHelper("/carousel", parameters);
};
const Carousel = () => {
 const [visibleModal, setVisibleModal] = React.useState<boolean>(false);
 const [imageList, setImageList] = React.useState<UploadFile[]>([]);
 const queryClient = useQueryClient();
 const { data, isLoading }: any = useQuery(
  QUERY_CAROUSEL_ADMIN,
  fetcherGetCarousel()
 );

 const showModal = () => {
  setVisibleModal(true);
 };

 const handleOk = () => {
  setVisibleModal(false);
  imageList.map((image: { name: string }, index: number) =>
   fetchPostCarousel({
    imageUrl: image.name,
    active: false,
    order: index + 1,
   })
  );
  queryClient.invalidateQueries(QUERY_CAROUSEL_ADMIN);
  setImageList([]);
 };

 const handleCancel = () => {
  setVisibleModal(false);
 };
 return (
  <>
   <Flex
    style={{
     margin: "20px 0",
    }}
    align='center'
    justify='space-between'
   >
    <Title
     level={4}
     style={{
      textTransform: "uppercase",
     }}
    >
     Danh sách hình ảnh
    </Title>
    <Flex gap='small' wrap='wrap'>
     <Button
      onClick={showModal}
      type='primary'
      icon={<i className='fa-solid fa-plus'></i>}
     >
      Tải hình ảnh lên
     </Button>
    </Flex>
   </Flex>
   <TableCarousel isLoading={isLoading} dataTable={data} />
   <Modal
    title='Tải hình ảnh'
    open={visibleModal}
    onOk={handleOk}
    onCancel={handleCancel}
    centered
    className='hide-scroll'
    styles={{
     body: { marginTop: 50, maxHeight: 600, minHeight: 100, overflowY: "auto" },
    }}
   >
    <UploadComponent resultUploadImage={setImageList} imageList={imageList} />
   </Modal>
  </>
 );
};

export default Carousel;
