import { MaxScreenDevice } from "assets/screens/DeviceScreens";
import styled from "styled-components";
type TProps = {
 backgroundImage: string;
};
export const NewsHomeV2Styled = styled.div<TProps>`
 width: 100%;
 height: 100%;
 background-image: url(${({ backgroundImage }) => backgroundImage});
 background-size: cover;
 background-repeat: no-repeat, repeat;
 padding: 100px 0;
 .news {
  display: flex;
  align-items: start;
  margin-top: 50px;

  &__left {
   width: 70%;
   height: 100%;
   &-heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: ${({ theme }) => theme.colors.white};
    .title {
     h3 {
      font-size: 35px;
      font-weight: 600;
      text-transform: uppercase;
     }
    }
    .button-see-more {
     background-color: transparent;
     border: 1px solid ${({ theme }) => theme.colors.white};
     border-radius: 20px;
     padding: 10px 30px;
     cursor: pointer;
     transition: all 0.4s ease;
     button {
      background-color: transparent;
      border: none;
      color: ${({ theme }) => theme.colors.white};
      font-size: 18px;
      font-weight: 400;
     }
     &:hover {
      background-color: ${({ theme }) => theme.colors.white};
      button {
       background-color: transparent;
       color: ${({ theme }) => theme.colors.black};
      }
     }
    }
   }
   &_top-5 {
    width: calc(100% - 40px);
    height: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 150px 150px 30px 150px 150px;
    gap: 10px;
    .item-mobile {
     display: none !important;
    }
    .item {
     border: 1px solid ${({ theme }) => theme.colors.gray[300]};
     border-radius: 2px;
     background-color: ${({ theme }) => theme.colors.white};
     img {
      width: 100%;
      height: 100%;
     }
     &:first-child {
      width: 100%;
      grid-column: 1/5;
      grid-row: 1/3;
      display: flex;
      align-items: center;
      justify-content: start;
      .item__info {
       width: calc(50% - 40px);
       display: flex;
       flex-direction: column;
       padding: 0 20px;
       .new {
        &-date {
         p {
          color: ${({ theme }) => theme.colors.gray[500]};
          font-size: 15px;
          font-weight: 600;
         }
        }
        &-title {
         h3 {
          font-size: 22px;
          font-weight: 400;
          color: ${({ theme }) => theme.colors.black};
         }
        }
        &-summary {
         p {
          font-size: 16px;
          font-weight: 400;
          color: ${({ theme }) => theme.colors.black};
         }
        }
        &-button {
         margin-top: 20px;
         button {
          background-color: transparent;
          border: 1px solid ${({ theme }) => theme.colors.main_yellow};
          color: ${({ theme }) => theme.colors.black};
          background-color: ${({ theme }) => theme.colors.main_yellow};
          padding: 10px 30px;
          border-radius: 20px;
          font-size: 16px;
          text-transform: uppercase;
          cursor: pointer;
          transition: all 0.4s ease;
          &:hover {
           background-color: transparent;
          }
         }
        }
       }
      }
      .item__image {
       width: 50%;
       height: 100%;
      }
     }
     &:nth-child(3),
     &:nth-child(4) {
      grid-column: 1/3;
      grid-row: 4/5;
     }
     &:nth-child(4) {
      grid-column: 3/5;
     }
     &:nth-child(5),
     &:nth-child(6) {
      grid-column: 1/3;
      grid-row: 5/6;
     }
     &:nth-child(6) {
      grid-column: 3/5;
     }
     .new__top4 {
      display: flex;
      align-items: center;
      gap: 10px;
      width: 100%;
      height: 100%;
      .image {
       width: 50%;
       height: 100%;
      }
      .info {
       display: flex;
       align-items: start;
       justify-content: center;
       flex-direction: column;
       gap: 10px;
       width: 50%;
       .date {
        p {
         color: ${({ theme }) => theme.colors.gray[500]};
         font-size: 13px;
         font-weight: 600;
        }
       }
       h4 {
        -webkit-line-clamp: 3;
        font-size: 15px;
        font-weight: 500;
       }
      }
     }
     /* &:nth-child(4) {
      grid-column: 1/3;
      grid-row: 5/6;
     } */
    }
    h3,
    h4,
    p {
     display: -webkit-box;
     -webkit-line-clamp: 2;
     -webkit-box-orient: vertical;
     overflow: hidden;
     text-overflow: ellipsis;
    }
    p {
     -webkit-line-clamp: 3;
    }
   }
  }
  &__right {
   width: 30%;
   height: 100%;
   display: flex;
   flex-direction: column;
   gap: 23px;
   &-item {
    width: 100%;
    height: 130px;
    display: flex;
    align-items: center;
    gap: 15px;
    background-color: ${({ theme }) => theme.colors.white};
    border: 1px solid ${({ theme }) => theme.colors.gray[400]};
    .image {
     width: 50%;
     height: 100%;
    }
    .info {
     width: 50%;
     height: 100%;
     display: flex;
     align-items: start;
     justify-content: center;
     flex-direction: column;
     gap: 5px;
     .date {
      p {
       color: ${({ theme }) => theme.colors.gray[500]};
       font-size: 13px;
       font-weight: 600;
      }
     }
     .title {
      h5 {
       display: -webkit-box;
       -webkit-line-clamp: 2;
       -webkit-box-orient: vertical;
       overflow: hidden;
       text-overflow: ellipsis;
      }
     }
    }
   }
  }
 }
 .item__hover {
  transition: all 0.4s ease;
  cursor: pointer;
  &:hover {
   box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
   transform: translateY(-2px);
  }
 }

 @media only screen and (${MaxScreenDevice.tablet}) {
  padding: 50px 0;

  .news {
   &__left {
    width: 100%;
    &-heading {
     .title {
      h3 {
       font-size: 20px;
      }
     }
     .button-see-more {
      padding: 5px 30px;
      button {
       font-size: 16px;
      }
     }
    }
    &_top-5 {
     width: calc(100% - 40px);
     display: flex;
     flex-direction: column;
     gap: 20px;
     grid-template-columns: 1fr;
     padding: 0 20px;
     .item-mobile {
      display: block !important;
     }
     .item-desktop {
      display: none !important;
     }
     h3 {
      font-size: 20px !important;
     }
     p {
      font-size: 13px !important;
     }
    }
   }
   &__right {
    display: none;
   }
  }
 }
`;
