import { ColumnsType } from "antd/es/table";
import { TSolutionPackageAdmin } from "./type";
import { Tag, Typography } from "antd";
import { formatter } from "components/admin/common/formatPrice";
import Action from "./Action";
import moment from "moment";

const { Text } = Typography;
export const columns: ColumnsType<TSolutionPackageAdmin> = [
 {
  title: "Công suất",
  dataIndex: "performance",
  key: "performance",
  render: (performance: string) => (
   <Text>
    {performance} <Text strong>kWp</Text>
   </Text>
  ),
 },
 {
  title: "Số tấm pin",
  dataIndex: "numberOfPanels",
  key: "numberOfPanels",
  render: (numberOfPanels: string) => <Text>{numberOfPanels}</Text>,
 },
 {
  title: "Điện tạo ra",
  dataIndex: "electricityGenerated",
  key: "electricityGenerated",
  render: (electricityGenerated: string) => (
   <Text>
    {electricityGenerated} <Text strong>kWp</Text>
   </Text>
  ),
 },
 {
  title: "Số tiền tiết kiệm",
  dataIndex: "savingMoney",
  key: "savingMoney",
  render: (savingMoney: string) => (
   <Text>{formatter.format(Number(savingMoney))}</Text>
  ),
 },
 {
  title: "Trạng thái",
  dataIndex: "active",
  key: "active",
  render(active) {
   let text: string, color: string;
   if (active === 1) {
    text = "Công khai";
    color = "#87d068";
   } else {
    text = "Chưa công khai";
    color = "#f50";
   }
   return <Tag color={color}>{text}</Tag>;
  },
 },
 {
  title: "Ngày tạo",
  dataIndex: "created_at",
  key: "created_at",
  render: (created_at: string) => (
   <Text>{moment(created_at).format("DD/MM/YYYY HH:mm:ss")}</Text>
  ),
 },
 {
  title: "Ngày cập nhật",
  dataIndex: "updated_at",
  key: "updated_at",
  render: (updated_at: string) => (
   <Text>{moment(updated_at).format("DD/MM/YYYY HH:mm:ss")}</Text>
  ),
 },
 {
  title: "",
  dataIndex: "action",
  key: "id",
  render: (_, record: TSolutionPackageAdmin) => <Action record={record} />,
 },
];
