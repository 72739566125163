import Container from "common/Container";
import React from "react";
import NewPostComponent from "../NewPost.component";
import { SolutionStyled } from "assets/styles/page/solution/Solution.styled";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { TSolution } from "@types";
import _ from "lodash";
import { useParams } from "react-router-dom";
import NotFoundCommon from "common/NotFound";
import ContactComponent from "../contact/Component";
import { ServiceOfDTSolar } from "../SeriveOfDTSolar";
import AboutUsComponent from "components/Home/HomeComponent/AboutUs.component";

const Components = () => {
 const [html, setHtml] = React.useState<string>("");
 const { slug } = useParams();
 const { solution, isLoading } = useSelector(
  (state: RootState) => state.solution
 );
 const [notFount, setNotFount] = React.useState<boolean>(false);

 const getDataSolutionContent = (solution: TSolution[]) => {
  const result = _.find(solution, { solutionSlug: slug });
  if (result) {
   setHtml(result.solutionHtmlFile);
  } else {
   setNotFount(true);
  }
  // setHtml(result);
 };

 const scrollToTop = () => {
  window.scrollTo({
   top: 0,
   left: 0,
   behavior: "smooth",
  });
 };

 React.useEffect(() => {
  scrollToTop();
 }, [slug]);

 React.useEffect(() => {
  if (!isLoading && solution) {
   getDataSolutionContent(solution);
  }
 }, [isLoading, solution, slug]);

 return (
  <SolutionStyled>
   <Container>
    {notFount ? (
     <NotFoundCommon />
    ) : (
     <>
      <div className='solution'>
       <div
        className='sun-editor-editable solution__content'
        dangerouslySetInnerHTML={{ __html: html }}
       />
       <div className='solution__list-news'>
        <NewPostComponent />
       </div>
      </div>
      <AboutUsComponent href={"/gioi-thieu"} buttonText='XEM CHI TIẾT' />
      <ContactComponent />
     </>
    )}
   </Container>
  </SolutionStyled>
 );
};

export default Components;
