import { StyledHeader } from "assets/styles/V2/_Header.styled";
import { API_SERVER } from "helpers/variable";
import React from "react";
import HeaderTop from "./HeaderTop";
import { THeaderData, THeaderV2, TSolution } from "@types";
import { HeaderDataV2 } from "data/v2";
import { FormatDataHeader } from "./formatData";
import Container from "common/Container";
import HeaderMenu from "./HeaderMenu";
import { MenuOutlined } from "@ant-design/icons";
import DrawerMobile from "common/DrawerMobile";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "store";
import MenuHeader, { TMenuHeader } from "assets/json/MenuHeader";

type THeaderDataState = {
 id: string | number;
 idParent: string;
 title: string;
 href: string;
 subMenu?: THeaderV2[];
};

const Component = () => {
 const [visibleToggle, setVisibleToggle] = React.useState<boolean>(false);
 const [headerData, setHeaderData] = React.useState<THeaderDataState[]>([]);
 const { solution, isLoading } = useSelector(
  (state: RootState) => state.solution
 );
 const navigate = useNavigate();
 const dataHeader: TMenuHeader = MenuHeader;
 const handleSwitchHome = () => {
  navigate("/");
 };

 const formatHeaderData = (solution: TSolution[]) => {
  const result = solution.map((item: TSolution) => ({
   id: item.solutionId,
   idParent: "deb4920a-5467-444d-8a78-b8f734004836",
   title: item.solutionName,
   href: `${item.solutionSlug}`,
  }));
  setHeaderData(result);
 };

 React.useEffect(() => {
  if (!isLoading && solution) {
   formatHeaderData(solution);
  }
 }, [solution, isLoading]);

 return (
  <StyledHeader>
   <div className='header'>
    <div className='header__logo'>
     <img
      style={{
       cursor: "pointer",
      }}
      src={`/images/logo-dt.jpg`}
      alt='DT-SOLAR'
      onClick={handleSwitchHome}
     />
    </div>
    <div className='header__navbar'>
     <HeaderTop dataPage={FormatDataHeader(dataHeader.headerTop)} />
     <HeaderMenu
      dataPage={FormatDataHeader([...dataHeader.headerNavbar, ...headerData])}
     />
    </div>
    <div className='header__toggle'>
     <MenuOutlined onClick={() => setVisibleToggle(true)} />
    </div>
   </div>
   <DrawerMobile
    dataNavbar={FormatDataHeader([
     ...dataHeader.headerNavbar.concat(dataHeader.headerTop),
     ...headerData,
    ])}
    setVisible={setVisibleToggle}
    visible={visibleToggle}
   />
  </StyledHeader>
 );
};

export default Component;
