import { MaxScreenDevice } from "assets/screens/DeviceScreens";
import styled from "styled-components";
export const SolutionStyled = styled.div`
 .solution {
  margin-top: 200px;
  display: flex;
  align-items: start;
  gap: 20;
  width: 100%;
  &__content {
   width: 65%;
  }
  &__list-news {
   width: calc(35% - 20px);
  }
 }
 @media only screen and (${MaxScreenDevice.tablet}) {
  .solution {
   margin-top: 100px;
   width: calc(100% - 40px);
   padding: 0 20px 50px;
   flex-direction: column;
   .sun-editor-editable {
    padding: 0;
   }
   &__content {
    width: 100%;
   }
   &__list-news {
    width: 100%;
   }
  }
 }
`;
