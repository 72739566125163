import { THeaderV2 } from "@types";
import React from "react";
import Menu from "./Menu";
import { theme } from "theme";

type TProps = {
 dataPage: THeaderV2[];
};

const HeaderMenu = ({ dataPage }: TProps) => {
 return (
  <Menu
   styleNavbar={{
    active: true,
    fontStyle: {
     fontSize: "15px",
     fontWeight: 500,
     textTransform: "uppercase",
    },
    color: theme.colors.white,
    backgroundColor: theme.colors.main_green,
    colorHover: theme.colors.main_yellow,
    paddingRight: false,
   }}
   dataNavbar={dataPage}
  />
 );
};

export default HeaderMenu;
