import React from "react";
import { ContactHomeStyled } from "assets/styles/HomeStyled/_Contact.styled";
import Container from "common/Container";
import { ContactData } from "data";
import parse from "html-react-parser";
import ContactInfo from "common/ContactInfo";
import Social from "common/Social";
import Map from "common/Map";

export interface TContactInformation {
 label: string | null;
 content: string;
 href: string;
 icon: string | null;
}
export interface TSocial {
 icon: string;
 href: string;
 label?: string;
 backgroundColor?: string;
}

export interface TContact {
 image: string;
 contactInformation: TContactInformation[];
 social: TSocial[];
}

const ContactComponent = () => {
 const { image, contactInformation, social }: TContact = ContactData;

 const renderContactInformation = (
  information: TContactInformation,
  key: number
 ) => (
  <div className='info__contact' key={key}>
   <h4>{information.label}</h4>
   <a href={information.href}>{information.content}</a>
  </div>
 );

 const renderSocial = (social: TSocial, key: number) => (
  <a href={social.href} key={key}>
   {parse(social.icon)}
  </a>
 );

 return (
  <ContactHomeStyled>
   <Container>
    <div className='contact'>
     <div className='contact__heading'>
      <h1>LIÊN HỆ VỚI CHÚNG TÔI</h1>
      <div className='desc'></div>
     </div>
     <div className='contact__information'>
      <div className='contact__information-info'>
       <div className='title'>
        <h3>Thông tin liên hệ</h3>
       </div>
       <div className='desc'>
        <p>
         Để biết thêm thông tin hoặc yêu cầu về sản phẩm, dự án và giá cả của
         chúng tôi, vui lòng liên hệ với chúng tôi.
        </p>
       </div>
       <ContactInfo />
       {/* <Social /> */}
      </div>
      <Map />
     </div>
    </div>
   </Container>
  </ContactHomeStyled>
 );
};

export default ContactComponent;
