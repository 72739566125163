import Table from "antd/es/table";
import React from "react";
import { Button, Flex, message } from "antd";
import _ from "lodash";
import { useQueryClient } from "react-query";
import { QUERY_CAROUSEL_ADMIN, QUERY_PROJECT_ADMIN } from "helpers";
import { DataTypesTable, columns } from "./columns";
import { updateProjectData } from "./fetch";

export interface TDataProjectAdmin {
 projectId: string;
 projectImage: string;
 projectActive: number;
}
type TProps = {
 isLoading: boolean;
 dataTable: TDataProjectAdmin[];
};

const TableProject = ({ isLoading, dataTable }: TProps) => {
 const queryClient = useQueryClient();
 const [selectedRowKeys, setSelectedRowKeys] = React.useState<string[]>([]);
 const [loading, setLoading] = React.useState<boolean>(false);

 const tableData: DataTypesTable[] = dataTable?.map(
  (data: TDataProjectAdmin) => ({
   ...data,
   key: data.projectId,
  })
 );

 const handlePublishCarousel = () => {
  setLoading(true);
  let response: any;
  selectedRowKeys.map(async (id: string) => {
   response = await updateProjectData({ active: 1 }, id);
  });
  if (response?.type === "error") {
   return message.error(response.error);
  }
  queryClient.invalidateQueries(QUERY_PROJECT_ADMIN);
  message.success("Cập nhật dự liệu thành công.");
  setLoading(false);
  setSelectedRowKeys([]);
 };

 const onSelectChange = (newSelectedRowKeys: any[]) => {
  setSelectedRowKeys(newSelectedRowKeys);
 };

 const rowSelection = {
  selectedRowKeys,
  onChange: onSelectChange,
 };

 const hasSelected = selectedRowKeys.length > 0;
 return (
  <>
   {hasSelected && (
    <Flex gap={20} style={{ marginBottom: 20 }}>
     <Button type='primary' onClick={handlePublishCarousel} loading={loading}>
      Đăng
     </Button>
    </Flex>
   )}
   <Table
    rowSelection={rowSelection}
    loading={isLoading}
    columns={columns}
    dataSource={tableData}
   />
  </>
 );
};

export default TableProject;
