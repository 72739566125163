import { TNews } from "@types";
import { NewPostStyled } from "assets/styles/page/_NewPost.service.styled";
import { API_SERVER } from "helpers/variable";
import React from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { RootState } from "store";

const NewPostComponent = () => {
 const { news } = useSelector((state: RootState) => state.news);
 return (
  <NewPostStyled>
   <div className='title'>
    <i className='fa-solid fa-broom'></i>
    <h2>Bài viết mới</h2>
   </div>
   <div className='content'>
    <div className='post-list'>
     {news?.slice(0, 10).map((newItem: TNews) => (
      <NavLink
       to={`/tin-tuc/${newItem.href}`}
       className='post-list__item'
       key={newItem.id}
      >
       <div className='image'>
        <img
         src={`${API_SERVER}/${newItem.imageThumbnail}`}
         alt={`${newItem.href}`}
        />
       </div>
       <div className='content-new'>
        <div className='date'>
         <p>{newItem.date}</p>
        </div>
        <div className='label'>
         <h4>{newItem.title}</h4>
        </div>
       </div>
      </NavLink>
     ))}
    </div>
   </div>
  </NewPostStyled>
 );
};

export default NewPostComponent;
