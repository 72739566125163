import { MaxScreenDevice } from "assets/screens/DeviceScreens";
import styled from "styled-components";

export const FooterStyled = styled.footer`
 width: 100%;
 height: 100%;
 background-color: ${({ theme }) => theme.colors.main_green};
 .footer {
  padding: 50px 0;
  display: grid;
  grid-template-columns: 3fr 4fr;
  gap: 50px;
  &__left {
   .information {
    img {
     width: 80px;
     height: 80px;
     object-fit: contain;
     margin-bottom: 20px;
    }
    p {
     font-size: 14px;
     line-height: 160%;
     font-weight: 500;
     text-align: justify;
     color: ${({ theme }) => theme.colors.main_yellow};
    }
   }
   .social {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 20px;
    a {
     width: 35px;
     height: 35px;
     display: flex;
     align-items: center;
     justify-content: center;
     border-radius: 50%;
     color: ${({ theme }) => theme.colors.main_yellow};
     text-decoration: none;
     transform: scale(1);
     transition: transform 0.4s ease;
     &:hover {
      transform: scale(1.2);
     }
    }
   }
  }
  &__center {
   margin-top: 50px;
   .title {
    h3 {
     color: ${({ theme }) => theme.colors.main_yellow};
     font-size: 20px;
     font-weight: 500;
     text-transform: uppercase;
    }
   }
   .content {
    .item {
     display: flex;
     align-items: center;
     gap: 5px;
     color: ${({ theme }) => theme.colors.main_yellow};
     text-decoration: none;
     margin: 10px 0;
     font-size: 14px;
     &__icon {
      width: 20px;
     }
     transform: all 0.2s ease;
     &:hover {
      color: ${({ theme }) => theme.colors.gray[200]};
     }
    }
   }
  }
  &__right {
   .product-solution {
    display: flex;
    align-items: start;
    .footer__right-item {
     width: 50%;
     .service {
      &__title {
       h3 {
        color: ${({ theme }) => theme.colors.main_yellow};
        font-size: 20px;
        font-weight: 500;
        text-transform: uppercase;
       }
      }
      &__list {
       padding: 0 0 0 40px;
       li {
        margin: 10px 0;
        list-style: unset;
        color: ${({ theme }) => theme.colors.main_yellow};
        a {
         text-decoration: none;
         color: ${({ theme }) => theme.colors.main_yellow};
         transition: all 0.4s ease;
         &:hover {
          color: ${({ theme }) => theme.colors.main_green};
         }
        }
       }
      }
     }
    }
   }
   .contact {
    margin-top: 30px;
    h3 {
     font-size: 20px;
     font-weight: 500;
     text-transform: uppercase;
     color: ${({ theme }) => theme.colors.main_yellow};
    }
    p {
     margin: 20px 0;
     font-size: 15px;
     color: ${({ theme }) => theme.colors.main_yellow};
     font-weight: 400;
    }
    .input {
     width: 300px;
     height: 40px;
     position: relative;
     input {
      width: 100%;
      height: 100%;
      border-radius: 20px;
      outline: none;
      border: 1px solid ${({ theme }) => theme.colors.gray[100]};
      background-color: ${({ theme }) => theme.colors.gray[100]};
      padding: 0 20px;
      font-size: 15px;
      font-weight: 500;
      &::placeholder {
       font-size: 15px;
       font-weight: 500;
      }
     }
     i {
      position: absolute;
      top: 50%;
      right: -20px;
      transform: translateY(-50%);
      cursor: pointer;
      transition: all 0.4s ease;
      &:hover {
       color: ${({ theme }) => theme.colors.main_green};
      }
     }
    }
   }
  }
 }
 .footer-bottom {
  width: 100%;
  height: 50px;
  background-color: ${({ theme }) => theme.colors.main_green};
  /* border-top: 1px solid ${({ theme }) => theme.colors.gray[600]}; */
  .copyright {
   width: 100%;
   height: 100%;
   display: flex;
   align-items: center;
   justify-content: space-between;
   p,
   a {
    color: ${({ theme }) => theme.colors.main_yellow};
    font-size: 15px;
    font-weight: 400;
   }
   &__right {
    a {
     text-decoration: none;
     transition: all 0.3s ease;
     &:hover {
      color: ${({ theme }) => theme.colors.gray[200]};
     }
    }
   }
  }
 }
 @media only screen and (${MaxScreenDevice.laptop}) {
  .footer {
   padding: 20px;
  }
  .footer-bottom {
   padding: 0 20px;
  }
 }
 @media only screen and (${MaxScreenDevice.tablet}) {
  .footer {
   padding: 20px;
   grid-template-columns: 1fr;
   gap: 30px;
   &__right {
    .service {
     &__list {
      padding: 0 0 0 20px;
     }
    }
   }
  }
  .footer-bottom {
   width: calc(100% - 40px);
   height: 100%;
   .copyright {
    flex-direction: column;
    justify-content: center;
    padding: 10px;
    align-self: start;
    p,
    a {
     font-size: 14px;
    }
   }
  }
 }
`;
