import { Flex, Form, FormInstance, Input, InputNumber } from "antd";
import React from "react";
import { TFromValues } from "./type";
import CurrencyInput from "react-currency-input-field";

type TTypeView = "update" | "add";
interface TProps {
 form: FormInstance;
 type: TTypeView;
 onSubmit: (values: TFromValues) => void;
 defaultSavingMoney?: string;
}

const FormContent = ({
 form,
 onSubmit,
 type,
 defaultSavingMoney = "",
}: TProps) => {
 const [valueCurrentPrice, setValueCurrentPrice] = React.useState<string>("");
 const onFinish = (values: TFromValues) => {
  const parameter: TFromValues = {
   ...values,
   savingMoney: valueCurrentPrice,
  };
  onSubmit(parameter);
 };
 return (
  <Form form={form} onFinish={onFinish} layout='vertical'>
   <Form.Item<TFromValues>
    label='Công suất'
    name='performance'
    rules={[
     { required: true },
     {
      type: "number",
      min: 1,
      message: "Min: 1;",
      transform(value) {
       return Number(value);
      },
     },
    ]}
   >
    <Input />
   </Form.Item>
   <Form.Item<TFromValues>
    label='Số tấm pin'
    name='numberOfPanels'
    rules={[
     { required: true },
     {
      type: "number",
      min: 1,
      message: "Min: 1;",
      transform(value) {
       return Number(value);
      },
     },
    ]}
   >
    <Input />
   </Form.Item>
   <Form.Item<TFromValues>
    label='Điện tạo ra'
    name='electricityGenerated'
    rules={[
     { required: true },
     {
      type: "number",
      min: 1,
      message: "Min: 1;",
      transform(value) {
       return Number(value);
      },
     },
    ]}
   >
    <Input />
   </Form.Item>
   <Form.Item<TFromValues>
    label='Số tiền tiết kiệm'
    name='savingMoney'
    rules={[{ required: true }]}
   >
    <Flex align='center' gap={5}>
     <CurrencyInput
      className='input-price'
      intlConfig={{ locale: "vn", currency: "VND" }}
      groupSeparator='.'
      defaultValue={defaultSavingMoney}
      onValueChange={(value: any) => setValueCurrentPrice(value)}
     />
    </Flex>
   </Form.Item>
  </Form>
 );
};

export default FormContent;
