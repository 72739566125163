import { MaxScreenDevice } from "assets/screens/DeviceScreens";
import styled from "styled-components";
export const RelatedProductsStyled = styled.div`
 margin-top: 100px;
 .related-products {
  &__heading {
   h2 {
    color: ${({ theme }) => theme.colors.main_green};
    font-size: 30px;
    font-weight: 600;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 50px;
   }
  }
  &__content-slide {
   margin: 100px 0 50px;
   transition: all 0.4s ease;
   cursor: pointer;
   &:hover {
    border-radius: 10px;
    box-shadow: 1px 2px 5px -2px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: 1px 2px 5px -2px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 1px 2px 5px -2px rgba(0, 0, 0, 0.75);
   }
  }
  &__navigation-custom {
   position: absolute;
   top: 0;
   left: 50%;
   transform: translateX(-50%);
   display: flex;
   align-items: center;
   gap: 10px;
   z-index: 2;
   button {
    background-color: ${({ theme }) => theme.colors.main_yellow};
    border: 1px solid ${({ theme }) => theme.colors.main_yellow};
    padding: 10px;
    border-radius: 3px;
    transition: all 0.4s ease;
    cursor: pointer;
    i {
     font-size: 15px;
    }
    &:hover {
     background-color: transparent;
    }
   }
  }
 }
 @media only screen and (${MaxScreenDevice.tablet}) {
  margin-top: 50px;
  .related-products {
   &__heading {
    h2 {
     font-size: 24px;
     margin-bottom: 10px;
    }
   }
   &__content-slide {
    margin-top: 50px;
   }
  }
 }
`;
