export const setJobStatus = (status: number) => {
 switch (status) {
  case 0:
   return {
    color: "#f50",
    label: "Chưa hoàn thành",
   };
  case 1:
   return {
    color: "#108ee9",
    label: "Hoàn thành",
   };
 }
};
