import { MaxScreenDevice } from "assets/screens/DeviceScreens";
import styled from "styled-components";
export const SearchProjectStyled = styled.div`
 .form {
  width: 70% !important;
 }
 .search-input {
  height: 40px;
  width: 100%;
  border-radius: 20px;
  .ant-select-selector {
   border-radius: 20px;
  }
  &::placeholder,
  .ant-select-selection-placeholder {
   font-size: 15px;
   font-weight: 600;
  }
 }
 .submit {
  background-color: ${({ theme }) => theme.colors.main_green};
  border: none;
  height: 40px;
  width: 100%;
  font-size: 15px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.white};
  border-radius: 20px;
  cursor: pointer;
 }
 @media only screen and (${MaxScreenDevice.tablet}) {
  .form-item {
   flex-direction: column;
   .ant-col {
    max-width: 100%;
   }
  }
 }
`;
