import { MaxScreenDevice } from "assets/screens/DeviceScreens";
import styled from "styled-components";

export const CharacteristicStyled = styled.div`
 margin: 50px 0;
 padding: 50px 0;
 background-color: ${({ theme }) => theme.colors.white};
 .characteristic {
  &__heading {
   h2 {
    color: ${({ theme }) => theme.colors.blue[900]};
    font-size: 30px;
    font-weight: 600;
    text-align: center;
    text-transform: uppercase;
   }
  }
  &__list {
   display: grid;
   grid-template-columns: repeat(3, 1fr);
   column-gap: 30px;
   row-gap: 100px;
   margin-top: 100px;
   &-item {
    border: 1px solid ${({ theme }) => theme.colors.gray[300]};
    padding: 10px 30px;
    position: relative;
    border-radius: 20px;
    .icon {
     position: absolute;
     left: 50%;
     top: 0;
     transform: translate(-50%, -50%);
     width: 100px;
     height: 100px;
     background-color: ${({ theme }) => theme.colors.white};
     border: 1px solid ${({ theme }) => theme.colors.gray[300]};
     display: flex;
     align-items: center;
     justify-content: center;
     border-radius: 50%;
     i {
      width: 50px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 20px;
      background-color: ${({ theme }) => theme.colors.green[100]};
      color: ${({ theme }) => theme.colors.green[500]};
      border-radius: 50%;
     }
    }
    .content {
     margin-top: 60px;
     /* height: 70%; */
     height: 100%;
     .title {
      h3 {
       font-size: 20px;
       font-weight: 500;
       color: ${({ theme }) => theme.colors.green[500]};
       text-align: center;
       text-transform: uppercase;
      }
     }
     .desc {
      font-size: 15px;
      font-weight: 400;
      color: ${({ theme }) => theme.colors.black};
      text-align: left;
      margin: 20px 0;
     }
     .children {
      font-size: 14px;
      &-item {
       display: flex;
       align-items: start;
       gap: 10px;
       padding-left: 15px;
      }
      ul {
       display: flex;
       flex-direction: column;
       gap: 15px;
       list-style: none;
       padding-left: 0 !important;
       a {
        text-decoration: none;
        color: ${({ theme }) => theme.colors.black};
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;

        i {
         color: ${({ theme }) => theme.colors.blue[600]};

         margin-top: 5px;
         font-size: 15px;
        }
        &:hover {
         i,
         li {
          color: ${({ theme }) => theme.colors.green[600]};
         }
        }
       }
       li {
        list-style: none !important;
        padding-left: 0 !important;
       }
      }
     }
    }
   }
  }
 }

 @media only screen and (${MaxScreenDevice.laptop}) {
  padding: 0 20px;
  .characteristic {
   &__list {
    grid-template-columns: repeat(2, 1fr);
   }
  }
 }
 @media only screen and (${MaxScreenDevice.tablet}) {
  padding: 80px 20px;
  .characteristic {
   &__list {
    grid-template-columns: repeat(2, 1fr);
   }
  }
 }
 @media only screen and (${MaxScreenDevice.custom(600)}) {
  padding: 60px 20px;
  .characteristic {
   &__list {
    grid-template-columns: repeat(1, 1fr);
   }
  }
 }
`;
