/* eslint-disable @typescript-eslint/no-explicit-any */
import {
 Button,
 Col,
 Empty,
 Flex,
 Form,
 Modal,
 Popconfirm,
 Row,
 Tree,
 message,
} from "antd";
import React from "react";
import type { DirectoryTreeProps } from "antd/es/tree";
import { SolutionAdminStyled } from "./styled";
import { useQuery, useQueryClient } from "react-query";
import { QUERY_SOLUTION_ADMIN } from "helpers";
import _ from "lodash";
import { theme } from "theme";
import {
 EditOutlined,
 DeleteOutlined,
 PlusOutlined,
 QuestionCircleOutlined,
} from "@ant-design/icons";
import LoadingV2 from "common/V2/LoadingV2";
import { deleteSolutionQuery, getSolutionQuery } from "./query";
import FormInputSolution from "./FormInputSolution";
import ViewSolutionContent from "./ViewSolutionContent";

const { DirectoryTree } = Tree;

type TCategoryAdmin = {
 id: string;
 title: string;
 href: string;
 htmlFile?: string;
 active: number;
};
interface TStateSelectedTree {
 isSelected: boolean;
 dataSelect: TCategoryAdmin;
}
type TStateModal = {
 visible: boolean;
 type: "update" | "add";
 title: string;
 okText: string;
 cancelText: string;
};

export type TSolutionAdmin = {
 solutionId: string;
 solutionName: string;
 solutionSlug: string;
 solutionActive: number;
 solutionHtmlFile: string;
};

const Component = () => {
 const [isLoadingForm, setIsLoadingForm] = React.useState<boolean>(false);
 const [treeDataState, setTreeDataState] = React.useState<any>([]);
 const [selectedSolution, setSelectedSolution] = React.useState<
  TSolutionAdmin | undefined
 >(undefined);
 const [dataTreeState, setDataTreeState] = React.useState<TStateSelectedTree>({
  isSelected: false,
  dataSelect: { id: "", title: "", href: "", active: 0 },
 });
 const [modalCategory, setModalCategory] = React.useState<TStateModal>({
  visible: false,
  type: "add",
  title: "THÊM DANH MỤC SẢN PHẨM",
  cancelText: "Hủy",
  okText: "Thêm",
 });

 const queryClient = useQueryClient();
 const [formUpdateAndAddSolution] = Form.useForm();

 const { data: dataCategory, isLoading: isLoadingCategory }: any = useQuery(
  QUERY_SOLUTION_ADMIN,
  () => getSolutionQuery()
 );

 const formatDataTree = (solutionArray: TSolutionAdmin[]) => {
  const result = solutionArray.map((solution: TSolutionAdmin) => {
   return {
    href: solution.solutionSlug,
    title: (
     <span
      style={{
       color: solution.solutionActive === 0 ? theme.colors.red[600] : "#000",
      }}
     >
      {solution.solutionName}
     </span>
    ),
    key: solution.solutionId,
    htmlFile: solution.solutionHtmlFile,
    solutionActive: solution.solutionActive,
    children: [],
   };
  });
  setTreeDataState(result);
 };

 const onSelect: DirectoryTreeProps["onSelect"] = (keys, info) => {
  if (info.selectedNodes[0].children) {
   const { key, href, title, htmlFile, solutionActive }: any = info.node;
   setSelectedSolution({
    solutionActive: solutionActive,
    solutionHtmlFile: htmlFile,
    solutionId: key,
    solutionName: title,
    solutionSlug: href,
   });
   setDataTreeState({
    isSelected: true,
    dataSelect: {
     id: key,
     href: href,
     title,
     active: solutionActive,
    },
   });
  }
 };

 const handleOptionModal = (type: "update" | "add") => {
  if (type === "update") {
   setModalCategory({
    visible: true,
    cancelText: "Hủy",
    okText: "Cập nhật",
    title: dataTreeState.dataSelect.title,
    type,
   });
   formUpdateAndAddSolution.setFieldsValue({
    solutionName: dataTreeState.dataSelect.title,
    solutionHref: dataTreeState.dataSelect.href,
   });
  } else {
   setModalCategory({
    visible: true,
    cancelText: "Hủy",
    okText: "Thêm",
    title: "Thêm mới danh mục",
    type,
   });
  }
 };

 const handleDeleteSolution = async () => {
  const response: any = await deleteSolutionQuery(dataTreeState.dataSelect.id);
  if (response?.type === "error") {
   message.error(`Xóa ${dataTreeState.dataSelect.title} thất bại`);
  } else {
   message.success(`Xóa ${dataTreeState.dataSelect.title} thành công`);
   queryClient.invalidateQueries(QUERY_SOLUTION_ADMIN);
  }
 };

 const handleSaveAndUpdateSolution = () => {
  setIsLoadingForm(true);
  formUpdateAndAddSolution.submit();
  setModalCategory((prev: TStateModal) => ({
   ...prev,
   visible: false,
  }));
 };

 const onCloseModal = () => {
  formUpdateAndAddSolution.resetFields();
  queryClient.invalidateQueries(QUERY_SOLUTION_ADMIN);
  setIsLoadingForm(false);
  setModalCategory((prev: TStateModal) => ({
   ...prev,
   visible: false,
  }));
 };

 const refreshSelectSolution = () => {
  if (selectedSolution) {
   const result = _.filter(dataCategory, {
    solutionId: selectedSolution.solutionId,
   })[0];
   setSelectedSolution(result);
  }
 };

 React.useEffect(() => {
  refreshSelectSolution();
  if (!isLoadingCategory) {
   formatDataTree(dataCategory);
  }
 }, [dataCategory, isLoadingCategory]);

 return (
  <SolutionAdminStyled>
   <Modal
    title={modalCategory.title}
    open={modalCategory.visible}
    okText={modalCategory.okText}
    cancelText={modalCategory.cancelText}
    onOk={handleSaveAndUpdateSolution}
    onCancel={onCloseModal}
    confirmLoading={isLoadingForm}
   >
    <FormInputSolution
     id={dataTreeState.dataSelect.id}
     form={formUpdateAndAddSolution}
     onClose={onCloseModal}
     type={modalCategory.type}
    />
   </Modal>
   <Row
    style={{
     minHeight: "80vh",
     height: "100%",
    }}
    justify='space-between'
   >
    <Col
     span={16}
     push={6}
     style={{
      marginLeft: 20,
      backgroundColor: theme.colors.white,
      borderRadius: 6,
      padding: 20,
     }}
    >
     {selectedSolution ? (
      <ViewSolutionContent dataSolution={selectedSolution} />
     ) : (
      <Flex
       justify='center'
       align='center'
       style={{
        width: "100%",
        height: "100%",
       }}
      >
       <Empty description={<span>KHÔNG CÓ DỮ LIỆU</span>}></Empty>
      </Flex>
     )}
    </Col>
    <Col
     span={6}
     pull={18}
     style={{
      backgroundColor: theme.colors.white,
      borderRadius: 6,
      padding: 20,
     }}
    >
     <Flex wrap='wrap' gap='small'>
      <Button
       type='dashed'
       onClick={() => handleOptionModal("add")}
       icon={<PlusOutlined />}
      />
      {dataTreeState.isSelected && (
       <>
        <Button
         type='primary'
         onClick={() => handleOptionModal("update")}
         icon={<EditOutlined />}
        />
        <Popconfirm
         title='Xóa sản phẩm'
         description={
          <p>
           Bạn có chắc chắn muốn xóa
           <strong> {dataTreeState.dataSelect.title}</strong> ?
          </p>
         }
         icon={<QuestionCircleOutlined style={{ color: "red" }} />}
         okText='Xóa'
         cancelText='Hủy'
         onConfirm={handleDeleteSolution}
        >
         <Button type='primary' danger icon={<DeleteOutlined />} />
        </Popconfirm>
       </>
      )}
     </Flex>
     {isLoadingCategory ? (
      <LoadingV2 />
     ) : (
      <DirectoryTree
       multiple
       defaultExpandAll
       onSelect={onSelect}
       treeData={treeDataState}
      />
     )}
    </Col>
   </Row>
  </SolutionAdminStyled>
 );
};

export default Component;
