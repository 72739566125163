import React from "react";
import { ContactHomeStyled } from "assets/styles/HomeStyled/_Contact.styled";
import Container from "common/Container";
import ContactInfo from "common/ContactInfo";
import Social from "common/Social";
import Map from "common/Map";
import ContactForm from "common/ContactForm";

export interface TContactInformation {
 label: string | null;
 content: string;
 href: string;
 icon: string | null;
}
export interface TSocial {
 icon: string;
 href: string;
 label?: string;
 backgroundColor?: string;
}

export interface TContact {
 image: string;
 contactInformation: TContactInformation[];
 social: TSocial[];
}

const ContactComponent = () => {
 //  const { image, contactInformation, social }: TContact = ContactData;

 //  const renderContactInformation = (
 //   information: TContactInformation,
 //   key: number
 //  ) => (
 //   <div className='info__contact' key={key}>
 //    <h4>{information.label}</h4>
 //    <a href={information.href}>{information.content}</a>
 //   </div>
 //  );

 //  const renderSocial = (social: TSocial, key: number) => (
 //   <a href={social.href} key={key}>
 //    {parse(social.icon)}
 //   </a>
 //  );

 const scrollToTop = () => {
  window.scrollTo({
   top: 0,
   left: 0,
   behavior: "smooth",
  });
 };

 React.useEffect(() => {
  scrollToTop();
 }, []);

 return (
  <ContactHomeStyled>
   <Container>
    <div className='contact'>
     <div className='contact__heading'>
      <h1>LIÊN HỆ VỚI CHÚNG TÔI</h1>
     </div>
     <div className='contact__information'>
      <div className='contact__information-info'>
       <div className='title'>
        <h3>Thông tin liên hệ</h3>
       </div>
       <div className='desc'>
        <p>
         Để biết thêm thông tin hoặc yêu cầu về sản phẩm, dự án và giá cả của
         chúng tôi, vui lòng liên hệ với chúng tôi.
        </p>
       </div>
       <ContactInfo />
       {/* <Social /> */}
      </div>
      <div className='map'>
       <Map />
      </div>
     </div>
    </div>
    <ContactForm />
   </Container>
  </ContactHomeStyled>
 );
};

export default ContactComponent;
