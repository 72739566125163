import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { TNavBarData } from "@types";
import { navbarService } from "features/services";

export type TNavbarRedux = {
 navbar: TNavBarData[] | null;
 isError: boolean;
 isSuccess: boolean;
 isLoading: boolean;
 message: string;
};

const initialState: TNavbarRedux = {
 navbar: null,
 isError: false,
 isSuccess: false,
 isLoading: false,
 message: "",
};

export const getAllNavbar = createAsyncThunk("navbar/all", async () => {
 try {
  return await navbarService.getAllNavbar();
 } catch (error: any) {
  const message =
   (error.response && error.response.data && error.response.data.message) ||
   error.message ||
   error.toString();
  return message;
 }
});

export const navbarSlice = createSlice({
 name: "navbar",
 initialState,
 reducers: {
  allNavbar: (state: TNavbarRedux) => {
   state.isLoading = false;
   state.isSuccess = false;
   state.isError = false;
   state.message = "";
  },
 },
 extraReducers(builder) {
  builder
   .addCase(getAllNavbar.pending, (state: TNavbarRedux) => {
    state.isLoading = true;
   })
   .addCase(getAllNavbar.fulfilled, (state: TNavbarRedux, action: any) => {
    state.isLoading = false;
    state.isSuccess = true;
    state.navbar = action.payload;
   })
   .addCase(getAllNavbar.rejected, (state: TNavbarRedux, action: any) => {
    state.isLoading = false;
    state.isError = true;
    state.message = action.payload;
   });
 },
});

export const { allNavbar } = navbarSlice.actions;
export default navbarSlice.reducer;
