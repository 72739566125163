import { ServiceStyled } from "assets/styles/V2/Services/Service.styled";
import Container from "common/Container";
import React from "react";

const Component = () => {
 return (
  <ServiceStyled>
   <Container>
    <h2>DỊCH VỤ CỦA CHÚNG TÔI</h2>
    <div className='service__items'>
     <div className='service__items-item'>
      <div className='service-icon'>
       <i className='fa-solid fa-headset'></i>
      </div>
      <div className='service-heading'>
       <h3>CHUYÊN GIA TƯ VẤN</h3>
      </div>
     </div>
     <div className='service__items-item'>
      <div className='service-icon'>
       <i className='fa-solid fa-pen-ruler'></i>
      </div>
      <div className='service-heading'>
       <h3>THIẾT KẾ HIỆU QUẢ</h3>
      </div>
     </div>
     <div className='service__items-item'>
      <div className='service-icon'>
       <i className='fa-solid fa-piggy-bank'></i>
      </div>
      <div className='service-heading'>
       <h3>TIẾT KIỆN NĂNG LƯỢNG</h3>
      </div>
     </div>
     <div className='service__items-item'>
      <div className='service-icon'>
       <i className='fa-solid fa-screwdriver-wrench'></i>
      </div>
      <div className='service-heading'>
       <h3>LẮP ĐẶT CHUYÊN NGHIỆP</h3>
      </div>
     </div>
     <div className='service__items-item'>
      <div className='service-icon'>
       <i className='fa-solid fa-truck-fast'></i>
      </div>
      <div className='service-heading'>
       <h3>VẬN HÀNH VÀ CHUYỂN GIAO</h3>
      </div>
     </div>
    </div>
   </Container>
  </ServiceStyled>
 );
};

export default Component;
