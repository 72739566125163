import { MaxScreenDevice } from "assets/screens/DeviceScreens";
import styled from "styled-components";
export const ProjectListStyled = styled.section`
 margin: 50px 0;
 .project-list {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  .item {
   width: 100%;
   cursor: default !important;
  }
  .image-project {
   height: 250px;
  }
  .ant-card-body {
   padding: 15px;
  }
  .title {
   display: -webkit-box;
   -webkit-line-clamp: 2;
   -webkit-box-orient: vertical;
   overflow: hidden;
   text-overflow: ellipsis;
   h3 {
    font-size: 16px;
    font-weight: 500;
   }
  }
  .button-see-more {
   margin-top: 20px;
   button {
    background-color: ${({ theme }) => theme.colors.main_yellow};
    border: none;
    padding: 6px 20px;
    font-size: 15px;
    border-radius: 10px;
    cursor: pointer;
    border: 1px solid ${({ theme }) => theme.colors.main_yellow};
    transition: all 0.4s ease;
    i {
     transition: transform 0.4s ease;
    }
    &:hover {
     background-color: transparent;
     color: ${({ theme }) => theme.colors.black};
     i {
      transform: translateX(5px);
     }
    }
   }
  }
 }
 .pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
  .ant-pagination-item-active {
   border-color: ${({ theme }) => theme.colors.main_green};
   background-color: ${({ theme }) => theme.colors.main_green};
   a {
    color: ${({ theme }) => theme.colors.white};
   }
  }
 }
 @media only screen and (${MaxScreenDevice.tablet}) {
  padding: 0 20px;
  .project-list {
   grid-template-columns: repeat(1, 1fr);
  }
 }
`;
