import { MaxScreenDevice } from "assets/screens/DeviceScreens";
import styled from "styled-components";
export const StyledHeader = styled.header`
 transition: all 0.4s ease;
 .header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  height: 150px;
  background-color: ${({ theme }) => theme.colors.white};
  z-index: 999;
  &__logo {
   width: 35%;
   height: 100%;
   display: flex;
   align-items: center;
   justify-content: center;
   padding-left: 100px;
   img {
    width: 100px;
    height: 100px;
    object-fit: contain;
   }
  }
  &__navbar {
   width: 65%;
   height: 100%;
   display: flex;
   flex-direction: column;
  }
  &__toggle {
   display: none;
  }
 }
 @media only screen and (${MaxScreenDevice.laptop}) {
  .header {
   &__logo {
    width: 20%;
    padding-left: 0;
   }
   &__navbar {
    width: 80%;
   }
  }
  .navbar {
   &__menu {
    .nav {
     li {
      a {
       font-size: 14px;
      }
     }
    }
   }
  }
 }

 @media only screen and (${MaxScreenDevice.tablet}) {
  .header {
   justify-content: space-between;
   padding: 0 30px;
   width: calc(100% - 60px);
   height: 80px;
   box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
   &__logo {
    justify-content: start;
    img {
     width: 70px;
     height: 70px;
    }
   }
   &__navbar {
    display: none;
   }
   &__toggle {
    display: flex;
    font-size: 20px;
   }
  }
 }
`;
