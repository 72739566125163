import React from "react";
import { TNewsData } from "./Component";
import { Button, Flex, Form, Modal, Tooltip, message } from "antd";
import {
 EditOutlined,
 UnlockOutlined,
 LockOutlined,
 DeleteOutlined,
} from "@ant-design/icons";
import ContentModal from "./ContentModal";
import { API_SERVER } from "helpers/variable";
import { useQueryClient } from "react-query";
import { QUERY_NEWS_ADMIN } from "helpers";
import { deleteData, updateActiveNewsData } from "./query";

type TProps = {
 record: TNewsData;
};

const Action = ({ record }: TProps) => {
 const [visibleModal, setVisibleModal] = React.useState<boolean>(false);
 const [formUpdate] = Form.useForm();
 const queryClient = useQueryClient();

 const handleOpenEditModal = (data: TNewsData) => {
  formUpdate.setFieldsValue(data);
  setVisibleModal(true);
 };
 const handleEdit = () => {
  formUpdate.submit();
 };
 const handleActiveUpdate = async (id: string, active: number) => {
  const response: any = await updateActiveNewsData({ active }, id);
  if (response?.type === "error") {
   return message.error(response.error);
  }
  queryClient.invalidateQueries(QUERY_NEWS_ADMIN);
  message.success("Cập nhật dữ liệu thành công");
 };
 const handleDelete = async (id: string) => {
  const response: any = await deleteData(id);
  if (response?.type === "error") return message.error(response.error);
  queryClient.invalidateQueries(QUERY_NEWS_ADMIN);
  message.success("Xóa dữ liệu thành công");
 };
 const handleCloseModal = () => {
  formUpdate.resetFields();
  queryClient.invalidateQueries(QUERY_NEWS_ADMIN);
  setVisibleModal(false);
 };
 return (
  <>
   <Modal
    open={visibleModal}
    title='CẬP NHẬT NỘI DUNG'
    onOk={handleEdit}
    onCancel={handleCloseModal}
    okText='Cập nhật'
    cancelText='Hủy'
    width={"80%"}
   >
    <ContentModal
     type='update'
     form={formUpdate}
     onCloseModal={handleCloseModal}
     idNewsUpdate={record.id}
     imageListProp={[
      {
       uid: "0",
       name: record.imageThumbnail,
       status: "done",
       url: API_SERVER + "/" + record.imageThumbnail,
       thumbUrl: API_SERVER + "/" + record.imageThumbnail,
      },
     ]}
    />
   </Modal>
   <Flex gap='small'>
    <Tooltip title='Chỉnh sửa'>
     <Button
      onClick={() => handleOpenEditModal(record)}
      type='dashed'
      shape='circle'
      icon={<EditOutlined />}
     />
    </Tooltip>
    {record.active === 0 ? (
     <Tooltip
      title='Mở
      khóa'
     >
      <Button
       onClick={() => handleActiveUpdate(record.id, 1)}
       type='primary'
       shape='circle'
       icon={<UnlockOutlined />}
      />
     </Tooltip>
    ) : (
     <Tooltip title='Khóa'>
      <Button
       onClick={() => handleActiveUpdate(record.id, 0)}
       type='primary'
       danger
       shape='circle'
       icon={<LockOutlined />}
      />
     </Tooltip>
    )}
    <Tooltip title='Xóa dữ liệu'>
     <Button
      onClick={() => handleDelete(record.id)}
      type='primary'
      danger
      shape='circle'
      icon={<DeleteOutlined />}
     />
    </Tooltip>
   </Flex>
  </>
 );
};

export default Action;
