import { Swiper } from "swiper/react";
import { MaxScreenDevice } from "assets/screens/DeviceScreens";
import styled from "styled-components";

export const CarouselStyled = styled.div`
 .navigation-custom {
  position: absolute;
  display: flex;
  align-items: center;

  justify-content: space-between;
  width: calc(100% - 60px);
  top: 50%;
  transform: translateY(-50%);
  padding: 0 30px;
  transition: padding 0.3s ease;
  z-index: 9999;
  button {
   background-color: transparent;
   border: none;
   width: 40px;
   height: 40px;
   border: 1px solid ${({ theme }) => theme.colors.white};
   border-radius: 50%;
   cursor: pointer;
   transition: all 0.4s ease;
   i {
    color: ${({ theme }) => theme.colors.white};
    font-size: 20px;
   }
   &:hover {
    border-color: ${({ theme }) => theme.colors.blue[900]};
    background-color: ${({ theme }) => theme.colors.blue[900]};
   }
  }
 }
 .swiper {
  width: 100%;
  height: 100%;
  z-index: 1;
  .image-swiper {
   height: 50vh;
  }
  &:hover {
   .navigation-custom {
    padding: 0 20px;
    visibility: visible;
   }
  }
 }

 .swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
 }

 .swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
 }

 .swiper-pagination-bullet {
  width: 10px;
  height: 10px;
  text-align: center;
  line-height: 20px;
  font-size: 12px;
  color: #000;
  opacity: 1;
  background: rgba(0, 0, 0, 0.2);
 }

 .swiper-pagination-bullet-active {
  color: #fff;
  background: ${({ theme }) => theme.colors.white};
 }
 @media only screen and (${MaxScreenDevice.laptop}) {
  .swiper {
   .image-swiper {
    height: 50vh;
   }
  }
 }
`;
