import { TNews } from "@types";
import { Skeleton } from "antd";
import { NewsHomeV2Styled } from "assets/styles/V2/_NewV2.styled";
import Container from "common/Container";
import LoadingV2 from "common/V2/LoadingV2";
import { API_SERVER } from "helpers/variable";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "store";

const NewV2Component = () => {
 const { news, isLoading } = useSelector((state: RootState) => state.news);
 const navigate = useNavigate();
 const handleSwitch = (href: string) => {
  navigate(`/tin-tuc/${href}`);
 };

 return (
  <>
   {!isLoading && news && news.length > 0 ? (
    <NewsHomeV2Styled backgroundImage='images/background-view-new-home.jpg'>
     <Container>
      <div className='news__left-heading'>
       <div className='title'>
        <h3>Tin tức & sự kiện</h3>
       </div>
       <div className='button-see-more'>
        <button>Xem tất cả</button>
       </div>
      </div>
      <div className='news'>
       <div className='news__left'>
        <div className='news__left_top-5'>
         <div className='item item-desktop'>
          <div className='item__info'>
           <div className='new-date'>
            <p>{news[0].date}</p>
           </div>
           <div className='new-title'>
            <h3>{news[0].title}</h3>
           </div>
           <div className='new-summary'>
            <p>{news[0].summary}</p>
           </div>
           <div className='new-button'>
            <button onClick={() => handleSwitch(news[0].href)}>Xem thêm</button>
           </div>
          </div>
          <div className='item__image'>
           <img
            src={API_SERVER + "/" + news[0].imageThumbnail}
            alt={`${news[0].title}`}
           />
          </div>
         </div>
         <div
          className='item item-mobile item__hover'
          onClick={() => handleSwitch(news[0].href)}
         >
          <div className='new__top4'>
           <div className='image'>
            <img
             src={API_SERVER + "/" + news[0].imageThumbnail}
             alt={`${news[0].title}`}
            />
           </div>
           <div className='info'>
            <div className='date'>
             <p>{news[0].date}</p>
            </div>
            <div className='title'>
             <h4>{news[0].title}</h4>
            </div>
           </div>
          </div>
         </div>
         {news.slice(1, 5).map((item: TNews) => (
          <div
           className='item item__hover'
           key={item.id}
           onClick={() => handleSwitch(item.href)}
          >
           <div className='new__top4'>
            <div className='image'>
             <img
              src={API_SERVER + "/" + item.imageThumbnail}
              alt={`${item.title}`}
             />
            </div>
            <div className='info'>
             <div className='date'>
              <p>{item.date}</p>
             </div>
             <div className='title'>
              <h4>{item.title}</h4>
             </div>
            </div>
           </div>
          </div>
         ))}
        </div>
       </div>
       <div className='news__right'>
        {news.slice(5, 10).map((item: TNews) => (
         <div
          className='news__right-item item__hover'
          onClick={() => handleSwitch(item.href)}
          key={item.id}
         >
          <div className='image'>
           <img
            src={API_SERVER + "/" + item.imageThumbnail}
            alt={`${item.title}`}
           />
          </div>
          <div className='info'>
           <div className='date'>
            <p>{item.date}</p>
           </div>
           <div className='title'>
            <h5>{item.title}</h5>
           </div>
          </div>
         </div>
        ))}
       </div>
      </div>
     </Container>
    </NewsHomeV2Styled>
   ) : (
    <LoadingV2 />
   )}
  </>
 );
};

export default NewV2Component;
