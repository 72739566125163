import infoCompany from "assets/json/Infomation";
import MenuHeader, { TMenuChild } from "./MenuHeader";

export interface TServiceFooter {
 label: string;
 items: TMenuChild[];
}
export interface TContactInformation {
 label: string;
 information: {
  icon: string;
  label: string | null;
  content: string;
  href: string;
 }[];
}

export interface TFooterData {
 information: string;
 contactInformation: TContactInformation;
 services: TServiceFooter;
 copyright: {
  year: number;
  icon: string;
  label: string;
 };
 hotline: {
  label: string;
  phoneNumber: string;
  href: string;
 };
}

const FooterData: TFooterData = {
 information:
  "DTSolar - đối tác đáng tin cậy trong lĩnh vực năng lượng mặt trời! Chúng tôi tự hào là một trong những đơn vị hàng đầu cung cấp giải pháp tiết kiệm tối ưu về năng lượng mặt trời, mang đến cho khách hàng sự hiệu quả và bền vững.",
 contactInformation: {
  label: "Thông tin liên lạc",
  information: [
   {
    icon: `<i class="fa-solid fa-building"></i>`,
    label: null,
    content: infoCompany.nameCompany,
    href: "dtsolar.com.vn",
   },
   {
    icon: `<i class="fa-solid fa-phone"></i>`,
    label: "Số điện thoại: ",
    content: infoCompany.phoneNumber,
    href: `tel:+${infoCompany.phoneNumber}`,
   },
   {
    icon: `<i class="fa-solid fa-envelope"></i>`,
    label: "Email",
    content: infoCompany.email,
    href: `mailto:${infoCompany.email}`,
   },
   {
    icon: `<i class="fa-solid fa-map"></i>`,
    label: "Địa chỉ: ",
    content: infoCompany.address,
    href:
     "https://www.google.com/maps?ll=13.096395,109.315052&z=15&t=m&hl=vi&gl=US&mapclient=embed&cid=3720750674118003946",
   },
  ],
 },
 services: {
  label: "Sản phẩm",
  items: [...MenuHeader.headerTop, ...MenuHeader.headerNavbar],
 },
 copyright: {
  year: 2023,
  icon: '<i class="fa-regular fa-copyright"></i>',
  label: infoCompany.nameCompany,
 },
 hotline: {
  label: "Hotline tư vấn",
  phoneNumber: infoCompany.phoneNumber,
  href: `tel:+${infoCompany.phoneNumber}`,
 },
};

export default FooterData;
