import { configureStore } from "@reduxjs/toolkit";
import {
 CarouselSlice,
 ProductSlice,
 CategorySlice,
 CartSlice,
 AuthSlice,
 SolutionSlice,
 ProjectSlice,
 newsSlice,
 solutionPackageSlice,
 aboutUsSlice,
} from "features/slices";
import NavbarReducer from "features/slices/Navbar";

export const store = configureStore({
 reducer: {
  navbar: NavbarReducer,
  carousel: CarouselSlice,
  product: ProductSlice,
  category: CategorySlice,
  cart: CartSlice,
  auth: AuthSlice,
  solution: SolutionSlice,
  project: ProjectSlice,
  news: newsSlice,
  solutionPackage: solutionPackageSlice,
  aboutUs: aboutUsSlice,
 },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
