import { getQueryHelper, postQueryHelper } from "helpers";

// export const getHomeManageQuery = () => {
//  return getQueryHelper("/home-manage/add");
// };
export interface TDataAboutUs {
 id: string;
 imageUrl: string;
 redirect: string;
 buttonText: string;
 summary: string;
 contentPages: string;
 title: string;
}

export const addHomeManageQuery = (parameters: any) => {
 return postQueryHelper("/about-us/add-update-about-us", parameters);
};
export const getDataAboutUs = () => {
 return getQueryHelper("/about-us/select-about-us");
};
