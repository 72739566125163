import React from "react";
import { Button, Flex, List, Modal, Typography } from "antd";

const { Link, Text } = Typography;

type TProps = {
 data: {
  [key: string]: string;
 };
};

const ViewContent = ({ data }: TProps) => {
 const [visible, setVisible] = React.useState<boolean>(false);

 const handleOpenModal = () => {
  setVisible(true);
 };
 const handleCloseModal = () => {
  setVisible(false);
 };

 return (
  <>
   <Modal
    open={visible}
    onCancel={handleCloseModal}
    styles={{
     content: {
      padding: "30px 40px",
     },
    }}
    footer={[
     <Button type='primary' onClick={handleCloseModal}>
      Đóng
     </Button>,
    ]}
   >
    {Object.keys(data).map((key: string) => (
     <Flex
      align='start'
      style={{
       margin: "10px 0",
      }}
      gap={20}
     >
      <Text
       strong
       style={{
        minWidth: 70,
        textTransform: "uppercase",
       }}
      >
       {key}:
      </Text>
      <Text>{data[key]}</Text>
     </Flex>
    ))}
   </Modal>
   <Link onClick={handleOpenModal}>Xem chi tiết</Link>
  </>
 );
};

export default ViewContent;
