import styled from "styled-components";

export const ContactForConsultationStyled = styled.div`
 max-width: 1160px;
 width: 100%;
 height: 100%;
 margin: 0 auto;
 padding: 0;
 background-color: transparent;
`;
