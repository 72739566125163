import { THeaderV2 } from "@types";
import { NavbarStyled } from "assets/styles";
import React from "react";
import { NavLink } from "react-router-dom";
import { theme } from "theme";
import { TNavbarChildren } from "types";

type TProps = {
 dataNavbar: THeaderV2[];
 element?: JSX.Element;
 styleNavbar: {
  active: boolean;
  fontStyle: {
   fontSize: string;
   fontWeight: number;
   textTransform: string;
  };
  color: string;
  backgroundColor: string;
  colorHover: string;
  paddingRight: boolean;
 };
};

const Menu = ({ dataNavbar, element, styleNavbar }: TProps) => {
 const renderNavbar = (dataNavbar: any) => {
  return dataNavbar?.map((item: any, index: number) => (
   <li
    key={index}
    className={`nav__item ${item?.subMenu !== null && "menu__line"}`}
   >
    {item?.subMenu?.length === 0 ? (
     <NavLink to={item?.href} className='transition'>
      {item.title}
     </NavLink>
    ) : (
     <p>{item.title}</p>
    )}
    <div className='sub'>
     <div className='sub__ctt'>
      {item?.subMenu?.map(
       (subMenuParent: TNavbarChildren, indexSub: number) => {
        return (
         <div className='sub__ctt__item' key={indexSub}>
          <ul className='sub__ctt__item-list'>
           <li>
            <NavLink to={item?.href + "/" + subMenuParent?.href}>
             {subMenuParent?.title}
            </NavLink>
           </li>
          </ul>
         </div>
        );
       }
      )}
     </div>
    </div>
   </li>
  ));
 };
 return (
  <NavbarStyled {...styleNavbar}>
   <div className='navbar'>
    <div className='navbar__menu'>
     <ul className='nav'>
      {renderNavbar(dataNavbar)}
      {element && element}
     </ul>
    </div>
   </div>
  </NavbarStyled>
 );
};

export default Menu;
