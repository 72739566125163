import { MaxScreenDevice } from "assets/screens/DeviceScreens";
import styled from "styled-components";
export const ServiceStyled = styled.div`
 padding: 50px 0;
 background: linear-gradient(
  to right,
  ${({ theme }) => theme.colors.green[100]} 5%,
  ${({ theme }) => theme.colors.green[300]}
 );
 h2 {
  font-size: 30px;
  font-weight: 600;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.black};
  text-align: center;
 }
 .service__items {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  margin-top: 30px;
  &-item {
   .service {
    &-icon {
     text-align: center;
     height: 50px;
     font-size: 40px;
     padding: 20px 0 50px;
     i {
      border: 1px solid ${({ theme }) => theme.colors.main_yellow};
      padding: 10px;
      border-radius: 50%;
      color: ${({ theme }) => theme.colors.main_yellow};
     }
    }
    &-heading {
     height: calc(100% - 50px);
     h3 {
      font-size: 20px;
      font-weight: 500;
     }
    }
   }
  }
 }
 @media only screen and (${MaxScreenDevice.tablet}) {
  h2 {
   font-size: 23px;
  }
  .service__items {
   flex-direction: column;
   margin: 20px;
   &-item {
    .service {
     &-icon {
      padding: 10px 0;
      &-item {
       .service {
        &-icon {
        }
        &-heading {
         height: calc(100% - 50px);
         h3 {
          font-size: 16px;
         }
        }
       }
      }
      i {
       border: 1px solid ${({ theme }) => theme.colors.main_yellow};
       padding: 10px;
       border-radius: 50%;
       color: ${({ theme }) => theme.colors.main_yellow};
      }
     }
     &-heading {
      height: calc(100% - 50px);
      margin-top: 20px;
      h3 {
       font-size: 18px;
      }
     }
    }
   }
  }
 }
`;
