import { MaxScreenDevice } from "assets/screens/DeviceScreens";
import styled from "styled-components";

export const ContactHomeStyled = styled.div`
 width: 100%;
 padding: 100px 0;
 .contact {
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray[400]};
  &__heading {
   h1 {
    color: ${({ theme }) => theme.colors.black};
    font-size: 30px;
    font-weight: 600;
    text-align: center;
    text-transform: uppercase;
    padding-bottom: 10px;
    position: relative;
    &::after {
     content: "";
     width: 70px;
     height: 2px;
     background-color: ${({ theme }) => theme.colors.black};
     position: absolute;
     left: 50%;
     bottom: 0;
     transform: translateX(-50%);
    }
   }
   .desc {
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    p {
     max-width: 60%;
     color: ${({ theme }) => theme.colors.black};
     font-size: 15px;
     font-weight: 400;
     text-align: center;
    }
   }
  }
  &__information {
   margin: 50px 0;
   display: flex;
   align-items: start;
   gap: 30px;
   &-info {
    width: 40%;
    .title {
     h3 {
      font-size: 20px;
      color: ${({ theme }) => theme.colors.black};
      font-weight: 500;
      text-transform: uppercase;
     }
    }
    .desc {
     margin-top: 10px;
     p {
      font-size: 15px;
      color: ${({ theme }) => theme.colors.black};
      font-weight: 400;
     }
    }
   }
   .map {
    width: 60%;
    height: 400px;
   }
  }
 }
 @media only screen and (${MaxScreenDevice.laptop}) {
  padding: 0 20px;
 }
 @media only screen and (${MaxScreenDevice.custom(800)}) {
  margin: 0 0 100px;
  padding: 0 20px;
  width: calc(100% - 40px);
  .contact {
   &__heading {
    h1 {
     font-size: 18px;
    }
    .desc {
     p {
      font-size: 14px;
      max-width: 100%;
     }
    }
   }
   &__information {
    flex-direction: column-reverse;
    margin: 30px 0;
    &-info {
     width: 100%;
     .title {
      h3 {
       font-size: 17px;
      }
     }
     .desc {
      margin-top: 10px;
      p {
       font-size: 13px;
      }
     }
    }
    .map {
     width: 100%;
    }
   }
  }
 }
`;
