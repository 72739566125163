import { isObject } from "lodash";
import React, { useCallback, useEffect, useRef, useState } from "react";
import SunEditor from "suneditor-react";
import SunEditorCore from "suneditor/src/lib/core";
import { theme } from "theme";
import { API_SERVER } from "helpers/variable";
import { UploadBeforeHandler } from "suneditor-react/dist/types/upload";
import { postQueryHelper } from "helpers";
import Container from "./Container";

const editorOptions = {
 height: "800px",
 position: "relative, static, position",
 //  popupDisplay: "",
 display: "block, inline, flex",
 buttonList: [
  ["undo", "redo"],
  ["removeFormat"],
  ["bold", "underline", "italic", "fontSize"],
  ["fontColor", "hiliteColor"],
  ["align", "horizontalRule", "list", "outdent", "indent"],
  ["table", "link", "image", "imageGallery", "video"],
  ["showBlocks", "codeView", "preview", "fullScreen"],
 ],
 imageRotation: false,
 fontSize: [12, 14, 16, 18, 20],
 colorList: [""],
 previewTemplate:
  "<div style='width:800px; max-width:1080px; margin:auto;'>    <h1>Preview Template</h1>     {{contents}}     <div>_Footer_</div></div>            ",
 //  imageGalleryUrl: API_SERVER + "/upload",
};

interface YourObjectType {
 [key: string]: string;
}
const TextEditorTest = () => {
 const editor = useRef<SunEditorCore>();
 const colorsTheme: any = theme.colors;
 const [colorOption, setColorOption] = useState<string[]>([]);

 const optionColor = useCallback(() => {
  const colorCustom: string[] = [];
  Object.keys(colorsTheme).map((keysColor: string) => {
   const color: YourObjectType = colorsTheme[keysColor];
   if (!isObject(color)) return colorCustom.push(color);
   return Object.keys(color).map((key: string) => colorCustom.push(color[key]));
  });
  setColorOption(colorCustom);
 }, [colorsTheme]);

 const optionFontSize = () => {
  const fontSize: number[] = [];
  for (let i = 10; i < 25; i++) {
   fontSize.push(i);
  }
  editorOptions.fontSize = [...fontSize];
 };

 editorOptions.colorList = [...colorOption];
 useEffect(() => {
  optionColor();
  optionFontSize();
 }, [optionColor]);

 const getSunEditorInstance = (sunEditor: SunEditorCore) => {
  editor.current = sunEditor;
 };
 const onImageUploadBefore = (
  files: File[],
  info: object,
  uploadHandler: UploadBeforeHandler
 ) => {
  const formData = new FormData();
  if (files) {
   formData.append("file", files[0]);

   postQueryHelper("/upload", formData).then((res: any) => {
    const response = {
     result: [
      {
       url: API_SERVER + "/" + res.originalname,
       name: files[0].name,
       size: files[0].size,
      },
     ],
    };
    uploadHandler(response);
   });
  }
  return undefined;
 };
 return (
  <>
   <Container>
    <SunEditor
     lang='en'
     onImageUploadBefore={onImageUploadBefore}
     width='100%'
     height='100%'
     setOptions={editorOptions}
     getSunEditorInstance={getSunEditorInstance}
     onChange={(value) => console.log({ value })}
    />
   </Container>
  </>
 );
};

export default TextEditorTest;
