import React from "react";
import { EstimationStyled } from "./styled";
import {
 MaterialSymbolsLightSolarPowerOutline,
 MdiSolarPanelLarge,
 SolarSunsetLineDuotone,
 StreamlineShoppingBuildingRealHomeTowerBuildingHouseEstate,
 SubwayPower,
} from "icons";
import { availableParallelism } from "os";
import { formatter } from "components/admin/common/formatPrice";
import { TResultEstimation } from "./SystemEstimation";

export type TResultEstimationState = {
 wattage: number; //Công suất hệ thống
 acreage: number; // Diện tích lắp đặt
 batteryPanels: number; // Số lượng tấm pin
 electricityOutput: number; // Sản lượng điện
 annualCosts: number; // Chi phí tiết kiện
};

type TProps = {
 averageElectricity: number;
 resultEstimation: Function;
};

const Estimation = ({ averageElectricity, resultEstimation }: TProps) => {
 const [result, setResult] = React.useState<TResultEstimationState>({
  wattage: 0,
  acreage: 0,
  batteryPanels: 0,
  electricityOutput: 0,
  annualCosts: 0,
 });
 const estimationCal = () => {
  const wat: number = Number((averageElectricity / 360 / 1000).toFixed());
  setResult((prev: TResultEstimationState) => ({
   ...prev,
   wattage: wat,
   acreage: wat * 5,
   batteryPanels: wat * 2,
   electricityOutput: (wat * 4 * 360) / 1000,
   annualCosts: wat * 4 * 360 * 3000,
  }));
  resultEstimation({
   wattage: wat,
   acreage: wat * 5,
   batteryPanels: wat * 2,
   electricityOutput: (wat * 4 * 360) / 1000,
   annualCosts: wat * 4 * 360 * 3000,
  });
 };

 React.useEffect(() => {
  estimationCal();
 }, []);

 return (
  <EstimationStyled>
   <div className='estimation'>
    <div className='estimation__item'>
     <div className='estimation__item-icon'>
      <MaterialSymbolsLightSolarPowerOutline />
     </div>
     <div className='estimation__item-label'>
      <h3>Công suất điện</h3>
     </div>
     <div className='estimation__item-result'>
      <p>
       {result.wattage} <br /> (kW)
      </p>
     </div>
    </div>
    <div className='estimation__item'>
     <div className='estimation__item-icon'>
      <StreamlineShoppingBuildingRealHomeTowerBuildingHouseEstate />
     </div>
     <div className='estimation__item-label'>
      <h3>Diện tích trung binh</h3>
     </div>
     <div className='estimation__item-result'>
      <p>
       {result.acreage} <br /> (m²)
      </p>
     </div>
    </div>
    <div className='estimation__item'>
     <div className='estimation__item-icon'>
      <MdiSolarPanelLarge />
     </div>
     <div className='estimation__item-label'>
      <h3>Số lượng pin sử dụng</h3>
     </div>
     <div className='estimation__item-result'>
      <p>
       {result.batteryPanels} <br /> (Tấm pin)
      </p>
     </div>
    </div>
    <div className='estimation__item'>
     <div className='estimation__item-icon'>
      <SubwayPower />
     </div>
     <div className='estimation__item-label'>
      <h3>Sản lượng điện</h3>
     </div>
     <div className='estimation__item-result'>
      <p>
       {result.electricityOutput.toFixed(3)} <br /> (kWh/năm)
      </p>
     </div>
    </div>
    <div className='estimation__item'>
     <div className='estimation__item-icon'>
      <SolarSunsetLineDuotone />
     </div>
     <div className='estimation__item-label'>
      <h3>chi phí điện hàng năm</h3>
     </div>
     <div className='estimation__item-result'>
      <p>
       {result.annualCosts.toLocaleString("vi-VN")} <br /> (VND/năm)
      </p>
     </div>
    </div>
   </div>
  </EstimationStyled>
 );
};

export default Estimation;
