import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { TSolution } from "@types";
import { solutionService } from "features/services";

export type TSolutionRedux = {
 solution: TSolution[] | null;
 isError: boolean;
 isSuccess: boolean;
 isLoading: boolean;
 message: string;
};

const initialState: TSolutionRedux = {
 solution: null,
 isError: false,
 isSuccess: false,
 isLoading: false,
 message: "",
};

export const getAllSolution = createAsyncThunk("solution/all", async () => {
 try {
  return await solutionService.getAllSolution();
 } catch (error: any) {
  const message =
   (error.response && error.response.data && error.response.data.message) ||
   error.message ||
   error.toString();
  return message;
 }
});

export const solutionSlice = createSlice({
 name: "solution",
 initialState,
 reducers: {
  allSolutionActive: (state: TSolutionRedux) => {
   state.isLoading = false;
   state.isSuccess = false;
   state.isError = false;
   state.message = "";
  },
 },
 extraReducers(builder) {
  builder
   .addCase(getAllSolution.pending, (state: TSolutionRedux) => {
    state.isLoading = true;
   })
   .addCase(getAllSolution.fulfilled, (state: TSolutionRedux, action: any) => {
    state.isLoading = false;
    state.isSuccess = true;
    state.solution = action.payload;
   })
   .addCase(getAllSolution.rejected, (state: TSolutionRedux, action: any) => {
    state.isLoading = false;
    state.isError = true;
    state.message = action.payload;
   });
 },
});

export const { allSolutionActive } = solutionSlice.actions;
export default solutionSlice.reducer;
