import axios from "axios";
import { postQueryHelper } from "helpers";

const login = async (values: any) => {
 const response: any = await postQueryHelper("/auth/sign-in", values);
 if (!response.type) {
  localStorage.setItem("user", JSON.stringify(response));
  window.location.href = window.location.origin + "/admin";
 }
 return response;
};

const logout = () => {
 localStorage.removeItem("user");
};

const authService = {
 logout,
 login,
};
export default authService;
