import {
 deleteQueryHelper,
 getQueryHelper,
 patchQueryHelper,
 postQueryHelper,
} from "helpers";

export const getSolutionQuery = () => {
 return getQueryHelper("/solution/get-all-solution");
};
export const addSolutionQuery = (parameters: {
 title: string;
 href: string;
 html?: string;
}) => {
 return postQueryHelper("/solution/add-solution", parameters);
};
export const updateSolutionQuery = (
 parameters: {
  title?: string;
  href?: string;
  fileHtml?: string;
  active?: number;
 },
 idSolution: string
) => {
 return patchQueryHelper(`/solution/update-solution/${idSolution}`, parameters);
};
export const deleteSolutionQuery = (idSolution: string) => {
 return deleteQueryHelper(`/solution/delete-solution/${idSolution}`);
};
