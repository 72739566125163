import { MaxScreenDevice } from "assets/screens/DeviceScreens";
import styled from "styled-components";
type TProps = {
 backgroundImage: string;
};
export const BannerStyled = styled.div<TProps>`
 background-image: url(${({ backgroundImage }) => backgroundImage});
 background-size: cover;
 background-position-y: center;
 width: 100%;
 height: 400px;
 position: relative;
 .content-banner {
  width: 100%;
  height: 50px;
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: start;
  .breadcrumb {
   display: flex;
   align-items: center;
   justify-content: start;
   height: 100%;
   ul {
    display: flex;
    align-items: center;
    gap: 10px;
    .link {
     a {
      text-decoration: none;
      font-size: 15px;
      font-weight: 500;
      color: ${({ theme }) => theme.colors.white};
      &:hover {
       color: ${({ theme }) => theme.colors.main_yellow};
      }
     }
     i {
      margin-left: 10px;
     }
    }
    li {
     list-style: none;
     font-size: 15px;
     font-weight: 500;
     color: ${({ theme }) => theme.colors.white};
    }
   }
  }
 }
 h1 {
  color: ${({ theme }) => theme.colors.white};
  font-size: 30px;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
  padding-bottom: 10px;
 }
 @media only screen and (${MaxScreenDevice.tablet}) {
  .content-banner {
   width: calc(100% - 40px);
   padding: 0 20px;
  }
 }
`;
