import { Button } from "antd";
import { MaxScreenDevice } from "assets/screens/DeviceScreens";
import styled from "styled-components";
export const AboutEnterpriseStyled = styled.div`
 background: linear-gradient(
  to right,
  ${({ theme }) => theme.colors.green[100]} 5%,
  ${({ theme }) => theme.colors.green[300]}
 );
 .about-enterprise {
  display: flex;
  align-items: center;
  justify-content: start;
  min-height: 60vh;
  height: 100%;
  width: 100%;
  gap: 20px;
  /* margin-top: 100px; */
  &__left {
   padding: 30px 0;
   width: 40%;
   .heading {
    h1 {
     font-size: 30px;
     font-weight: 600;
     text-transform: uppercase;
    }
   }
   .desc {
    margin: 10px 0;
    p {
     font-size: 16px;
     font-weight: 400;
     line-height: 140%;
     text-align: justify;
    }
   }
   .button-see-more {
    margin-top: 20px;
    button {
     background-color: ${({ theme }) => theme.colors.main_yellow};
     color: ${({ theme }) => theme.colors.white};
     padding: 10px 20px;
     border: none;
     font-size: 18px;
     border-radius: 20px;
     text-transform: uppercase;
     cursor: pointer;
     transition: transform 0.4s ease;
     &:hover {
      transform: translateY(5px);
     }
    }
   }
  }
  &__right {
   position: relative;
   width: 60%;
   height: 100%;
   .background {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: ${({ theme }) => theme.colors.gray[500]};
    opacity: 0.5;
   }
   .icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    &-play {
     font-size: 40px;
     color: ${({ theme }) => theme.colors.white};
     cursor: pointer;
     transform: scale(1);
     transition: transform 0.4s ease;
     &:hover {
      transform: scale(1.2);
     }
    }
   }
  }
 }
 @media only screen and (${MaxScreenDevice.tablet}) {
  width: 100%;
  .about-enterprise {
   flex-direction: column;
   height: 100%;
   width: calc(100% - 40px);
   padding: 40px 20px;
   &__right {
    width: 100%;
   }
   &__left {
    width: 100%;
    .heading {
     h1 {
      font-size: 20px;
      text-align: center;
     }
    }
    .desc {
     p {
      text-align: center;
     }
    }
    .button-see-more {
     display: flex;
     align-items: center;
     justify-content: center;
     button {
      font-size: 15px;
     }
    }
   }
  }
 }
`;
