import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { TDataAboutUs } from "components/admin/Content/UIManage/homeManage/edit/query";
import { aboutUsService } from "features/services";

export type TAboutUsRedux = {
 aboutUs: TDataAboutUs[];
 isError: boolean;
 isSuccess: boolean;
 isLoading: boolean;
 message: string;
};

const initialState: TAboutUsRedux = {
 aboutUs: [],
 isError: false,
 isSuccess: false,
 isLoading: false,
 message: "",
};

export const getAllAboutUs = createAsyncThunk("aboutUs/all", async () => {
 try {
  return await aboutUsService.getAllAboutUs();
 } catch (error: any) {
  const message =
   (error.response && error.response.data && error.response.data.message) ||
   error.message ||
   error.toString();
  return message;
 }
});

export const AboutUsSlice = createSlice({
 name: "carousel",
 initialState,
 reducers: {
  allAboutUsActive: (state: TAboutUsRedux) => {
   state.isLoading = false;
   state.isSuccess = false;
   state.isError = false;
   state.message = "";
  },
 },
 extraReducers(builder) {
  builder
   .addCase(getAllAboutUs.pending, (state: TAboutUsRedux) => {
    state.isLoading = true;
   })
   .addCase(getAllAboutUs.fulfilled, (state: TAboutUsRedux, action: any) => {
    state.isLoading = false;
    state.isSuccess = true;
    state.aboutUs = action.payload;
   })
   .addCase(getAllAboutUs.rejected, (state: TAboutUsRedux, action: any) => {
    state.isLoading = false;
    state.isError = true;
    state.message = action.payload;
   });
 },
});

export const { allAboutUsActive } = AboutUsSlice.actions;
export default AboutUsSlice.reducer;
