import { Space, Tag } from "antd";
import { ColumnsType } from "antd/es/table";
import ViewContent from "./ViewContent";
import Action from "./Action";

export interface TDataMessage {
 customerInformation: {
  [key: string]: string;
 };
 id: string;
 message: {
  [key: string]: string;
 };
 time: string;
 status: number;
 title: string;
}

export interface DataType extends TDataMessage {
 key: string;
}
export const columns: ColumnsType<DataType> = [
 {
  title: "Tiêu đề",
  dataIndex: "title",
  key: "title",
 },
 {
  title: "Thông tin khách hàng",
  dataIndex: "customerInformation",
  key: "customerInformation",
  render: (information) => {
   return <ViewContent data={information} />;
  },
 },
 {
  title: "Nội dung",
  dataIndex: "message",
  key: "message",
  render: (message) => {
   return <ViewContent data={message} />;
  },
 },
 {
  title: "Thời gian",
  dataIndex: "time",
  key: "time",
 },
 {
  title: "Trạng thái",
  dataIndex: "status",
  key: "status",
  render: (value: number) => {
   let text: string, color: string;
   if (value === 1) {
    text = "Đã gửi";
    color = "#87d068";
   } else {
    text = "Chưa gửi";
    color = "#f50";
   }
   return <Tag color={color}>{text}</Tag>;
  },
 },
 {
  title: "Action",
  key: "action",
  render: (_, record: TDataMessage) => <Action record={record} />,
 },
];
