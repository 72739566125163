import { MaxScreenDevice } from "assets/screens/DeviceScreens";
import styled from "styled-components";

export const CardStyled = styled.div`
 background-color: ${({ theme }) => theme.colors.gray[100]};
 border: 1px solid ${({ theme }) => theme.colors.gray[200]};
 border-radius: 5px;
 transition: all 0.4s ease;
 cursor: pointer;
 .card {
  &__image {
   width: 100%;
   height: 250px;
   img {
    width: 100%;
    height: 100%;
    object-fit: cover;
   }
  }
  &__content {
   padding: 20px;
   &-title {
    text-align: left;
    padding-bottom: 20px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.black};

    a {
     font-size: 18px;
     font-weight: 600;
     color: ${({ theme }) => theme.colors.blue[900]};
     text-decoration: none;
     transition: all 0.4s ease;
     display: -webkit-box;
     -webkit-line-clamp: 1;
     -webkit-box-orient: vertical;
     overflow: hidden;
     text-overflow: ellipsis;
     &:hover {
      color: ${({ theme }) => theme.colors.green[600]};
     }
    }
   }
   &-desc {
    margin-top: 10px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    p {
     font-size: 15px;
     font-weight: 400;
     line-height: 130%;
    }
    a {
     text-decoration: none;
     color: ${({ theme }) => theme.colors.blue[500]};
    }
   }
   &-link {
    display: inline-flex;
    align-items: center;
    gap: 10px;
    margin: 20px 0 10px;
    background-color: ${({ theme }) => theme.colors.green[600]};
    padding: 5px 10px;
    border-radius: 8px;
    border: 1px solid ${({ theme }) => theme.colors.green[600]};
    transition: all 0.5s ease;
    cursor: pointer;
    p {
     color: ${({ theme }) => theme.colors.white};
     text-decoration: none;
     font-size: 14px;
     font-weight: 600;
    }
    i {
     color: ${({ theme }) => theme.colors.white};
    }
    &:hover {
     background-color: transparent;
     a,
     i {
      color: ${({ theme }) => theme.colors.green[600]};
     }
    }
   }
  }
 }
 &:hover {
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
 }
`;

interface TCardsStyled {
 numberColumn: any;
}

export const CardsStyled = styled.div<TCardsStyled>`
 display: grid;
 grid-template-columns: repeat(${({ numberColumn }) => numberColumn}, 1fr);
 gap: 20px;
 @media only screen and (${MaxScreenDevice.laptop}) {
  grid-template-columns: repeat(2, 1fr);
 }
 @media only screen and (${MaxScreenDevice.custom(600)}) {
  grid-template-columns: repeat(1, 1fr);
 }
`;
