import React from "react";
import { Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { BrandsData } from "data";
import { StyledBrands } from "assets/styles";
import Container from "common/Container";
import { NavLink } from "react-router-dom";

const BrandsComponent = () => {
 return (
  <Container>
   <StyledBrands>
    <Swiper
     modules={[Autoplay]}
     spaceBetween={50}
     loop={true}
     slidesPerView={5}
     autoplay={{
      delay: 5000,
      disableOnInteraction: false,
     }}
     className='mySwiper'
     breakpoints={{
      200: {
       slidesPerView: 1,
       spaceBetween: 20,
       centeredSlides: true,
      },
      688: {
       slidesPerView: 5,
      },
     }}
    >
     <div className='brands'>
      {BrandsData.map((item: { img: string; href: string }, index: number) => (
       <SwiperSlide key={index}>
        <NavLink to={item.href}>
         <img className='image-swiper' src={item.img} alt={`${index}`} />
        </NavLink>
       </SwiperSlide>
      ))}
     </div>
    </Swiper>
   </StyledBrands>
  </Container>
 );
};

export default BrandsComponent;
