import React from "react";

const Map = () => {
 return (
  <iframe
   src='https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3886.044052470454!2d109.3150516!3d13.0963947!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40af99a32c119627%3A0x33a2c30654994cea!2zQ8O0bmcgVHkgQ1AgxJDhuqd1IFTGsCBOxINuZyBMxrDhu6NuZyBEVCBTb2xhcg!5e0!3m2!1svi!2s!4v1702796215418!5m2!1svi!2s'
   width='100%'
   height='100%'
   style={{
    border: 0,
   }}
   loading='lazy'
   referrerPolicy='no-referrer-when-downgrade'
  ></iframe>
 );
};

export default Map;
