import { BannerStyled } from "assets/styles/_Banner.styled";
import { API_SERVER } from "helpers/variable";
import React from "react";
import Container from "./Container";
import { TypeBreadcrumb } from "./Breadcrumb";
import { NavLink } from "react-router-dom";

type TProps = {
 breadcrumb?: TypeBreadcrumb[];
 backgroundImage?: string;
};

const Banner = ({
 breadcrumb,
 backgroundImage = API_SERVER + "/banner.jpg",
}: TProps) => {
 return (
  <BannerStyled backgroundImage={backgroundImage}>
   <div className='content-banner'>
    <Container>
     <div className='breadcrumb'>
      <ul>
       {breadcrumb?.map((item: TypeBreadcrumb, index: number) => (
        <li key={index} className={`${item.href ? "navlink" : "active"}`}>
         {item.href ? (
          <div className='link'>
           <NavLink to={`${item?.href}`}>{item?.title}</NavLink>
           <i className='fa-solid fa-angle-right'></i>
          </div>
         ) : (
          item?.title
         )}
        </li>
       ))}
      </ul>
     </div>
    </Container>
   </div>
  </BannerStyled>
 );
};

export default Banner;
