import { MaxScreenDevice } from "assets/screens/DeviceScreens";
import styled from "styled-components";
export const ListProductsStyled = styled.div`
 padding: 100px 0;
 min-height: 80vh;
 h1 {
  margin: 0 0 50px 0;
  text-align: center;
 }
 .list-product {
  display: flex;
  align-items: start;
  gap: 20px;
  &__left {
   .search {
    margin-bottom: 30px;
    position: relative;
    input {
     width: calc(100% - 20px);
     height: 40px;
     outline: none;
     padding: 0 10px;
     border-radius: 20px;
     font-size: 15px;
     font-weight: 500px;
     color: ${({ theme }) => theme.colors.black};
     border: 1px solid ${({ theme }) => theme.colors.gray[400]};
     &::placeholder {
      font-size: 15px;
      font-weight: 500px;
     }
    }
    i {
     position: absolute;
     right: 10px;
     top: 50%;
     transform: translateY(-50%);
    }
   }
  }
  .list-product__cards {
   width: 75%;
  }
 }
 @media only screen and (${MaxScreenDevice.tablet}) {
  padding: 0 20px;
  margin-top: 100px;
  h1 {
   font-size: 25px;
   margin: 0 0 20px;
  }
  .list-product {
   flex-direction: column;
   &__left {
    width: 100%;
   }
   .list-product__cards {
    width: 100%;
   }
  }
 }
`;
