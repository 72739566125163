import styled from "styled-components";
export const ButtonFloatPhoneStyled = styled.div`
 .pulsate-bck {
  position: fixed;
  right: 23px;
  bottom: 100px;
  width: 60px;
  height: 60px;
  background-color: ${({ theme }) => theme.colors.main_green};
  -webkit-animation: pulsate-bck 1.5s ease-in-out infinite both;
  animation: pulsate-bck 1.5s ease-in-out infinite both;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  z-index: 999999999;
  .icon {
   font-size: 20px;
   path {
    fill: ${({ theme }) => theme.colors.main_yellow};
   }
  }
 }

 @-webkit-keyframes pulsate-bck {
  0% {
   -webkit-transform: scale(1);
   transform: scale(1);
  }
  50% {
   -webkit-transform: scale(0.9);
   transform: scale(0.9);
  }
  100% {
   -webkit-transform: scale(1);
   transform: scale(1);
  }
 }
 @keyframes pulsate-bck {
  0% {
   -webkit-transform: scale(1);
   transform: scale(1);
  }
  50% {
   -webkit-transform: scale(0.9);
   transform: scale(0.9);
  }
  100% {
   -webkit-transform: scale(1);
   transform: scale(1);
  }
 }
`;
export const ButtonFloatZaloStyled = styled.div`
 .pulsate-bck {
  position: fixed;
  right: 23px;
  bottom: 170px;
  width: 60px;
  height: 60px;
  -webkit-animation: pulsate-bck 1.5s ease-in-out infinite both;
  animation: pulsate-bck 1.5s ease-in-out infinite both;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  z-index: 999999999;

  .icon {
   font-size: 20px;
   path {
    fill: ${({ theme }) => theme.colors.main_yellow};
   }
  }
 }

 @-webkit-keyframes pulsate-bck {
  0% {
   -webkit-transform: scale(1);
   transform: scale(1);
  }
  50% {
   -webkit-transform: scale(0.9);
   transform: scale(0.9);
  }
  100% {
   -webkit-transform: scale(1);
   transform: scale(1);
  }
 }
 @keyframes pulsate-bck {
  0% {
   -webkit-transform: scale(1);
   transform: scale(1);
  }
  50% {
   -webkit-transform: scale(0.9);
   transform: scale(0.9);
  }
  100% {
   -webkit-transform: scale(1);
   transform: scale(1);
  }
 }
`;
