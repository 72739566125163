import infoCompany from "assets/json/Infomation";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
 information:
  "DTSolar - đối tác đáng tin cậy trong lĩnh vực năng lượng mặt trời! Chúng tôi tự hào là một trong những đơn vị hàng đầu cung cấp giải pháp tiết kiệm tối ưu về năng lượng mặt trời, mang đến cho khách hàng sự hiệu quả và bền vững.",
 contactInformation: {
  label: "Thông tin liên lạc",
  information: [
   {
    icon: `<i class="fa-solid fa-building"></i>`,
    label: null,
    content: infoCompany.nameCompany,
    href: "dtsolar.com.vn",
   },
   {
    icon: `<i class="fa-solid fa-phone"></i>`,
    label: "Số điện thoại: ",
    content: infoCompany.phoneNumber,
    href: `tel:+${infoCompany.phoneNumber}`,
   },
   {
    icon: `<i class="fa-solid fa-envelope"></i>`,
    label: "Email",
    content: infoCompany.email,
    href: `mailto:${infoCompany.email}`,
   },
   {
    icon: `<i class="fa-solid fa-map"></i>`,
    label: "Địa chỉ: ",
    content: infoCompany.address,
    href: "#",
   },
  ],
 },
 services: {
  label: "Sản phẩm",
  items: [
   {
    label: "Tổng vệ sinh sau xây dựng",
    href: "#",
   },
   {
    label: "Vệ sinh nhà ở, nhà cửa",
    href: "#",
   },
   {
    label: "Vệ sinh nhà xưởng",
    href: "#",
   },
   {
    label: "Vệ sinh công trình",
    href: "#",
   },
   {
    label: "Dịch vụ giặt thảm",
    href: "#",
   },
   {
    label: "Dịch vụ lau kính",
    href: "#",
   },
   {
    label: "Cung cấp tạp vụ",
    href: "#",
   },
   {
    label: "Giúp việc theo giờ",
    href: "#",
   },
   {
    label: "Vị sinh ống khói kếp",
    href: "#",
   },
  ],
 },
 solution: {
  label: "giải pháp",
  items: [
   {
    label: "Giải pháp 1",
    href: "#",
   },
   {
    label: "Giải pháp 2",
    href: "#",
   },
   {
    label: "Giải pháp 3",
    href: "#",
   },
   {
    label: "Giải pháp 4",
    href: "#",
   },
   {
    label: "Giải pháp 5",
    href: "#",
   },
   {
    label: "Giải pháp 6",
    href: "#",
   },
   {
    label: "Giải pháp 7",
    href: "#",
   },
   {
    label: "Giải pháp 8",
    href: "#",
   },
   {
    label: "Giải pháp 9",
    href: "#",
   },
  ],
 },
 copyright: {
  year: 2023,
  icon: '<i class="fa-regular fa-copyright"></i>',
  label: "Công ty ....",
 },
 hotline: {
  label: "Hotline tư vấn",
  phoneNumber: "0559352509",
  href: "#",
 },
};
