import { MaxScreenDevice } from "assets/screens/DeviceScreens";
import styled from "styled-components";

export const StyledSearchInput = styled.div`
 color: ${({ theme }) => theme.colors.green[400]};
 transition: color 0.4s ease;
 cursor: pointer;
 position: relative;
 height: 40px;
 input {
  height: 100%;
  width: 200px;
  outline: none;
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.colors.gray[300]};
  padding: 0 10px;
 }
 i {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  height: 100%;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-left: 1px solid ${({ theme }) => theme.colors.gray[300]};
 }
 /* &:hover {
     color: ${({ theme }) => theme.colors.green[700]};
    } */
 @media only screen and (${MaxScreenDevice.laptop}) {
  display: none;
 }
`;
