import Container from "common/Container";
import React from "react";
import SolutionSession from "../SolutionSesstion";
import { useNavigate } from "react-router-dom";
import { Form, Modal } from "antd";
import ContactModal from "components/Home/common/ContactModal";

const Solution = () => {
 const [visibleContact, setVisibleContact] = React.useState<boolean>(false);
 return (
  <section>
   <ContactModal setCloseModal={setVisibleContact} visible={visibleContact} />
   <SolutionSession
    title='GIẢI PHÁP CHO HỘ GIA ĐÌNH'
    description={`<p><span style="font-size: 18px">Hệ thống điện năng lượng mặt trời hòa lưới cho hộ gia đình có tấm pin hấp thu ánh sáng mặt trời chuyển hóa thành dòng điện 1 chiều; dòng điện này sẽ được bộ inverter chuyển thành nguồn điện sạch để cấp cho các thiết bị điện, giúp nhiều gia đình Việt tiết kiệm đáng kể chi phí tiền điện hằng tháng.</span><br></p><p style="text-align: justify"><span style="font-size: 18px">Giải pháp không chỉ mang đến nguồn năng lượng sạch thân thiện với môi trường mà còn tạo ra hiệu quả kinh tế cao cho gia đình.</span></p><p style="text-align: justify"><span style="font-size: 18px">Hệ thống điện mặt trời không chiếm quá nhiều diện tích nên chỉ cần một khoảng không gian nhỏ trên mái nhà hoặc sân thượng (không bị che bóng) là đã có thể lắp đặt, an tâm hưởng nhiều lợi ích thiết thực từ nguồn năng lượng xanh.</span></p>`}
    row='row'
    onHandleButton={() => setVisibleContact(true)}
    image='/images/img_about_us.jpg'
   />
  </section>
 );
};

export default Solution;
