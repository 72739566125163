import React from "react";
import { TSolutionPackageAdmin } from "./type";
import { Button, Flex, Form, Modal, Tooltip, message } from "antd";
import {
 EditOutlined,
 UnlockOutlined,
 LockOutlined,
 DeleteOutlined,
} from "@ant-design/icons";
import { deleteSolutionPackage, updateSolutionPackage } from "./query";
import { useQueryClient } from "react-query";
import { QUERY_SOLUTION_PACKAGE_ADMIN } from "helpers";
import FormContent from "./FormContent";
interface TProps {
 record: TSolutionPackageAdmin;
}

export interface TValueUpdateSolutionPackage {
 performance?: string;
 numberOfPanels?: string;
 electricityGenerated?: string;
 savingMoney?: string;
 active?: number;
}

const Action = ({ record }: TProps) => {
 const [formUpdateSolutionPackage] = Form.useForm();
 const queryClient = useQueryClient();

 const [visibleModal, setVisibleModal] = React.useState<boolean>(false);
 const [isLoadingConfirm, setIsLoadingConfirm] = React.useState<boolean>(false);
 const handleSubmitModal = () => {
  setIsLoadingConfirm(true);
  formUpdateSolutionPackage.submit();
 };
 const handleOpenModal = () => {
  setVisibleModal(true);
  formUpdateSolutionPackage.setFieldsValue(record);
 };
 const handleCloseModal = () => {
  setVisibleModal(false);
  queryClient.invalidateQueries(QUERY_SOLUTION_PACKAGE_ADMIN);
  formUpdateSolutionPackage.resetFields();
 };

 const handleActiveUpdate = (active: number) => {
  onSubmit({ active });
 };

 const handleDelete = async (id: string) => {
  const response: any = await deleteSolutionPackage(id);
  if (response?.type === "error") return message.error("Xóa dữ liệu thất bại");
  queryClient.invalidateQueries(QUERY_SOLUTION_PACKAGE_ADMIN);
  message.success("Xóa dữ liệu thành công");
 };

 const onSubmit = React.useCallback(
  async (values: TValueUpdateSolutionPackage) => {
   console.log(record.id, values);
   const response: any = await updateSolutionPackage(record.id, values);
   setIsLoadingConfirm(false);
   if (response?.type === "error") return message.error("Cập nhật thất bại");
   handleCloseModal();
   message.success("Cập nhật thành công");
  },
  [formUpdateSolutionPackage, handleCloseModal]
 );

 return (
  <>
   <Modal
    open={visibleModal}
    title='CẬP NHẬT GÓI GIẢI PHÁP'
    onOk={handleSubmitModal}
    onCancel={handleCloseModal}
    confirmLoading={isLoadingConfirm}
    okText='Cập nhật'
    cancelText='Hủy'
   >
    <FormContent
     form={formUpdateSolutionPackage}
     onSubmit={onSubmit}
     type='update'
     defaultSavingMoney={record.savingMoney}
    />
   </Modal>

   <Flex gap='small'>
    <Tooltip title='Chỉnh sửa'>
     <Button
      onClick={handleOpenModal}
      type='dashed'
      shape='circle'
      icon={<EditOutlined />}
     />
    </Tooltip>
    {record.active === 0 ? (
     <Tooltip
      title='Mở
      khóa'
     >
      <Button
       onClick={() => handleActiveUpdate(1)}
       type='primary'
       shape='circle'
       icon={<UnlockOutlined />}
      />
     </Tooltip>
    ) : (
     <Tooltip title='Khóa'>
      <Button
       onClick={() => handleActiveUpdate(0)}
       type='primary'
       danger
       shape='circle'
       icon={<LockOutlined />}
      />
     </Tooltip>
    )}
    <Tooltip title='Xóa dữ liệu'>
     <Button
      onClick={() => handleDelete(record.id)}
      type='primary'
      danger
      shape='circle'
      icon={<DeleteOutlined />}
     />
    </Tooltip>
   </Flex>
  </>
 );
};

export default Action;
