import { ContactPageStyled } from "assets/styles/page";
import TextEditorTest from "common/TextEditTest";
import TextEditor from "common/TextEditor";
import { ClientComponent } from "components/Home";
import { HomeComponent } from "components/Home/HomeComponent";
import { ServicePage } from "components/Home/Pages";
import { AboutUsPage } from "components/Home/Pages/AboutUs";
import { OrderConfirmPage } from "components/Home/Pages/ConfirmOrder";
import { EnterprisePage } from "components/Home/Pages/Enterprise";
import { Household } from "components/Home/Pages/Household";
import { PartnerPage } from "components/Home/Pages/Partner";
import { ProjectPage } from "components/Home/Pages/Projects";
import { SolutionPage } from "components/Home/Pages/Solution";
import { ContactPage } from "components/Home/Pages/contact";
import ContactComponent from "components/Home/Pages/contact/Component";
import { DetailProduct } from "components/Home/Pages/detailProduct";
import { ListProducts } from "components/Home/Pages/listProducts";
import { NewsPages } from "components/Home/Pages/news";
import SearchAll from "components/Home/Pages/search/SearchAll";
import ConfirmOrder from "components/admin/Content/order/ConfirmOrder";

import { TRoutes } from "types";

const ClientRoute: TRoutes = {
 layout: "client",
 component: <ClientComponent />,
 path: "",
 views: [
  {
   name: "home",
   path: "/",
   component: <HomeComponent />,
  },

  {
   name: "contact",
   path: "/lien-he",
   component: (
    <ContactPageStyled>
     <ContactComponent />
    </ContactPageStyled>
   ),
  },
  {
   name: "list-product",
   path: "san-pham",
   component: <ListProducts />,
  },
  {
   name: "detail-product",
   path: "san-pham/:slug",
   component: <DetailProduct />,
  },
  {
   name: "tin-tuc",
   path: "tin-tuc",
   component: <NewsPages />,
  },
  {
   name: "tin-tuc",
   path: "tin-tuc/:slug",
   component: <ServicePage />,
  },
  {
   name: "about-us",
   path: "gioi-thieu",
   component: <AboutUsPage />,
  },
  // {
  //  name: "doi-tac",
  //  path: "doi-tac",
  //  component: <PartnerPage />,
  // },
  //
  {
   name: "doanh-nghiep",
   path: "doanh-nghiep",
   component: <EnterprisePage />,
  },
  {
   name: "ho-gia-dinh",
   path: "ho-gia-dinh",
   component: <Household />,
  },
  {
   name: "du-an",
   path: "du-an",
   component: <ProjectPage />,
  },
  {
   name: "giai-phap",
   path: "giai-phap/:slug",
   component: <SolutionPage />,
  },
  // {
  //  name: "confirm",
  //  path: "xac-nhan-don-hang",
  //  component: <OrderConfirmPage />,
  // },
  {
   name: "search-result",
   path: "ket-qua-tim-kiem/:slug",
   component: <SearchAll />,
  },
  {
   name: "search-result",
   path: "123",
   component: <TextEditorTest />,
  },

  // {
  //  name: "service",
  //  path: ":slug",
  //  component: <ServicePage />,
  // },
  // {
  //  name: "service",
  //  path: ":parentSlug/:childrenSlug",
  //  component: <ServicePage />,
  // },
 ],
};
export default ClientRoute;
