import { TCarousel } from "@types";
import React from "react";
import { LockOutlined } from "@ant-design/icons";
import { Button, Flex, message } from "antd";
import { remove, update } from "./fetch";
import { QUERY_CAROUSEL_ADMIN } from "helpers";
import { useQueryClient } from "react-query";
import RemoveButton from "components/admin/common/RemoveButton";
type TProps = {
 record: TCarousel;
};

const Action = ({ record }: TProps) => {
 const queryClient = useQueryClient();

 const handleRemoveCarousel = (id: string) => {
  remove(id);
  queryClient.invalidateQueries(QUERY_CAROUSEL_ADMIN);
  message.success("Xóa dữ liệu thành công");
 };
 const handleUpdateStatus = (id: string) => {
  update(id, { active: false });
  queryClient.invalidateQueries(QUERY_CAROUSEL_ADMIN);
  message.success("Cập nhật dữ liệu thành công");
 };

 return (
  <Flex gap={10}>
   {record.active && (
    <Button
     type='dashed'
     icon={<LockOutlined />}
     onClick={() => handleUpdateStatus(record.id)}
    />
   )}
   <RemoveButton
    onClick={() => handleRemoveCarousel(record.id)}
    id={record.id}
   />
  </Flex>
 );
};

export default Action;
