import React from "react";
import { TCartItemsAdmin } from "./Action";
import { Flex, Form, Image, Input, InputNumber } from "antd";
import { API_SERVER } from "helpers/variable";
import CurrencyInput from "react-currency-input-field";

type TProps = {
 form: any;
 onFinish: () => void;
 dataProduct: TCartItemsAdmin[];
 onChangePriceProduct: (price: any, index: number, quantity: number) => void;
 setDiscount: (discount: any, index: number) => void;
};

const FormPriceRP = ({
 dataProduct,
 form,
 onChangePriceProduct,
 onFinish,
 setDiscount,
}: TProps) => {
 return (
  <Form
   form={form}
   layout='vertical'
   onFinish={onFinish}
   style={{
    padding: "20px 0",
   }}
  >
   {dataProduct.map((product: TCartItemsAdmin, index: number) => {
    return (
     <Flex gap={20} justify='start'>
      <Image width={200} src={API_SERVER + "/" + product.productImages[0]} />
      <div className='info'>
       <h3>{product.productName}</h3>
       <p>
        <strong>Mã sản phẩm: </strong>
        {product.productCode}
       </p>
       <p>
        <strong>Loại sản phẩm: </strong>
        {product.categoryTitle}
       </p>
       <p>
        <strong>Số lượng: </strong>
        {product.cartQuantity}
       </p>
       <Flex
        align='start'
        gap={10}
        style={{
         height: 100,
        }}
       >
        <Form.Item
         label='Giá sản phẩm / 1SP'
         name='productPrice'
         required
         rules={[
          {
           required: true,
           message: "Vui lòng nhập giá sản phẩm",
          },
         ]}
        >
         <Flex align='center' gap={5}>
          <CurrencyInput
           className='input-price'
           intlConfig={{ locale: "vn", currency: "VND" }}
           groupSeparator='.'
           defaultValue={product.priceTotal / product.cartQuantity}
           onValueChange={(value: any) => {
            onChangePriceProduct(value, index, product.cartQuantity);
           }}
          />
         </Flex>
        </Form.Item>
        <Form.Item
         label='Giảm giá'
         name='discount'
         rules={[
          {
           type: "number",
           max: 100,
           min: 0,
           message: "Định dạng không đúng, tối đa 100 và tối thiểu 0",
           transform(value) {
            return Number(value);
           },
          },
         ]}
        >
         <Flex align='center' gap={5}>
          <InputNumber
           min={0}
           max={100}
           defaultValue={product.discount}
           onChange={(value) => setDiscount(value, index)}
          />
          %
         </Flex>
        </Form.Item>
       </Flex>
      </div>
     </Flex>
    );
   })}
  </Form>
 );
};

export default FormPriceRP;
