import { ContainerStyled } from "assets/styles";
import React from "react";

interface PropsContainer {
 children: React.ReactNode;
}

const Container = ({ children }: PropsContainer) => {
 return <ContainerStyled>{children}</ContainerStyled>;
};

export default Container;
