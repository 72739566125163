import React from "react";
import { useNavigate } from "react-router-dom";

const SearchInput = ({ visibleDrawer }: { visibleDrawer: Function }) => {
 const [valueSearch, setValueSearch] = React.useState<string>("");
 const navigate = useNavigate();
 const handleSearch = (value: string) => {
  navigate(`/ket-qua-tim-kiem/${value}`);
  visibleDrawer(false);
 };
 const handleKeyDown = (event: any) => {
  if (event.key === "Enter") {
   handleSearch(valueSearch);
  }
 };
 return (
  <div className='search-icon'>
   <input
    onChange={(value) => setValueSearch(value.target.value)}
    onKeyDown={handleKeyDown}
    type='text'
    placeholder='Tìm kiếm ...'
   />
   <i className='fa-solid fa-magnifying-glass' />
  </div>
 );
};

export default SearchInput;
