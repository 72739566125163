import styled from "styled-components";

export const ServiceUsStyled = styled.div`
 width: 100%;
 margin-top: 50px;
 .title {
  background-color: ${({ theme }) => theme.colors.blue[800]};
  border-radius: 5px;
  margin: 0 0 10px 0;
  padding: 10px 15px;
  display: flex;
  align-items: center;
  gap: 10px;
  i {
   color: ${({ theme }) => theme.colors.white};
  }
  h2 {
   font-size: 16px;
   font-weight: 500;
   color: ${({ theme }) => theme.colors.white};
  }
 }
 .content {
  border-radius: 5px;
  background-color: ${({ theme }) => theme.colors.green[800]};
  .service-list {
   display: flex;
   flex-direction: column;
   .service-list__item {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px 15px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray[200]};
    cursor: pointer;
    transition: all 0.4s ease;
    i {
     color: ${({ theme }) => theme.colors.white};
    }
    a {
     color: ${({ theme }) => theme.colors.white};
     text-decoration: none;
     text-transform: capitalize;
    }
    &:hover {
     background-color: ${({ theme }) => theme.colors.green[900]};
    }
   }
  }
 }
`;
