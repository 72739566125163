import { ProcessStyled } from "assets/styles/V2/_Process.styled";
import React from "react";
import Container from "./Container";
import { Pagination, Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
const Process = () => {
 const swiperRef = React.useRef<any>(null);
 const CustomNavigation = () => (
  <div className='navigation-custom'>
   <button
    onClick={() => {
     swiperRef.current.slidePrev();
    }}
   >
    <i className='fa-solid fa-angle-left'></i>
   </button>
   <button
    onClick={() => {
     swiperRef.current.slideNext();
    }}
   >
    <i className='fa-solid fa-angle-right'></i>
   </button>
  </div>
 );
 return (
  <ProcessStyled>
   <Container>
    <div className='process__heading'>
     <h2>Quy trình thực hiện</h2>
    </div>
    <div className='process__carousel'>
     <Swiper
      modules={[Pagination, Autoplay]}
      autoplay={{
       delay: 3000,
       disableOnInteraction: false,
      }}
      loop={true}
      spaceBetween={50}
      slidesPerView={3}
      onSwiper={(swiper) => {
       swiperRef.current = swiper;
      }}
      className='process-slide'
      breakpoints={{
       200: {
        slidesPerView: 1,
        spaceBetween: 20,
        centeredSlides: true,
       },
       688: {
        slidesPerView: 3,
       },
      }}
     >
      <SwiperSlide>
       <div className='process__carousel-item'>
        <div className='triangle'></div>
        <div className='step'>
         <h3>01</h3>
        </div>
        <div className='image'>
         <img src='images/process-1.jpg' alt='process-1' />
        </div>
        <div className='content'>
         <div className='title'>
          <h3>TƯ VẤN</h3>
         </div>
         <div className='desc'>
          <p>
           Giới thiệu các giải pháp, thiết bị và công nghệ. Tư vấn giải pháp
           hiệu quả nhất với nhu cầu và điều kiện của khách hàng.
          </p>
          <p>
           Chủ đầu tư sẽ biết rõ công suất tối đa có thể lắp đặt và sản lượng dự
           kiến mà hệ thống mang lại, đồng thời có những dữ liệu cụ thể cho bài
           toán đầu tư.
          </p>
         </div>
        </div>
       </div>
      </SwiperSlide>
      <SwiperSlide>
       <div className='process__carousel-item'>
        <div className='triangle'></div>
        <div className='step'>
         <h3>02</h3>
        </div>
        <div className='image'>
         <img src='images/process-2.jpg' alt='process-2' />
        </div>
        <div className='content'>
         <div className='title'>
          <h3>KHẢO SÁT & THIẾT KẾ</h3>
         </div>
         <div className='desc'>
          <p>
           Các chuyên gia tiến hành khảo sát thực địa, phân tích điều kiện ánh
           sáng, bóng phủ, diện tích và góc nghiêng để có thể tối ưu hóa hệ
           thống.
          </p>
          <p>
           Kết quả khảo sát được các kỹ sư lên cấu hình, thiết kế hệ thống, đồng
           thời tính toán điểm hoàn vốn và phân tích tài chính.
          </p>
         </div>
        </div>
       </div>
      </SwiperSlide>
      <SwiperSlide>
       <div className='process__carousel-item'>
        <div className='triangle'></div>
        <div className='step'>
         <h3>03</h3>
        </div>
        <div className='image'>
         <img src='images/process-3.jpg' alt='process-3' />
        </div>
        <div className='content'>
         <div className='title'>
          <h3>THI CÔNG</h3>
         </div>
         <div className='desc'>
          <p>
           Đội ngũ thi công của DTSolar trực tiếp triển khai lắp đặt, đảm bảo
           tính nhất quán với bản thiết kế.
          </p>
          <p>
           Bộ phận quản lý dự án kiểm tra, giám sát thi công, theo dõi tiến độ
           dự án và cùng chủ đầu tư nghiệm thu hệ thống.
          </p>
         </div>
        </div>
       </div>
      </SwiperSlide>
      <SwiperSlide>
       <div className='process__carousel-item'>
        <div className='triangle'></div>
        <div className='step'>
         <h3>04</h3>
        </div>
        <div className='image'>
         <img src='images/process-4.jpg' alt='process-4' />
        </div>
        <div className='content'>
         <div className='title'>
          <h3>BẢO TRÌ HỆ THỐNG</h3>
         </div>
         <div className='desc'>
          <p>
           DTSolar hướng dẫn vận hành, chuyển giao công nghệ, giúp khách hàng
           làm chủ hệ thống.
          </p>
          <p>
           Đội ngũ kỹ sư DTSolar hỗ trợ theo dõi, giám sát hệ thống cùng khách
           hàng. Cam kết bảo hành thiết bị, hiệu suất và hỗ trợ kỹ thuật lâu
           dài.
          </p>
         </div>
        </div>
       </div>
      </SwiperSlide>

      <CustomNavigation />
     </Swiper>
    </div>
   </Container>
  </ProcessStyled>
 );
};

export default Process;
