import { MaxScreenDevice } from "assets/screens/DeviceScreens";
import { update } from "./../../../components/admin/Content/UIManage/carouselComponent/fetch";
import styled from "styled-components";
type TProps = {
 backgroundImage: string;
};
export const ProjectStyled = styled.div<TProps>`
 background-image: linear-gradient(
  to right,
  ${({ theme }) => theme.colors.green[100]} 5%,
  ${({ theme }) => theme.colors.green[300]}
 );
 z-index: -1;
 padding: 50px 0;
 .navigation-custom {
  position: absolute;
  display: flex;
  align-items: center;

  justify-content: space-between;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);

  transition: padding 0.3s ease;
  z-index: 9999;
  button {
   background-color: transparent;
   border: none;
   width: 40px;
   height: 40px;
   border: 1px solid ${({ theme }) => theme.colors.black};
   border-radius: 50%;
   cursor: pointer;
   transition: all 0.4s ease;
   i {
    color: ${({ theme }) => theme.colors.black};
    font-size: 20px;
   }
   &:hover {
    border-color: ${({ theme }) => theme.colors.blue[900]};
    background-color: ${({ theme }) => theme.colors.blue[900]};
   }
  }
 }
 .project {
  &__heading {
   padding: 0 20px;
   text-align: center;
   color: ${({ theme }) => theme.colors.black};
   h2 {
    font-size: 30px;
    line-height: 160%;
   }
  }
  &__item {
   position: relative;
   .bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #52525b66;

    z-index: 1;
   }
   &.swiper-slide-active {
    .project__item-content {
     transform: translateY(-40px);
    }
   }
   &-content {
    padding-top: 100px;
    opacity: 1;
    z-index: 2;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    transform: translateY(100%);
    /* transform: translateX(-50%); */
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${({ theme }) => theme.colors.white};
    transition: transform 1s ease;
    .content {
     width: calc(50vw / 2);
    }
    .title {
     h4 {
      line-height: 160%;
      font-size: 19px;
      font-weight: 600;
      text-transform: uppercase;
      text-align: center;
     }
    }
    .name {
     margin: 20px 0;
     h3 {
      font-size: 35px;
      font-weight: 700;
      text-transform: uppercase;
      text-align: center;
     }
    }
    .info {
     padding: 0 20px;
     display: flex;
     align-items: center;
     justify-content: space-between;
     .item {
      display: flex;
      align-items: center;
      gap: 10px;
      .icon {
       font-size: 30px;
      }
      strong {
       font-size: 18px;
       font-weight: 500;
       text-transform: uppercase;
      }
      p {
       text-align: center;
       font-size: 40px;

       font-weight: 700;
      }
     }
    }
   }
  }
 }
 .swiper {
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
 }

 .swiper-slide {
  background-position: center;
  background-size: cover;
  img {
   width: 100%;
   height: 100%;
  }
 }

 .swiper-slide img {
  display: block;
  width: 100%;
 }
 @media only screen and (${MaxScreenDevice.tablet}) {
  padding: 50px 0 0;
  .project {
   &__heading {
    margin-bottom: 10px;
    h2 {
     font-size: 20px;
    }
    p {
     font-size: 15px;
    }
   }
   &__item {
    width: 100%;
    &-content {
     padding-top: 50px;
     transform: translateY(100%);
     /* transform: translateX(-50%); */
     display: flex;
     align-items: center;
     justify-content: center;
     color: ${({ theme }) => theme.colors.white};
     transition: transform 1s ease;
     .content {
      width: 100%;
     }
     .title {
      h4 {
       font-size: 14px;
       font-weight: 500;
      }
     }
     .name {
      margin: 20px 0;
      h3 {
       font-size: 20px;
       font-weight: 600;
      }
     }
     .info {
      padding: 0 20px;
      .item {
       display: flex;
       align-items: center;
       gap: 10px;
       .icon {
        font-size: 20px;
       }
       strong {
        font-size: 16px;
        font-weight: 500;
        text-transform: uppercase;
       }
       p {
        text-align: center;
        font-size: 20px;

        font-weight: 700;
       }
      }
     }
    }
   }
  }
 }
`;
