import { THeaderDataV2 } from "@types";
import _ from "lodash";

export const FormatDataHeader = (dataHeader: THeaderDataV2[]) => {
 const newNavbar = _.filter(dataHeader, { idParent: null });
 return newNavbar.map((item: THeaderDataV2) => ({
  ...item,
  subMenu: _.filter(dataHeader, { idParent: item.id }),
 }));
};
