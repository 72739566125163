import { Button, Form, Modal, Row, Tag, message } from "antd";
import { TDetailProduct } from "./Component";
import parse from "html-react-parser";
import { InfoDetailProductStyled } from "assets/styles/page";
import { NavLink, useNavigate } from "react-router-dom";
import React from "react";
import { TProduct } from "@types";
import ModalContent from "./ModalContent";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "store";
import { addToCart } from "features/slices/Cart";

interface Props {
 product: TProduct;
}
const InformationProduct = ({ product }: Props) => {
 const [visibleModal, setVisibleModal] = React.useState<boolean>(false);
 const [isLoading, setIsLoading] = React.useState<boolean>(false);
 const [valueQuantity, setValueQuantity] = React.useState<string>();
 const { cartItems } = useSelector((state: RootState) => state.cart);
 const [formSendInfo] = Form.useForm();
 const dispatch = useDispatch<AppDispatch>();
 const navigate = useNavigate();

 const handleInfoSend = () => {
  if (cartItems.length === 0) {
   message.warning("Vui lòng chọn sản phẩm trước khi gửi yêu cầu báo giá");
  } else {
   formSendInfo.submit();
  }
 };

 const handleOpenModal = (product: any) => {
  setVisibleModal(true);
  dispatch(addToCart(product));
 };
 const scrollToTop = () => {
  window.scrollTo({
   top: 0,
   left: 0,
   behavior: "smooth",
  });
 };

 React.useEffect(() => {
  scrollToTop();
 }, []);
 return (
  <>
   <Modal
    open={visibleModal}
    title='TƯ VẤN & BÁO GIÁ'
    width={"60%"}
    className='modal-mobile'
    // className='modal-contact'
    onCancel={() => setVisibleModal(false)}
    footer={[
     <Row justify={"end"}>
      <Button
       loading={isLoading}
       type='dashed'
       onClick={() => navigate("/san-pham")}
      >
       XEM THÊM SẢN PHẨM
      </Button>
      <Button loading={isLoading} type='primary' onClick={handleInfoSend}>
       Gửi thông tin báo giá
      </Button>
     </Row>,
    ]}
   >
    <div style={{ padding: "40px 0" }}>
     <ModalContent
      form={formSendInfo}
      setVisibleModal={setVisibleModal}
      setIsLoading={setIsLoading}
     />
    </div>
   </Modal>
   <InfoDetailProductStyled>
    <div className='detail-product__info'>
     <div className='detail-product__info-title'>
      <h1>{product.productName}</h1>
     </div>
     <div className='detail-product__info-code'>
      <strong>Mã sản phẩm:</strong>
      <p>{product.productCode}</p>
     </div>
     <div className='detail-product__info-trademark-type'>
      <p>
       {product.productTrademark && (
        <>
         <span>
          <strong>Thương hiệu: </strong>
          {product.productTrademark}
         </span>
         |
        </>
       )}
       <span>
        <strong>Loại: </strong>
        {product.categoryTitle}
       </span>
      </p>
     </div>
     <div className='detail-product__info-category'>
      <strong>THÔNG SỐ KỸ THUẬT</strong>
      <div
       className='sun-editor-editable'
       style={{
        paddingLeft: 20,
       }}
       dangerouslySetInnerHTML={{ __html: product.productParameter }}
      />
     </div>
    </div>
    <div className='detail-product__bottom'>
     <button onClick={() => handleOpenModal(product)}>
      <i className='fa-solid fa-cart-shopping' /> TƯ VẤN VÀ BÁO GIÁ
     </button>
     {/* <button className='caculator'>DỰ TOÁN HỆ THỐNG</button> */}
    </div>
   </InfoDetailProductStyled>
  </>
 );
};

export default InformationProduct;
