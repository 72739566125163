import Container from "common/Container";
import React from "react";
import { useParams } from "react-router-dom";
import _ from "lodash";
import NewPostComponent from "./NewPost.component";
import { SolutionStyled } from "assets/styles/HomeStyled/_Solution";
import NotFoundCommon from "common/NotFound";
import { TNews } from "@types";
import { useSelector } from "react-redux";
import { RootState } from "store";
import AboutUsComponent from "../HomeComponent/AboutUs.component";
import ContactComponent from "../HomeComponent/Contact.component";

const Component = () => {
 const [html, setHtml] = React.useState<string | undefined>(undefined);
 const { slug } = useParams();
 const { news, isLoading } = useSelector((state: RootState) => state.news);
 const [notFount, setNotFount] = React.useState<boolean>(false);

 const getDataSolutionContent = (news: TNews[]) => {
  const result = _.find(news, { href: slug });
  if (result) {
   setHtml(result.html);
  } else {
   setNotFount(true);
  }
 };

 const scrollToTop = () => {
  window.scrollTo({
   top: 0,
   left: 0,
   behavior: "smooth",
  });
 };

 React.useEffect(() => {
  scrollToTop();
 }, [slug]);

 React.useEffect(() => {
  if (!isLoading && news) {
   getDataSolutionContent(news);
  }
 }, [isLoading, news, slug]);

 return (
  <SolutionStyled>
   <Container>
    {notFount ? (
     <NotFoundCommon />
    ) : (
     <>
      {html && (
       <>
        <div className='solution'>
         <div
          className='sun-editor-editable solution__content'
          dangerouslySetInnerHTML={{ __html: html }}
         />
         <div className='solution__list-news'>
          <NewPostComponent />
         </div>
        </div>
        <AboutUsComponent href={"/gioi-thieu"} buttonText='XEM CHI TIẾT' />
        <ContactComponent />
       </>
      )}
     </>
    )}
   </Container>
  </SolutionStyled>
 );
};

export default Component;
