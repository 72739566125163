import React from "react";
import SolutionSession from "../SolutionSesstion";
import { SystemEstimationStyled } from "assets/styles/V2/HouseHold/SystemEstimation";
import { Button, Flex, Form, Input, Modal, Steps, message, theme } from "antd";
import CurrencyInput from "react-currency-input-field";
import ContactModal from "components/Home/common/ContactModal";
import FormInformation from "./FormInformation";
import Estimation, { TResultEstimationState } from "./Estimation";
import moment from "moment";
import { postQueryHelper } from "helpers";

type TFrom = {
 electricityAmount: number;
};

type TInformation = {
 name: string;
 phoneNumber: string;
};
export interface TInformationEstimation extends TInformation {
 address: {
  phuong_xa: string;
  quan_huyen: string;
  tinhTP: string;
 };
}

export interface TResultEstimation {
 title: string;
 customerInformation: string;
 message: string;
 time: string;
}

const SystemEstimation = () => {
 const [visibleModal, setVisibleModal] = React.useState<boolean>(false);
 const [valueElectricityAmount, setValueElectricityAmount] =
  React.useState<number>(0);
 const [form] = Form.useForm();
 const [formInformation] = Form.useForm();
 const { token } = theme.useToken();
 const [current, setCurrent] = React.useState<number>(0);
 const [information, setInformation] = React.useState<TInformationEstimation>();

 const [resultEstimation, setResultEstimation] =
  React.useState<TResultEstimationState>();
 const next = () => {
  formInformation.submit();
 };

 const sendInformation = React.useCallback((values: TInformationEstimation) => {
  setCurrent((prev: number) => prev + 1);
  setInformation(values);
 }, []);

 const steps = [
  {
   title: "Thông tin",
   content: <FormInformation form={formInformation} onNext={sendInformation} />,
  },
  {
   title: "Kết quả",
   content: (
    <Estimation
     resultEstimation={setResultEstimation}
     averageElectricity={valueElectricityAmount}
    />
   ),
  },
 ];

 const items = steps.map((item) => ({ key: item.title, title: item.title }));

 const contentStyle: React.CSSProperties = {
  lineHeight: "260px",
  color: token.colorTextTertiary,
  marginTop: 16,
 };

 const handleFinishEstimation = async () => {
  const customerInformation = {
   "Địa chỉ": `${information?.address.phuong_xa}, ${information?.address.quan_huyen}, ${information?.address.tinhTP}`,
   "Họ tên": information?.name,
   "Số điện thoại": information?.phoneNumber,
  };
  const message = {
   "Công suất hệ thống": resultEstimation?.wattage,
   "Diện tích lắp đặt": resultEstimation?.acreage,
   "Số lượng tấm pin": resultEstimation?.batteryPanels,
   "Sản lượng điện": resultEstimation?.electricityOutput.toFixed(3),
   "Chi phí tiết kiệm": resultEstimation?.annualCosts.toLocaleString("vi-VN"),
  };
  const time = moment().format("DD/MM/YYYY HH:mm:ss");
  const parameters: TResultEstimation = {
   title: "Dự toán hệ thống",
   customerInformation: JSON.stringify(customerInformation),
   message: JSON.stringify(message),
   time,
  };
  await postQueryHelper("/message/send-information-to-server", parameters);
  setCurrent(0);
  form.resetFields();
  setVisibleModal(false);
 };

 const onFinishEstimation = (values: TFrom) => {
  setVisibleModal(true);
 };

 return (
  <SystemEstimationStyled>
   <Modal
    title='DỰ TOÁN HỆ THỐNG HÒA LƯỚI'
    open={visibleModal}
    onCancel={() => setVisibleModal(false)}
    footer={null}
    width={"60%"}
    className='modal-mobile'
   >
    <Steps current={current} items={items} />
    <div style={contentStyle}>{steps[current].content}</div>
    <div style={{ marginTop: 24 }}>
     {current < steps.length - 1 && (
      <Button type='primary' onClick={() => next()}>
       Xem kết quả
      </Button>
     )}
     {current === steps.length - 1 && (
      <Button type='primary' onClick={handleFinishEstimation}>
       Hoàn thành
      </Button>
     )}
    </div>
   </Modal>
   <SolutionSession
    image='images/HOGIADINH-1.jpg'
    onHandleButton={() => {
     formInformation.resetFields();
     form.submit();
    }}
    row='row-reverse'
    background={false}
    buttonText='XEM KẾT QUẢ'
   >
    <div className='system-estimation'>
     <div className='system-estimation__heading'>
      <h3>DỰ TOÁN HỆ THỐNG HÒA LƯỚI</h3>
     </div>
     <div className='system-estimation__input'>
      <Form form={form} layout='vertical' onFinish={onFinishEstimation}>
       <Form.Item
        label='Số tiền điện trung bình mỗi tháng'
        name='electricityAmount'
        className='form-input'
        required
        rules={[
         {
          required: true,
          message: "Vui lòng điền số điện trung bình mỗi tháng!",
         },
        ]}
       >
        <Flex align='center' gap={5}>
         <CurrencyInput
          style={{
           padding: "0 20px",
          }}
          className='input-price'
          intlConfig={{ locale: "vn", currency: "VND" }}
          groupSeparator='.'
          onValueChange={(value: any) => {
           setValueElectricityAmount(Number(value));
          }}
         />
        </Flex>
       </Form.Item>
      </Form>
     </div>
    </div>
   </SolutionSession>
  </SystemEstimationStyled>
 );
};

export default SystemEstimation;
