import { MaxScreenDevice } from "assets/screens/DeviceScreens";
import styled from "styled-components";
export const BenefitEnterpriseStyled = styled.div`
 margin: 100px 0;
 .benefit {
  &__heading {
   h2 {
    font-size: 35px;
    text-align: center;
   }
  }
  &__items {
   display: flex;
   justify-content: space-between;
   flex-wrap: wrap;
   gap: 50px;
   margin: 100px 0 0 0;
   &-item {
    width: calc(50% - 50px);
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 40px;
    img {
     width: 60px;
     height: 60px;
     object-fit: contain;
    }
    .content {
     h3 {
      margin-bottom: 10px;
      font-size: 18px;
      font-weight: 500;
     }
     p {
      font-size: 15px;
      font-weight: 400;
     }
    }
   }
  }
 }
 @media only screen and (${MaxScreenDevice.laptop}) {
  margin: 50px 0;
  .benefit {
   &__heading {
    h2 {
     font-size: 25px;
    }
   }
   &__items {
    flex-direction: column;
    margin: 50px 0 0 0;
    padding: 0 20px;
    &-item {
     width: 100%;
     gap: 10px;
     .content {
      p {
      }
     }
    }
   }
  }
 }
`;
