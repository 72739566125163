import { TCartItems, TProduct } from "@types";
import {
 Button,
 Col,
 Form,
 Image,
 Input,
 InputNumber,
 Row,
 Select,
 message,
} from "antd";
import { ContentModalStyled } from "assets/styles/V2/HouseHold/SolutionPackage";
import { API_SERVER } from "helpers/variable";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "store";
import { CloseOutlined } from "@ant-design/icons";
import {
 removeAllCartItem,
 removeCartItem,
 updateQuantity,
} from "features/slices/Cart";
import TextArea from "antd/es/input/TextArea";
import { postQueryHelper } from "helpers";
import moment from "moment";
import PhoneInput from "antd-phone-input";

type TProps = {
 form: any;
 setVisibleModal: Function;
 setIsLoading: Function;
};

const ModalContent = ({ form, setVisibleModal, setIsLoading }: TProps) => {
 const [valueQuantity, setValueQuantity] = React.useState<number>(1);
 const { cartItems, isLoading } = useSelector((state: RootState) => state.cart);
 const dispatch = useDispatch<AppDispatch>();

 const onSendInfo = async (values: any) => {
  setIsLoading(true);
  const productIdList = cartItems.map((item: TCartItems) => ({
   productId: item.productId,
   quantity: item.cartQuantity,
  }));
  if (
   !values.customerPhone ||
   !values.customerEmail.trim() ||
   !values.customerName.trim()
  ) {
   setIsLoading(false);
   return message.warning("Vui lòng nhập đủ các trường");
  }
  const customerPhone =
   "0" + values.customerPhone.areaCode + values.customerPhone.phoneNumber;
  if (customerPhone.length < 10) {
   setIsLoading(false);
   return message.warning("Định sạng số điện thoại không đúng");
  }
  const parameters = {
   ...values,
   customerPhone,
   time: moment().format("DD/MM/YYYY HH:mm:ss"),
   productId: JSON.stringify(productIdList),
  };
  const response = await postQueryHelper(
   "/order/send-information-order",
   parameters
  );
  if (response) {
   message.success("Gửi thông tin thành công");
   dispatch(removeAllCartItem());
   setTimeout(() => {
    setVisibleModal(false);
    setIsLoading(false);
   }, 1000);
  } else {
   message.success("Gửi thông tin thất bại");
   setTimeout(() => {
    setIsLoading(false);
   }, 1000);
  }
 };
 const handleRemoveProduct = (product: any) => {
  dispatch(removeCartItem(product));
 };

 const onChangeQuantity = (value: any, productId: string) => {
  setValueQuantity(value);
  dispatch(updateQuantity({ productId, quantity: value }));
 };

 return (
  <ContentModalStyled>
   <Form
    form={form}
    onFinish={onSendInfo}
    layout='vertical'
    className='modal-content__form'
   >
    <Form.Item
     label='Tên của bạn'
     name='customerName'
     required
     rules={[
      {
       required: true,
       message: "Vui lòng nhập tên của bạn!",
      },
     ]}
    >
     <Input className='form-input' />
    </Form.Item>
    <Form.Item
     label='Số điện thoại'
     name='customerPhone'
     rules={[
      {
       required: true,
       message: "Vui lòng nhập tên của bạn!",
      },
     ]}
    >
     <PhoneInput onlyCountries={["vn"]} />
    </Form.Item>
    <Form.Item
     label='Email'
     name='customerEmail'
     rules={[
      {
       type: "email",
       message: "Định dạng email không đúng!",
      },
      {
       required: true,
       message: "Vui lòng nhập email của bạn!",
      },
     ]}
    >
     <Input className='form-input' />
    </Form.Item>
    <Form.Item label='Ghi chú' name='notes'>
     <TextArea className='form-input textarea' rows={4} />
    </Form.Item>
   </Form>
   {!isLoading && cartItems.length > 0 && (
    <>
     <h3
      style={{
       margin: "20px 0",
      }}
     >
      Số lượng sản phẩm: {cartItems.length}
     </h3>
     {cartItems.map((cart: TCartItems) => {
      return (
       <div className='viewProduct'>
        <div className='information-product'>
         <Image
          className='image'
          src={`${API_SERVER}/${cart.productImages[0]}`}
         />
         <div className='info'>
          <h3>{cart.productName}</h3>
          <p>
           <strong>Mã sản phẩm: </strong>
           {cart.productCode}
          </p>
          <p>
           <strong>Loại sản phẩm: </strong>
           {cart.categoryTitle}
          </p>
         </div>
        </div>
        <InputNumber
         min={1}
         max={100000}
         className='input-desktop'
         defaultValue={cart.cartQuantity}
         onChange={(value) => onChangeQuantity(value, cart.productId)}
        />
        <Button
         type='dashed'
         danger
         className='input-desktop'
         shape='circle'
         icon={<CloseOutlined />}
         onClick={() => handleRemoveProduct(cart)}
        />
        <div className='mobile'>
         <InputNumber
          min={1}
          max={100000}
          defaultValue={cart.cartQuantity}
          onChange={(value) => onChangeQuantity(value, cart.productId)}
         />
         <Button
          type='dashed'
          danger
          shape='circle'
          icon={<CloseOutlined />}
          onClick={() => handleRemoveProduct(cart)}
         />
        </div>
       </div>
      );
     })}
    </>
   )}
  </ContentModalStyled>
 );
};

export default ModalContent;
