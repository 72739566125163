import { TCategory } from "@types";
import { ProductPortfolioStyled } from "assets/styles/page";
import React from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { RootState } from "store";

type TProps = {
 searchProduct: (type: "category" | "name", value: string) => void;
};

const ProductPortfolio = ({ searchProduct }: TProps) => {
 const [isActiveCategory, setIsActiveCategory] = React.useState<string>("all");
 const { category } = useSelector((state: RootState) => state.category);

 return (
  <ProductPortfolioStyled>
   <div className='product-portfolio'>
    <div className='product-portfolio__heading'>
     <i className='fa-solid fa-bars'></i>
     <h2>DANH MỤC SẢN PHẨM</h2>
    </div>
    <div className='product-portfolio__list'>
     <ul>
      <div
       className={`item ${isActiveCategory === "all" ? "active" : ""}`}
       onClick={() => {
        searchProduct("category", "all");
        setIsActiveCategory("all");
       }}
      >
       <li>
        <p>Tất cả</p>
       </li>
      </div>
      {category.map((item: TCategory) => (
       <div
        className={`item ${isActiveCategory === item.id ? "active" : ""}`}
        key={item.id}
        onClick={() => {
         searchProduct("category", item.id);
         setIsActiveCategory(item.id);
        }}
       >
        <li>
         <p>{item.title}</p>
        </li>
       </div>
      ))}
     </ul>
    </div>
   </div>
  </ProductPortfolioStyled>
 );
};

export default ProductPortfolio;
