import React from "react";
import parse from "html-react-parser";
import Container from "common/Container";
import Banner from "common/Banner";
import { ContactPage } from "../contact";
import BrandsComponent from "components/Home/HomeComponent/Brands.component";
import { AboutUsPage } from "data/release/page";
import { useSelector } from "react-redux";
import { RootState } from "store";

const Component = () => {
 const { aboutUs } = useSelector((state: RootState) => state.aboutUs);

 const breadcrumb = [
  {
   title: "Trang Chủ",
   href: "/",
  },
  {
   title: "Giới Thiệu",
   href: null,
  },
 ];

 return (
  <div>
   <Banner breadcrumb={breadcrumb} />
   <Container>
    <div
     className='sun-editor-editable'
     dangerouslySetInnerHTML={{ __html: aboutUs?.[0].contentPages }}
    />
    <ContactPage />
   </Container>
  </div>
 );
};

export default Component;
