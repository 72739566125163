import { sizeScreen } from "./MediaScreens";

export const MinScreenDevice = {
 mobileS: `min-width: ${sizeScreen.mobileS}`,
 mobileM: `min-width: ${sizeScreen.mobileM}`,
 mobileL: `min-width: ${sizeScreen.mobileL}`,
 tablet: `min-width: ${sizeScreen.tablet}`,
 laptop: `min-width: ${sizeScreen.laptop}`,
 laptopL: `min-width: ${sizeScreen.laptopL}`,
 desktop: `min-width: ${sizeScreen.desktop}`,
 desktopL: `min-width: ${sizeScreen.desktop}`,
 custom: (width: number) => `min-width: ${width}px`,
};

export const MaxScreenDevice = {
 mobileS: `max-width: ${sizeScreen.mobileS}`,
 mobileM: `max-width: ${sizeScreen.mobileM}`,
 mobileL: `max-width: ${sizeScreen.mobileL}`,
 tablet: `max-width: ${sizeScreen.tablet}`,
 laptop: `max-width: ${sizeScreen.laptop}`,
 laptopL: `max-width: ${sizeScreen.laptopL}`,
 desktop: `max-width: ${sizeScreen.desktop}`,
 desktopL: `max-width: ${sizeScreen.desktop}`,
 custom: (width: number) => `max-width: ${width}px`,
};
