import { ProjectStyled } from "assets/styles/V2/Projects/Project.styled";
import Banner from "common/Banner";
import React from "react";
import Search from "./Search";
import ProjectList from "./ProjectList";

const Component = () => {
 const breadcrumb = [
  {
   title: "Trang Chủ",
   href: "/",
  },
  {
   title: "Dự Án",
   href: null,
  },
 ];
 return (
  <ProjectStyled>
   <Banner breadcrumb={breadcrumb} />
   <div className='project-title'>
    <h1>Dự án</h1>
   </div>
   <Search />
   <ProjectList />
  </ProjectStyled>
 );
};

export default Component;
