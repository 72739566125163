import { MaxScreenDevice } from "assets/screens/DeviceScreens";
import styled from "styled-components";

export const SearchModalStyled = styled.div`
 position: fixed;
 top: 0;
 left: 0;
 width: 100vw;
 height: 100vh;
 display: flex;
 align-items: center;
 justify-content: center;
 z-index: 999999;
 &::before {
  content: "";
  background-color: ${({ theme }) => theme.colors.gray[300]};
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.7;
 }
 .input {
  width: 50vw;
  height: 50px;
  opacity: 99999999999;
  position: relative;
  input {
   width: 100%;
   height: 100%;
   background-color: ${({ theme }) => theme.colors.white};
   border: 1px solid ${({ theme }) => theme.colors.black};
   padding: 0 20px;
   outline: none;
   z-index: 1;
   border-radius: 20px;
   font-size: 16px;
   &::placeholder {
    font-size: 16px;
    font-weight: 500;
   }
  }
  i {
   position: absolute;
   right: 0;
   top: 50%;
   width: 50px;
   height: 50px;
   background-color: ${({ theme }) => theme.colors.blue[800]};
   transform: translate(110%, -50%);
   border-radius: 50%;
   z-index: 3;
   display: flex;
   align-items: center;
   justify-content: center;
   color: ${({ theme }) => theme.colors.white};
   font-size: 20px;
   cursor: pointer;
   transform: background-color 0.3s ease;
   &:hover {
    background-color: ${({ theme }) => theme.colors.blue[900]};
   }
  }
 }
 @media only screen and (${MaxScreenDevice.mobileL}) {
  text-align: center;
  justify-content: start;
  width: 100%;
  padding: 0 20px;
  .input {
   width: calc(100vw - 80px);
   height: 40px;
   input {
    border-radius: 5px;
    width: 100%;
   }
   i {
    width: 40px;
    height: 40px;
    border-radius: 10px;
   }
  }
 }
`;
