import Footer from "common/Footer";
import React from "react";
import { Outlet } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "store";
import { getAllCarousel } from "features/slices/Carousel";
import Loading from "common/Loading";
import { HeaderV2 } from "common/V2";
import { getAllProduct } from "features/slices/Product";
import { getAllCategory } from "features/slices/Category";
import { getAllSolution } from "features/slices/Solution";
import { getAllProject } from "features/slices/Project";
import { getAllNews } from "features/slices/News";
import { getAllSolutionPackage } from "features/slices/SolutionPackage";
import ButtonFloatPhone from "./common/ButtonFloatPhone";
import ButtonFloatZalo from "./common/ButtonFloatZalo";
import { getAllAboutUs } from "features/slices/AboutUs";

const ClientView = () => {
 const dispatch = useDispatch<AppDispatch>();
 const { isLoading: isLoadingCarousel } = useSelector(
  (state: RootState) => state.carousel
 );
 const { isLoading: isLoadingProduct } = useSelector(
  (state: RootState) => state.product
 );
 const { isLoading: isLoadingCategory } = useSelector(
  (state: RootState) => state.category
 );
 const { isLoading: isLoadingSolution } = useSelector(
  (state: RootState) => state.solution
 );
 const { isLoading: isLoadingProject } = useSelector(
  (state: RootState) => state.project
 );
 const { isLoading: isLoadingNews } = useSelector(
  (state: RootState) => state.news
 );
 const { isLoading: isLoadingSolutionPackage } = useSelector(
  (state: RootState) => state.solutionPackage
 );
 const { isLoading: isLoadingAboutUs } = useSelector(
  (state: RootState) => state.aboutUs
 );
 React.useEffect(() => {
  dispatch(getAllCarousel());
  dispatch(getAllProduct());
  dispatch(getAllCategory());
  dispatch(getAllSolution());
  dispatch(getAllProject());
  dispatch(getAllNews());
  dispatch(getAllSolutionPackage());
  dispatch(getAllAboutUs());
 }, [dispatch]);
 return (
  <>
   {isLoadingNews &&
   isLoadingCarousel &&
   isLoadingProduct &&
   isLoadingCategory &&
   isLoadingSolution &&
   isLoadingProject &&
   isLoadingAboutUs &&
   isLoadingSolutionPackage ? (
    <Loading />
   ) : (
    <>
     <HeaderV2 />
     <main className='main-client'>
      <Outlet />
     </main>
     <ButtonFloatPhone />
     <ButtonFloatZalo />
     <Footer />
    </>
   )}
  </>
 );
};

export default ClientView;
