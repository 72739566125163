import { ColumnsType } from "antd/es/table";
import { TDataProjectAdmin } from "./Table.project";
import { Image, Tag } from "antd";
import { API_SERVER } from "helpers/variable";
import Action from "./Action";

export interface DataTypesTable extends TDataProjectAdmin {
 key: string;
}

export const columns: ColumnsType<DataTypesTable> = [
 {
  title: "Hình ảnh",
  dataIndex: "projectImage",
  key: "projectImage",
  align: "center",
  width: "50%",
  render: (image: string) => (
   <Image src={`${API_SERVER}/${image}`} width={200} />
  ),
 },
 {
  title: "Tên hình ảnh",
  dataIndex: "projectImage",
  key: "projectImage",
 },
 {
  title: "Trạng thái",
  dataIndex: "projectActive",
  render: (active: boolean) => {
   return (
    <>
     {active ? (
      <Tag color='green'>Công khai</Tag>
     ) : (
      <Tag color='red'>Riêng tư</Tag>
     )}
    </>
   );
  },
 },
 {
  title: "",
  dataIndex: "action",
  align: "center",
  render: (_, record: TDataProjectAdmin) => <Action record={record} />,
 },
];
