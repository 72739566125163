import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { TCartItems, TProduct } from "@types";
import _ from "lodash";

type TInitialState = {
 cartItems: TCartItems[];
 isError: boolean;
 isSuccess: boolean;
 isLoading: boolean;
};

const initialState: TInitialState = {
 cartItems: [],
 isError: false,
 isSuccess: false,
 isLoading: false,
};

export const selectCart = createAsyncThunk("cart/all", async () => {
 try {
  return localStorage.getItem("user");
 } catch (error: any) {
  const message =
   (error.response && error.response.data && error.response.data.message) ||
   error.message ||
   error.toString();
  return message;
 }
});
const cartSlice = createSlice({
 name: "cart",
 initialState,
 reducers: {
  addToCart: (state: TInitialState, action: { payload: TProduct }) => {
   const itemIndex = state.cartItems.findIndex(
    (item: TProduct) => item.productId === action.payload.productId
   );
   if (itemIndex >= 0) return;
   const tempProduct = { ...action.payload, cartQuantity: 1 };

   state.cartItems.push(tempProduct);
   localStorage.setItem("cartItem", JSON.stringify(state.cartItems));
  },
  updateQuantity: (
   state: TInitialState,
   action: {
    payload: {
     productId: string;
     quantity: number;
    };
   }
  ) => {
   const itemIndex = state.cartItems.findIndex(
    (item: TCartItems) => item.productId === action.payload.productId
   );
   state.cartItems[itemIndex].cartQuantity = action.payload.quantity;
   localStorage.setItem("cartItem", JSON.stringify(state.cartItems));
  },
  removeCartItem: (state: TInitialState, action: { payload: TProduct }) => {
   const removeItem = state.cartItems.filter(
    (e: TProduct) => e.productId !== action.payload.productId
   );
   state.cartItems = removeItem;
   localStorage.setItem("cartItem", JSON.stringify(state.cartItems));
  },
  removeAllCartItem: () => {
   localStorage.setItem("cartItem", JSON.stringify([]));
  },
 },
 extraReducers: (builder: any) => {
  builder
   .addCase(selectCart.pending, (state: TInitialState) => {
    state.isLoading = true;
   })
   .addCase(selectCart.fulfilled, (state: TInitialState, action: any) => {
    state.isLoading = false;
    state.isSuccess = true;
    state.cartItems = action.payload;
   })
   .addCase(selectCart.rejected, (state: TInitialState) => {
    state.isLoading = false;
    state.isError = true;
    state.cartItems = [];
   });
 },
});
export const { addToCart, removeCartItem, removeAllCartItem, updateQuantity } =
 cartSlice.actions;
export default cartSlice.reducer;
