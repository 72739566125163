export const QUERY_NAVBAR_ADMIN = "QUERY_NAVBAR_ADMIN";
export const QUERY_CAROUSEL_ADMIN = "QUERY_CAROUSEL_ADMIN";
export const QUERY_PRODUCT_ADMIN = "QUERY_PRODUCT_ADMIN";
export const QUERY_CATEGORY_ADMIN = "QUERY_CATEGORY_ADMIN";
export const QUERY_ORDER_ADMIN = "QUERY_ORDER_ADMIN";
export const QUERY_SOLUTION_ADMIN = "QUERY_SOLUTION_ADMIN";
export const QUERY_MESSAGE_ADMIN = "QUERY_MESSAGE_ADMIN";
export const QUERY_PROJECT_ADMIN = "QUERY_PROJECT_ADMIN";
export const QUERY_NEWS_ADMIN = "QUERY_NEWS_ADMIN";
export const QUERY_SOLUTION_PACKAGE_ADMIN = "QUERY_SOLUTION_PACKAGE_ADMIN";
export const QUERY_ABOUT_US_ADMIN = "QUERY_ABOUT_US_ADMIN";
