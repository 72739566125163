import React from "react";
import { TDataMessage } from "./columns";
import { Flex, Form, Input, Modal, Space, Typography, message } from "antd";
import TextEditor from "common/TextEditor";
import { QUERY_MESSAGE_ADMIN, postQueryHelper } from "helpers";
import { updateStatusMessage } from "./query";
import { useQueryClient } from "react-query";

type TProps = {
 record: TDataMessage;
};
type TValuesForm = {
 subject: string;
 content: string;
};

const { Link, Text } = Typography;

const formatHtmlFile = (content: string) => {
 return `<!DOCTYPE html>
  <html>
  <head>
    <title>
    DT-SOLAR | SOLAR VIỆT NAM | Điện năng lượng mặt trời, Điện mặt trời
    </title>
    <link href="https://cdn.jsdelivr.net/npm/suneditor@latest/dist/css/suneditor.min.css" rel="stylesheet">
    <script src="https://cdn.jsdelivr.net/npm/suneditor@latest/dist/suneditor.min.js"></script>
    <script src="https://cdn.jsdelivr.net/npm/suneditor@latest/src/lang/ko.js"></script>
  </head>
  <body>
    ${content}
  </body>
</html>`;
};

const defaultHtml = `<br><br><br><br><br>
<p>
  <img style="width: 100px; height: 100px" src="https://dtsolar.com.vn/images/logo-dt.png">
</p>
<p>
  <br>
</p>
<h2><strong>CÔNG TY CỔ PHẦN ĐẦU TƯ NĂNG LƯỢNG DT SOLAR</strong></h2>
<p>Địa chỉ: Thôn Đông Phước, Xã Hòa An, Huyện Phú Hòa, Tỉnh Phú Yên, Việt Nam</p>
<p>Website: <a href="https://dtsolar.com.vn" rel="noopener noreferrer" target="_blank">DTSolar</a></p>
<p>Số điện thoại: <a href="tel:+0354873613" rel="noopener noreferrer" target="_blank"><strong>0354873613</strong></a></p>
<p>Email: <strong>dtsolartech78@gmail.com</strong></p>
<p>Fanpage: <a href="https://www.facebook.com/DTsolar78" rel="noopener noreferrer" target="_blank">Năng Lượng Tái Tạo DT Solar</a></p>
<p><br></p>`;

const Action = ({ record }: TProps) => {
 const [visible, setVisible] = React.useState<boolean>(false);
 const [valuesContent, setValuesContent] = React.useState<string>(defaultHtml);
 const [formSendEmail] = Form.useForm();
 const queryClient = useQueryClient();

 const [isLoading, setIsLoading] = React.useState<boolean>(false);
 const handleOK = () => {
  setIsLoading(true);
  formSendEmail.submit();
 };
 const handleCancel = () => {
  queryClient.invalidateQueries(QUERY_MESSAGE_ADMIN);

  setVisible(false);
 };
 const onFinish = async (values: TValuesForm) => {
  const email = record.customerInformation.Email;
  const html = formatHtmlFile(values.content);
  const parameters = {
   to: email,
   subject: values.subject,
   html: html,
  };
  const response: any = await postQueryHelper(
   "/mail/send-email/tra-loi-tin-nhan",
   parameters
  );
  if (response?.type === "error") {
   setIsLoading(false);
   return message.error(response.error);
  }
  await updateStatusMessage(record.id, { status: 1 });
  setIsLoading(false);
  formSendEmail.resetFields();
  message.success("Gửi email thành công");
  handleCancel();
 };
 React.useEffect(() => {
  formSendEmail.setFieldsValue({
   content: defaultHtml,
  });
 }, []);

 return (
  <>
   <Modal
    title={<Text style={{ textTransform: "uppercase" }}>{record.title}</Text>}
    open={visible}
    okText='Trả lời'
    cancelText='Đóng'
    onOk={handleOK}
    onCancel={handleCancel}
    width='60%'
    confirmLoading={isLoading}
   >
    <div
     style={{
      padding: "20px 50px",
     }}
    >
     {Object.keys(record.customerInformation).map((key: string) => (
      <Flex
       align='start'
       style={{
        margin: "10px 0",
       }}
       gap={20}
      >
       <Text
        strong
        style={{
         minWidth: 70,
         textTransform: "uppercase",
        }}
       >
        {key}:
       </Text>
       <Text>{record.customerInformation[key]}</Text>
      </Flex>
     ))}
     <Form form={formSendEmail} onFinish={onFinish} layout='vertical'>
      <Form.Item label='Subject:' name='subject' rules={[{ required: true }]}>
       <Input />
      </Form.Item>
      <Form.Item label='Nội dung:' name='content'>
       <TextEditor onChange={setValuesContent} value={valuesContent} />
      </Form.Item>
     </Form>
    </div>
   </Modal>
   {record?.customerInformation?.["Email"] && (
    <Space size='middle'>
     <Link onClick={() => setVisible(true)}>Trả lời</Link>
    </Space>
   )}
  </>
 );
};

export default Action;
