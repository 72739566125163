import { MaxScreenDevice } from "assets/screens/DeviceScreens";
import styled from "styled-components";
export const CarouselDetailProductStyled = styled.div`
 width: 40%;
 height: 100%;
 display: flex;
 flex-direction: column;
 gap: 20px;
 .carousel {
  height: calc(80% - 20px);
  .mySwiper2 {
   border: 1px solid ${({ theme }) => theme.colors.main_green};
   .swiper-wrapper {
    align-items: center;
   }
   height: 100%;
   width: 100%;
   img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    vertical-align: middle;
   }
  }
 }
 .image__list {
  height: 20%;
  .mySwiper {
   height: 100%;
   box-sizing: border-box;
  }
  .mySwiper .swiper-slide {
   width: 25%;
   height: 100%;
   opacity: 0.4;
   border: 1px solid ${({ theme }) => theme.colors.main_green};
  }
  .mySwiper .swiper-slide-thumb-active {
   opacity: 1;
   border: 1px solid ${({ theme }) => theme.colors.main_yellow};
  }
  .swiper-slide img {
   display: block;
   width: 100%;
   height: 100%;
   object-fit: cover;
   cursor: pointer;
  }
 }
 @media only screen and (${MaxScreenDevice.custom(800)}) {
  width: 100%;
  flex-direction: column;
  height: 50vh;
  .carousel {
   width: 100%;
   height: 80%;
  }
  /* .carousel {
   width: 100%;
   height: 100%;
  }
  .image__list {
   width: 20%;
   height: 100%;
   .swiper-wrapper {
    width: 100%;
    height: calc((100% / 4) - 20px);
    flex-direction: column;
    gap: 20px;
    .swiper-slide {
     width: 100% !important;
    }
   }
  } */
 }
`;
