import React from "react";
import { TSolutionAdmin } from "./Component";
import {
 Button,
 Flex,
 Modal,
 Popconfirm,
 Row,
 Typography,
 message,
} from "antd";
import {
 DeleteOutlined,
 EditOutlined,
 LockOutlined,
 UnlockOutlined,
 QuestionCircleOutlined,
} from "@ant-design/icons";
import TextEditor from "common/TextEditor";
import { deleteSolutionQuery, updateSolutionQuery } from "./query";
import { useQueryClient } from "react-query";
import { QUERY_SOLUTION_ADMIN } from "helpers";

type TProps = {
 dataSolution: TSolutionAdmin;
};
const { Title } = Typography;
const HeaderSolution = ({ dataSolution }: TProps) => {
 const [valueContent, setValueContent] = React.useState<string>("");
 const [visibleModal, setVisibleModal] = React.useState<boolean>(false);
 const queryClient = useQueryClient();
 const handleOpenUpdateModal = async () => {
  setVisibleModal(true);
 };

 const handleUpdate = async (type: "updateHtml" | "updateActive") => {
  let response: any;
  if (type === "updateHtml") {
   response = await updateSolutionQuery(
    {
     fileHtml: valueContent,
    },
    dataSolution.solutionId
   );
  } else if (type === "updateActive") {
   response = await updateSolutionQuery(
    {
     active: dataSolution.solutionActive === 1 ? 0 : 1,
    },
    dataSolution.solutionId
   );
  }
  if (response.type === "error") {
   return message.error(response.error);
  }
  queryClient.invalidateQueries(QUERY_SOLUTION_ADMIN);
  message.success("Cập nhật nội dung thành công");
 };

 const handleDeleteSolution = async () => {
  const response: any = await deleteSolutionQuery(dataSolution.solutionId);
  if (response.type === "error") {
   message.error(response.error);
  } else {
   message.success("Xóa thành công.");
  }
 };
 const handleCloseModal = () => {
  setVisibleModal(false);
 };
 return (
  <>
   <Modal
    title='Chỉnh sửa / thêm nội dung'
    okText='Thêm'
    cancelText='Hủy'
    width='80%'
    open={visibleModal}
    onCancel={handleCloseModal}
    onOk={() => handleUpdate("updateHtml")}
   >
    <div>
     <TextEditor
      value={dataSolution.solutionHtmlFile}
      onChange={setValueContent}
     />
    </div>
   </Modal>
   <div
    style={{
     minHeight: "80vh",
     height: "80vh",
     overflowY: "auto",
    }}
   >
    <div className='header'>
     <Row justify={"space-between"}>
      <Title
       className='heading'
       color='#000'
       level={5}
       style={{ color: "#000" }}
      >
       {dataSolution.solutionName}
      </Title>
      <Flex align='center' justify='center' gap={10}>
       <Button
        type='dashed'
        onClick={handleOpenUpdateModal}
        icon={<EditOutlined />}
       />
       {dataSolution.solutionActive === 1 ? (
        <Button
         type='dashed'
         onClick={() => handleUpdate("updateActive")}
         danger
         icon={<LockOutlined />}
        />
       ) : (
        <Button
         type='dashed'
         onClick={() => handleUpdate("updateActive")}
         icon={<UnlockOutlined />}
        />
       )}
       <Popconfirm
        title='Xóa sản phẩm'
        description={`Bạn có chắc chắn muốn xóa sản phẩm ${dataSolution.solutionName} ?`}
        icon={<QuestionCircleOutlined style={{ color: "red" }} />}
        okText='Xóa'
        cancelText='Hủy'
        onConfirm={handleDeleteSolution}
       >
        <Button danger type='dashed' icon={<DeleteOutlined />} />
       </Popconfirm>
      </Flex>
     </Row>
    </div>
   </div>
  </>
 );
};

export default HeaderSolution;
