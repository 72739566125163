import React from "react";
import { DataType, columns } from "./columns";
import { Table } from "antd";
import { useQuery } from "react-query";
import { QUERY_MESSAGE_ADMIN } from "helpers";
import { getAllMessage } from "./query";

export interface TMessageDataAdmin {
 customerInformation: string;
 id: string;
 message: string;
 time: string;
 title: string;
}

const Component = () => {
 const [dataTable, setDataTable] = React.useState<any>();
 const { data: dataMessage, isLoading }: any = useQuery(
  QUERY_MESSAGE_ADMIN,
  () => getAllMessage()
 );
 const onFormatMessageData = (data: TMessageDataAdmin[]) => {
  const dataArr = data.map((item: TMessageDataAdmin) => ({
   ...item,
   customerInformation: JSON.parse(item.customerInformation),
   message: JSON.parse(item.message),
  }));
  setDataTable(dataArr);
 };
 React.useEffect(() => {
  if (!isLoading && dataMessage) {
   onFormatMessageData(dataMessage);
  }
 }, [isLoading, dataMessage]);

 return (
  <>
   <Table columns={columns} dataSource={dataTable} loading={isLoading} />
  </>
 );
};

export default Component;
