import { Button, Image, Tag } from "antd";
import { ColumnsType } from "antd/es/table";
import { TJob, TOrder } from "types";
import { setJobStatus } from "./setStatus";
import Action, {
 TCartItemsAdmin,
 TResultOrder,
 TResultProduct,
} from "./Action";
import { API_SERVER } from "helpers/variable";
import { formatter } from "components/admin/common/formatPrice";

export interface TTableOrder extends TOrder {
 key: string | number;
}

export const columns: ColumnsType<TTableOrder> = [
 {
  title: "Tên khách hàng",
  dataIndex: "customerName",
  key: "customerName",
 },
 {
  title: "Số điện thoại",
  dataIndex: "customerPhone",
  key: "customerPhone",
 },
 {
  title: "Email",
  dataIndex: "customerEmail",
  key: "customerEmail",
 },
 {
  title: "Số lượng sản phẩm",
  dataIndex: "productId",
  key: "productId",
  render: (value: string) => {
   return <p>{JSON.parse(value).length}</p>;
  },
 },
 {
  title: "Thời gian",
  dataIndex: "time",
  key: "time",
 },
 {
  title: "Trạng thái",
  key: "status",
  dataIndex: "status",
  render: (_, { status }: { status: number }) => {
   const { color, label }: any = setJobStatus(status);
   return <Tag color={color}>{label.toUpperCase()}</Tag>;
  },
 },
 {
  title: "",
  key: "action",
  render: (_, record) => <Action record={record} />,
 },
];

export const columnConfirmOrder: ColumnsType<TResultProduct> = [
 {
  title: "Hình ảnh",
  dataIndex: "image",
  key: "image",
  render: (value) => {
   return <Image preview={false} width={150} src={value} />;
  },
 },
 {
  title: "Tên sản phẩm",
  dataIndex: "name",
  key: "name",
 },
 {
  title: "Số lượng sản phẩm",
  dataIndex: "quantity",
  key: "quantity",
 },
 {
  title: "Giá sản phẩm",
  dataIndex: "price",
  key: "price",
 },
 {
  title: "Tổng",
  dataIndex: "priceTotal",
  key: "priceTotal",
 },
 {
  title: "Giảm giá",
  dataIndex: "discount",
  key: "discount",
  render(value) {
   return <p>- {value}</p>;
  },
 },
];
