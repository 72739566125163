import { SolutionSessionStyled } from "assets/styles/V2/_SolutionSession.styled";
import Container from "common/Container";
import React from "react";

type TProps = {
 title?: string;
 description?: string;
 image: string;
 video?: string;
 onHandleButton: () => void;
 children?: React.ReactNode | null;
 buttonText?: string;
 row: "row-reverse" | "row";
 background?: boolean;
};

const SolutionSession = ({
 title,
 description,
 image,
 video,
 row = "row",
 buttonText = "NHẬN TƯ VẤN VÀ BÁO GIÁ",
 children = null,
 background = true,
 onHandleButton,
}: TProps) => {
 return (
  <SolutionSessionStyled background={background} row={row}>
   <Container>
    <div className='solution-session'>
     <div className='solution-session__left'>
      {!children ? (
       <>
        <div className='heading'>
         <h1>{title}</h1>
        </div>
        <div className='desc'>
         {description && (
          <div
           style={{ backgroundColor: "transparent" }}
           className='sun-editor-editable'
           dangerouslySetInnerHTML={{ __html: description }}
          />
         )}
        </div>
       </>
      ) : (
       <>{children}</>
      )}
      <div className='button-see-more' onClick={onHandleButton}>
       <button>{buttonText}</button>
      </div>
     </div>
     <div className='solution-session__right'>
      {video && <div className='background' />}
      <img src={image} alt='solution-image' />
     </div>
    </div>
   </Container>
  </SolutionSessionStyled>
 );
};

export default SolutionSession;
