import styled from "styled-components";
export const ReviewStyled = styled.div`
 .review {
  &__heading {
   margin: 20px 0;
   h3 {
    color: ${({ theme }) => theme.colors.blue[900]};
    font-size: 30px;
    font-weight: 600;
    text-align: center;
    text-transform: uppercase;
   }
   p {
    margin-top: 20px;
    color: ${({ theme }) => theme.colors.blue[900]};
    font-size: 15px;
    font-weight: 400;
    text-align: center;
   }
  }
  &__content {
   margin: 150px 0 50px;
   border: 1px solid ${({ theme }) => theme.colors.green[600]};
   border-radius: 10px;
   width: 100%;
   position: relative;
   z-index: 1;
   &-image {
    width: 100px;
    height: 100px;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    img {
     width: 100%;
     height: 100%;
     object-fit: cover;
     border-radius: 50%;
     position: relative;
     z-index: 1;
     &::after {
      content: "";
      width: 100%;
      height: 100%;
      display: block;
      z-index: 2;
      background-color: ${({ theme }) => theme.colors.blue[600]};
     }
    }
   }
   &-content {
    padding: 60px 20px 12px;
    .name {
     color: ${({ theme }) => theme.colors.black};
     font-size: 18px;
     font-weight: 600;
    }
    .function {
     color: ${({ theme }) => theme.colors.blue[600]};
     font-size: 13px;
     font-weight: 400;
    }
    .review {
     color: ${({ theme }) => theme.colors.black};
     font-size: 15px;
     font-weight: 400;
    }
    .date {
     color: ${({ theme }) => theme.colors.gray[600]};
     font-size: 13px;
     font-weight: 400;
     text-align: left !important;
    }
    p {
     text-align: center;
     padding: 8px;
    }
   }
  }
  &__navigation-custom{
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    gap: 10px;
    z-index: 2;
    button{
        background-color: ${({theme}) => theme.colors.main_yellow};
        border: 1px solid ${({theme}) => theme.colors.main_yellow};
        padding: 10px;
        border-radius: 3px;
        transition: all .4s ease;
        cursor: pointer;
        i{
            font-size: 15px;
        }
        &:hover{
            background-color: transparent;
        }
    }
  }
 }
`;
