import React from "react";
import {
 Button,
 Col,
 Flex,
 Form,
 Input,
 Row,
 Select,
 Spin,
 Typography,
 message,
} from "antd";
import { TImagesProduct } from "components/Home/Pages/detailProduct/Component";
import UploadComponent from "common/Upload";
import TextEditor from "common/TextEditor";
import _ from "lodash";
import {
 QUERY_PRODUCT_ADMIN,
 getQueryHelper,
 patchQueryHelper,
 postQueryHelper,
} from "helpers";
import { theme } from "theme";
import { useQueryClient } from "react-query";
import {
 saveProductQuery,
 updateProductQuery,
 TFromValueAddProduct,
} from "./query";

type TProps = {
 formUpdate?: any;
 imageListUpdate?: any;
 type?: "update" | "add";
 idProduct?: string;
 setVisibleUpdateModal?: Function;
};

type TCategoryOption = {
 label: string;
 value: string;
};

const { Title } = Typography;

const style = {
 input: {
  height: 45,
 },
 form: {
  width: "100%",
 },
};

const AddProduct = ({
 formUpdate = undefined,
 imageListUpdate = [],
 type = "add",
 idProduct = "",
 setVisibleUpdateModal = undefined,
}: TProps) => {
 const [form] = Form.useForm();
 const [typeView, setTypeView] = React.useState<"update" | "add">(type);
 const [imageList, setImageList] = React.useState([]);
 const [productParameter, setProductParameter] = React.useState<string>(
  type === "add" ? "" : formUpdate.getFieldsValue().productParameter
 );
 const [productDescription, setProductDescription] = React.useState<string>(
  type === "add" ? "" : formUpdate.getFieldsValue().description
 );
 const [categoryOption, setCategoryOption] = React.useState<TCategoryOption[]>(
  []
 );
 const [isLoading, setIsLoading] = React.useState<boolean>(false);
 const queryClient = useQueryClient();

 React.useEffect(() => {
  setImageList(imageListUpdate);
 }, [typeView === "update"]);

 const onSaveProduct = async (values: TFromValueAddProduct) => {
  if (imageList.length === 0)
   return message.error("Vui lòng tải hình ảnh sản phẩm.");
  setIsLoading(true);
  const imageSuccessUpload = _.filter(imageList, { status: "done" }).map(
   (item: any) => item.name
  );
  const parameters = {
   ...values,
   images: JSON.stringify(imageSuccessUpload),
   productName: values.productName,
   productParameter,
   description: productDescription,
  };
  let response: any;
  if (type === "add") {
   response = await saveProductQuery(parameters);
  } else {
   response = await updateProductQuery(parameters, idProduct);
  }
  queryClient.invalidateQueries(QUERY_PRODUCT_ADMIN);
  setTypeView("add");
  if (response.type === "error") {
   message.error(response.message);
   setIsLoading(false);
   return;
  } else {
   message.success("Thêm sản phẩm thành công.");
   form.resetFields();
   if (type === "add") setImageList([]);
   setIsLoading(false);
   if (setVisibleUpdateModal) setVisibleUpdateModal(false);
  }
 };

 const onGetCategoryData = async () => {
  const categoryData: any = await getQueryHelper("/category/get-all-category");
  const options = categoryData.map(
   (category: { title: string; href: string; id: string }) => ({
    label: category.title,
    value: category.id,
   })
  );
  setCategoryOption(options);
 };

 React.useEffect(() => {
  onGetCategoryData();
 }, []);

 if (isLoading)
  return (
   <Flex
    justify='center'
    align='center'
    style={{
     width: "100%",
     height: "100%",
    }}
   >
    <Spin spinning={isLoading} />
   </Flex>
  );

 return (
  <div>
   {!formUpdate && (
    <Title style={{ textAlign: "center" }} level={3}>
     THÊM SẢN PHẨM
    </Title>
   )}
   <Flex>
    <Form
     form={formUpdate || form}
     style={style.form}
     layout='vertical'
     onFinish={onSaveProduct}
    >
     <Form.Item label={"Tên sản shẩm:"} name='productName' required>
      <Input
       styles={{
        input: style.input,
       }}
      />
     </Form.Item>
     <Row gutter={[12, 12]}>
      <Col span={8}>
       <Form.Item label={"Mã sản shẩm:"} name='productCode' required>
        <Input
         styles={{
          input: style.input,
         }}
        />
       </Form.Item>
      </Col>
      <Col span={8}>
       <Form.Item label={"Thương hiệu:"} name='trademark'>
        <Input
         styles={{
          input: style.input,
         }}
        />
       </Form.Item>
      </Col>
      <Col span={8}>
       <Form.Item label={"Loại sản phẩm:"} name='categoryCode' required>
        <Select
         showSearch
         style={{ height: 45 }}
         placeholder='Loại sản phẩm'
         optionFilterProp='children'
         filterOption={(input: string, option: any) =>
          (option?.label ?? "").includes(input)
         }
         filterSort={(optionA: TCategoryOption, optionB: TCategoryOption) =>
          (optionA?.label ?? "")
           .toLowerCase()
           .localeCompare((optionB?.label ?? "").toLowerCase())
         }
         options={categoryOption}
        />
       </Form.Item>
      </Col>
     </Row>
     <Form.Item label='Thông số:' name='productParameter' required>
      <TextEditor onChange={setProductParameter} />
     </Form.Item>
     <Form.Item label='Mô tả chi tiết:' name='description'>
      <TextEditor onChange={setProductDescription} />
     </Form.Item>
     <UploadComponent resultUploadImage={setImageList} imageList={imageList} />
     {!formUpdate && (
      <Form.Item label={" "}>
       <Button type='primary' htmlType='submit'>
        Thêm sản phẩm
       </Button>
      </Form.Item>
     )}
    </Form>
   </Flex>
  </div>
 );
};

export default AddProduct;
