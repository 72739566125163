import styled from "styled-components";
export const SystemEstimationStyled = styled.section`
 .button-see-more {
  padding: 0 50px;
 }
 .system-estimation {
  padding: 0 50px;
  &__heading {
   margin: 20px 0;
   h3 {
    font-size: 25px;
    font-weight: 500;
    text-transform: uppercase;
   }
  }
  .form-input {
   width: 100%;
   label {
    font-size: 18px;
    font-weight: 400;
   }
   input {
    border-radius: 50px;
    width: 100%;
    height: 40px;
   }
  }
 }
`;
