type TInfoCompany = {
 address: string;
 phoneNumber: string;
 nameCompany: string;
 fanpage: {
  href: string;
  label: string;
 };
 email: string;
};
const infoCompany: TInfoCompany = {
 address: "Thôn Đông Phước, Xã Hòa An, Huyện Phú Hòa, Tỉnh Phú Yên, Việt Nam",
 phoneNumber: "0354873613",
 nameCompany: "CÔNG TY CỔ PHẦN ĐẦU TƯ NĂNG LƯỢNG DT SOLAR",
 fanpage: {
  href: "https://www.facebook.com/DTsolar78",
  label: "Năng Lượng Tái Tạo DT Solar",
 },
 email: "dtsolartech78@gmail.com",
};
export default infoCompany;
