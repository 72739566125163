import { Button, Modal } from "antd";
import Heading from "components/admin/common/Header";
import React from "react";
import TableProject from "./Table.project";
import { useQuery, useQueryClient } from "react-query";
import { QUERY_CAROUSEL_ADMIN, QUERY_PROJECT_ADMIN } from "helpers";
import { getAllProjectData, postDataProjectData } from "./fetch";
import UploadComponent from "common/Upload";
import type { UploadFile } from "antd/es/upload/interface";

const Component = () => {
 const [visibleAddSolution, setVisibleAddSolution] =
  React.useState<boolean>(false);
 const [imageList, setImageList] = React.useState<UploadFile[]>([]);
 const queryClient = useQueryClient();

 const { data, isLoading }: any = useQuery(QUERY_PROJECT_ADMIN, () =>
  getAllProjectData()
 );
 const handleOk = () => {
  setVisibleAddSolution(false);
  imageList.map((image: { name: string }, index: number) =>
   postDataProjectData({
    image: image.name,
   })
  );
  queryClient.invalidateQueries(QUERY_CAROUSEL_ADMIN);
  setImageList([]);
 };
 const handleCancel = () => {};

 return (
  <div>
   <Modal
    title='Tải hình ảnh'
    open={visibleAddSolution}
    onOk={handleOk}
    onCancel={handleCancel}
    centered
    className='hide-scroll'
    styles={{
     body: { marginTop: 50, maxHeight: 600, minHeight: 100, overflowY: "auto" },
    }}
   >
    <UploadComponent resultUploadImage={setImageList} imageList={imageList} />
   </Modal>
   <Heading title='DỰ ÁN ĐÃ THỰC HIỆN'>
    <Button
     onClick={() => setVisibleAddSolution(true)}
     type='primary'
     icon={<i className='fa-solid fa-plus'></i>}
    >
     THÊM
    </Button>
   </Heading>
   <TableProject dataTable={data} isLoading={isLoading} />
  </div>
 );
};

export default Component;
