import { Button, Form, Modal, Table, message } from "antd";
import Heading from "components/admin/common/Header";
import React from "react";
import ContentModal from "./ContentModal";
import { useQuery, useQueryClient } from "react-query";
import { QUERY_NEWS_ADMIN } from "helpers";
import { getNewsData } from "./query";
import { columns } from "./columns";

// export const TNewsDataAdmin

export interface TNewsData {
 id: string;
 imageThumbnail: string;
 title: string;
 summary: string;
 date: string;
 href: string;
 html: string;
 active: number;
}

const Component = () => {
 const [visibleModal, setVisibleModal] = React.useState<boolean>(false);
 const [isLoadingPage, setIsLoadingPage] = React.useState<boolean>(false);
 const [formSaveNews] = Form.useForm();
 const queryClient = useQueryClient();
 const { data, isLoading }: any = useQuery(QUERY_NEWS_ADMIN, () =>
  getNewsData()
 );
 const handleOpenModal = () => {
  setVisibleModal(true);
 };
 const handleSaveNews = () => {
  // setIsLoadingPage(true);
  formSaveNews.submit();
 };
 const handleCloseModal = () => {
  formSaveNews.resetFields();
  queryClient.invalidateQueries(QUERY_NEWS_ADMIN);
  setVisibleModal(false);
  setIsLoadingPage(false);
 };
 return (
  <>
   <Modal
    open={visibleModal}
    title='THÊM TIN TỨC'
    okText='Cập nhật'
    cancelText='Đóng'
    confirmLoading={isLoadingPage}
    onOk={handleSaveNews}
    onCancel={handleCloseModal}
    width={"80%"}
   >
    <ContentModal
     type='add'
     form={formSaveNews}
     onCloseModal={handleCloseModal}
     imageListProp={[]}
    />
   </Modal>
   <Heading title='DANH MỤC TIN TỨC'>
    <Button
     onClick={handleOpenModal}
     type='primary'
     icon={<i className='fa-solid fa-plus'></i>}
    >
     Thêm
    </Button>
   </Heading>
   <Table columns={columns} loading={isLoading} dataSource={data} />
  </>
 );
};

export default Component;
