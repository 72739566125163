import { Form, FormInstance, Input, Upload, message } from "antd";
import React from "react";
import type { UploadFile } from "antd/es/upload/interface";
import UploadComponent from "common/Upload";
import TextEditor from "common/TextEditor";
import moment from "moment";
import { formatHref } from "components/admin/common/formatHref";
import { addNewsData, updateNewsData } from "./query";
import { useQueryClient } from "react-query";
import { QUERY_NEWS_ADMIN } from "helpers";
import LoadingV2 from "common/V2/LoadingV2";

type TProps = {
 form: FormInstance;
 onCloseModal: () => void;
 imageListProp?: any;
 type: "add" | "update";
 idNewsUpdate?: string;
};

interface TValuesForm {
 title: string;
 summary: string;
 imageThumbnail: string;
 html: string;
}

export interface TNewsDataAdmin extends TValuesForm {
 date: string;
 href: string;
}

const ContentModal = ({
 form,
 onCloseModal,
 type = "add",
 idNewsUpdate = "",
 imageListProp = [],
}: TProps) => {
 const [imageList, setImageList] = React.useState<UploadFile[]>(imageListProp);
 const [valueDescription, setValueDescription] = React.useState<string>("");
 const [isLoading, setIsLoading] = React.useState<boolean>(false);

 const onFinish = async (values: TValuesForm) => {
  if (imageList.length === 0)
   return message.error("Vui lòng tải hình ảnh Thumbnail");
  if (imageList.length > 1)
   return message.error("Hình ảnh Thumbnail chỉ được duy nhât 1 hình");
  setIsLoading(true);
  const parameters: TNewsDataAdmin = {
   ...values,
   date: moment().format("DD/MM/YYYY HH:mm:ss"),
   href: formatHref(values.title),
   imageThumbnail: imageList[0].name,
  };
  let response: any;
  if (type === "add") {
   response = await addNewsData(parameters);
  } else if (type === "update") {
   response = await updateNewsData(parameters, idNewsUpdate);
  }
  if (response?.type === "error") {
   message.error(response.error);
  }

  message.success("Thêm dữ liệu tin tức thành công");
  setTimeout(() => {
   setImageList([]);
   setIsLoading(false);
   onCloseModal();
  }, 1000);
 };

 return (
  <>
   {isLoading ? (
    <LoadingV2 />
   ) : (
    <Form form={form} onFinish={onFinish} layout='vertical'>
     <Form.Item rules={[{ required: true }]} label='Tiêu đề:' name='title'>
      <Input />
     </Form.Item>
     <Form.Item rules={[{ required: true }]} label='Tóm tắt:' name='summary'>
      <Input.TextArea />
     </Form.Item>
     <Form.Item label='Hình ảnh (Thumbnail):' name='imageThumbnail'>
      <UploadComponent imageList={imageList} resultUploadImage={setImageList} />
     </Form.Item>
     <Form.Item rules={[{ required: true }]} label='Chi chi tiết:' name='html'>
      <TextEditor onChange={setValueDescription} value={valueDescription} />
     </Form.Item>
    </Form>
   )}
  </>
 );
};

export default ContentModal;
