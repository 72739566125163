import { TNavBarData } from "@types";
import { Form, Input, Select, message } from "antd";
import React from "react";
import { useQueryClient } from "react-query";
import { TContentFormModal, TParametersNavbar } from "../Navbar";
import { QUERY_NAVBAR_ADMIN, patchQueryHelper, postQueryHelper } from "helpers";
import _ from "lodash";

type TProps = {
 form: any;
 onCloseModal: () => void;
 data: TNavBarData[];
 contentFormModal: TContentFormModal;
};

type TOptionNavbar = {
 value: string | null;
 label: string;
};

type TForm = {
 idParent: string;
 href: string;
 title: string;
 id: string;
};

const fetchPostNavbar = (parameters: TParametersNavbar) => {
 return postQueryHelper("/navbar/", parameters);
};

export const fetchEditNavbarById = (
 id: string | undefined,
 parameters: TParametersNavbar
) => {
 return patchQueryHelper(`/navbar/${id}`, parameters);
};

const ContentModalNavbar = ({
 form,
 onCloseModal,
 data,
 contentFormModal,
}: TProps) => {
 const queryClient = useQueryClient();
 const [optionNavbar, setOptionNavbar] = React.useState<TOptionNavbar[]>([]);

 const formatOptionNavbar = (data: TNavBarData[]) => {
  const filterNavbar = _.filter(data, { idParent: null });
  const option = filterNavbar.map((navbar: TNavBarData) => ({
   value: navbar.id,
   label: navbar.title,
  }));
  setOptionNavbar([{ value: null, label: "Trống" }, ...option]);
 };

 const onSaveNavbar = async (parameters: TParametersNavbar) => {
  await fetchPostNavbar(parameters);
  queryClient.invalidateQueries(QUERY_NAVBAR_ADMIN);
 };

 const onUpdateNavbar = async (
  id: string | undefined,
  parameters: TParametersNavbar
 ) => {
  if (id) {
   await fetchEditNavbarById(id, parameters);
   queryClient.invalidateQueries(QUERY_NAVBAR_ADMIN);
  }
 };

 const onSaveAndUpdateNavbar = async (values: TForm) => {
  let result: any;
  const { idParent } = values;
  let parameters: TParametersNavbar;
  if (idParent) {
   parameters = values;
  } else {
   parameters = {
    ...values,
    idParent: null,
   };
  }
  if (contentFormModal.type === "new") {
   result = onSaveNavbar(parameters);
  } else {
   result = onUpdateNavbar(contentFormModal.idNavbar, parameters);
  }

  if (result) {
   message.success("Successfully!!!");
   onCloseModal();
   queryClient.invalidateQueries(QUERY_NAVBAR_ADMIN);
  } else {
   onCloseModal();
   message.success("Fail!!!");
  }
 };

 React.useEffect(() => {
  formatOptionNavbar(data);
 }, [data]);
 return (
  <Form
   style={{
    padding: "20px 0",
   }}
   layout='vertical'
   form={form}
   onFinish={onSaveAndUpdateNavbar}
  >
   <Form.Item label='Parent: ' name={"idParent"}>
    <Select options={optionNavbar} />
   </Form.Item>
   <Form.Item required={true} label='Tiêu đề: ' name={"title"}>
    <Input
     type='text'
     onChange={(value) => {
      form.setFieldsValue({
       href: value.target.value
        .toLowerCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .replace(/đ/g, "d")
        .replace(/Đ/g, "D")
        .trim()
        .split(" ")
        .join("-"),
      });
     }}
    />
   </Form.Item>
   <Form.Item label='Href: ' name={"href"}>
    <Input type='text' />
   </Form.Item>
  </Form>
 );
};

export default ContentModalNavbar;
