import styled from "styled-components";

export const ProcedureServicePageStyled = styled.div`
 width: 100%;
 .title {
  background-color: ${({ theme }) => theme.colors.blue[800]};
  border-radius: 5px;
  margin: 0 0 10px 0;
  padding: 10px 15px;
  display: flex;
  align-items: center;
  gap: 10px;
  i {
   color: ${({ theme }) => theme.colors.white};
  }
  h2 {
   font-size: 16px;
   font-weight: 500;
   color: ${({ theme }) => theme.colors.white};
  }
 }
 .collapse {
  width: 100% !important;
  background-color: ${({ theme }) => theme.colors.green[800]};
  border-radius: 5px;
  .ant-collapse-item {
   border-color: ${({ theme }) => theme.colors.gray[200]} !important;
  }
  .ant-collapse-content {
   &.ant-collapse-content-active {
    background-color: ${({ theme }) => theme.colors.green[900]} !important;
    color: ${({ theme }) => theme.colors.white};
    border-color: ${({ theme }) => theme.colors.gray[200]} !important;
   }
  }
  .ant-collapse-expand-icon {
   color: ${({ theme }) => theme.colors.white};
  }
  .ant-collapse-header-text {
   color: ${({ theme }) => theme.colors.white};
  }
 }
`;
