import React from "react";
import TableOrder from "./table";

const Components = () => {
 return (
  <>
   <TableOrder />
  </>
 );
};

export default Components;
