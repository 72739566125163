import { Table } from "antd";
import React from "react";
import { columns } from "./columns";
import { useQuery } from "react-query";
import { QUERY_ORDER_ADMIN, QUERY_PRODUCT_ADMIN } from "helpers";
import { getOrderQuery } from "./query";
import { getProductQuery } from "../products/query";

const TableOrder = () => {
 const { data, isLoading }: any = useQuery(QUERY_ORDER_ADMIN, () =>
  getOrderQuery()
 );

 return (
  <>
   <Table columns={columns} dataSource={data} loading={isLoading} />
  </>
 );
};

export default TableOrder;
