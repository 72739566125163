import { MaxScreenDevice } from "assets/screens/DeviceScreens";
import styled from "styled-components";

export const AboutUsHomeStyled = styled.div`
 .about {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 100px 0;
  gap: 20px;
  &__left {
   width: 45%;
   &-heading {
    h3 {
     font-size: 30px;
     font-weight: 600;
     text-transform: uppercase;
     margin-bottom: 20px;
    }
   }
   &-desc {
    p {
     font-size: 16px;
     font-weight: 500;
     line-height: 160%;
     text-align: justify;
     max-width: 90%;
    }
    .desc__info {
     margin: 20px 0 0 20px;
     border-left: 5px solid ${({ theme }) => theme.colors.gray[400]};
     padding-left: 10px;
    }
   }
   &-button {
    margin-top: 40px;
    button {
     padding: 10px 20px;
     background-color: ${({ theme }) => theme.colors.main_green};
     border-radius: 5px;
     border: none;
     color: ${({ theme }) => theme.colors.white};
     font-size: 16px;
     font-weight: 500;
     display: flex;
     align-items: center;
     justify-content: center;
     gap: 10px;
     cursor: pointer;
     transition: all 0.4s ease;
     .button-plus {
      font-size: 14px;
     }
     &:hover {
      transform: translateY(5px);
      background-color: ${({ theme }) => theme.colors.main_yellow};
     }
    }
   }
  }
  &__right {
   width: calc(55% - 20px);
   position: relative;
   height: 70vh;
   .image {
    width: 100%;
    border-radius: 0 20px 20px 0;
    img {
     height: 100%;
    }
   }
  }
 }

 @media only screen and (${MaxScreenDevice.tablet}) {
  .about {
   flex-direction: column-reverse;
   padding: 50px 0;
   gap: 20px;
   &__left,
   &__right {
    width: 100%;
   }
   &__left {
    &-heading {
     h3 {
      font-size: 20px;
     }
    }
    &-desc {
     p {
      font-size: 14px;
      max-width: 100%;
     }
    }
    &-button {
     margin-top: 20px;
     button {
      padding: 5px 10px;
      font-size: 14px;
      .button-plus {
       font-size: 14px;
      }
      &:hover {
       transform: translateY(5px);
       background-color: ${({ theme }) => theme.colors.main_yellow};
      }
     }
    }
   }
   &__right {
    height: 50vh;
    .image {
     border-radius: 0;
    }
   }
  }
 }
 @media only screen and (${MaxScreenDevice.laptop}) {
  padding: 20px;
 }
`;
