export const formatHref = (value: string) => {
 return value
  .toLowerCase()
  .normalize("NFD")
  .replace(/[\u0300-\u036f]/g, "")
  .replace(/đ/g, "d")
  .replace(/Đ/g, "D")
  .trim()
  .split(" ")
  .join("-");
};
