import NotFoundCommon from "common/NotFound";
import { LoginComponent } from "components/Auth/LoginComponent";
import { AdminLayout, AuthLayout, ClientLayout } from "layouts";
import React from "react";
import { Route, Routes } from "react-router-dom";
function App() {
 return (
  <Routes>
   <Route path='auth/sign-in' element={<LoginComponent />} />
   <Route path='admin/*' element={<AdminLayout />} />
   <Route path='/*' element={<ClientLayout />} />
   <Route path='*' element={<NotFoundCommon />} />
  </Routes>
 );
}

export default App;
