import AboutUsComponent from "components/Home/HomeComponent/AboutUs.component";
import React from "react";
import EditContentAboutUs from "./edit/aboutUs/EditContentAboutUs";
import { Button, Empty, Form, Modal, Typography } from "antd";
import Heading from "components/admin/common/Header";
import { useQuery } from "react-query";
import { QUERY_ABOUT_US_ADMIN } from "helpers";
import { TDataAboutUs, getDataAboutUs } from "./edit/query";
import { UploadFile } from "antd/lib";
import { API_SERVER } from "helpers/variable";

const { Text, Title } = Typography;

const Component = () => {
 const [form] = Form.useForm();
 const [imageList, setImageList] = React.useState<UploadFile[]>([]);
 const [visibleModal, setVisibleModal] = React.useState<boolean>(false);
 const { data: aboutUsData, isLoading }: any = useQuery(
  QUERY_ABOUT_US_ADMIN,
  () => getDataAboutUs()
 );
 const handleSubmit = () => {
  form.submit();
 };
 const handleCloseModal = () => {
  setVisibleModal(false);
 };

 const handleOpenModal = (aboutUsData: TDataAboutUs[]) => {
  setVisibleModal(true);
  if (aboutUsData.length !== 0) {
   setFieldsValue(aboutUsData[0]);
  }
 };

 const setFieldsValue = (aboutUsData: TDataAboutUs) => {
  form.setFieldsValue(aboutUsData);
  setImageList([
   {
    uid: "0",
    name: aboutUsData.imageUrl,
    status: "done",
    url: API_SERVER + "/" + aboutUsData.imageUrl,
    thumbUrl: API_SERVER + "/" + aboutUsData.imageUrl,
   },
  ]);
 };
 return (
  <div>
   <Heading title='GIỚI THIỆU VỀ DTSolar'>
    <Button
     onClick={() => handleOpenModal(aboutUsData)}
     type='primary'
     icon={<i className='fa-solid fa-plus'></i>}
    >
     Cập nhật nội dung
    </Button>
   </Heading>
   <Modal
    open={visibleModal}
    width='80%'
    title='Cập nhật nội dung GIỚI THIỆU DTSolar'
    okText='Cập nhật'
    cancelText='Hủy'
    onOk={handleSubmit}
    onCancel={() => setVisibleModal(false)}
   >
    <EditContentAboutUs
     imageListUpdate={imageList}
     onCloseModal={handleCloseModal}
     form={form}
    />
   </Modal>
   {isLoading ? (
    <Empty />
   ) : (
    <>
     <AboutUsComponent admin={true} propsAdmin={aboutUsData} />
     <Title level={3}>GIỚI THIỆU VỀ DTSOLAR</Title>
     <div
      className='sun-editor-editable solution__content'
      dangerouslySetInnerHTML={{ __html: aboutUsData[0].contentPages }}
     />
    </>
   )}
  </div>
 );
};

export default Component;
