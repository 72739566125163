import { THeaderV2 } from "@types";
import React from "react";
import Menu from "./Menu";
import { theme } from "theme";
import infoCompany from "assets/json/Infomation";
import { useNavigate } from "react-router-dom";

type TProps = {
 dataPage: THeaderV2[];
};
const stylesNav = {
 display: "flex",
 alignItems: "center",
 gap: 10,
};
const stylesInput = {
 outline: "none",
 height: 25,
 outlineStyle: "none",
 border: "1px solid",
 borderRadius: 5,
 padding: "0 5px",
};
const styleFontSize = {
 fontSize: 15,
};

const HeaderTop = ({ dataPage }: TProps) => {
 const [valueSearch, setValueSearch] = React.useState<string>("");
 const navigate = useNavigate();
 const handleSearch = (value: string) => {
  navigate(`/ket-qua-tim-kiem/${value}`);
 };
 const handleKeyDown = (event: any) => {
  if (event.key === "Enter") {
   handleSearch(valueSearch);
  }
 };

 return (
  <Menu
   dataNavbar={dataPage}
   styleNavbar={{
    active: true,
    fontStyle: {
     fontSize: "14px",
     fontWeight: 400,
     textTransform: "capitalize",
    },
    color: theme.colors.black,
    backgroundColor: theme.colors.white,
    colorHover: theme.colors.main_yellow,
    paddingRight: true,
   }}
   element={
    <>
     <li className='nav__item'>
      <a href={`mailto:${infoCompany.email}`} style={stylesNav}>
       <i style={styleFontSize} className='fa-regular fa-envelope '></i>
       <p
        style={{
         textTransform: "none",
        }}
       >
        {infoCompany.email}
       </p>
      </a>
     </li>
     <li className='nav__item'>
      <a
       href={`tel:+$${infoCompany.phoneNumber}`}
       style={stylesNav}
       className='nav__item'
      >
       <i style={styleFontSize} className='fa-solid fa-phone'></i>
       <p
        style={{
         textTransform: "none",
        }}
       >
        {infoCompany.phoneNumber}
       </p>
      </a>
     </li>
     <li className='nav__item'>
      <div style={stylesNav} className='nav__item'>
       <i
        style={{ fontSize: 15, cursor: "pointer" }}
        onClick={() => handleSearch(valueSearch)}
        className='fa-solid fa-magnifying-glass'
       ></i>
       <input
        type='text'
        style={stylesInput}
        onChange={(value) => setValueSearch(value.target.value)}
        onKeyDown={handleKeyDown}
        placeholder='Tìm kiếm ...'
       />
      </div>
     </li>
    </>
   }
  />
 );
};

export default HeaderTop;
