import { Button, Flex, Input, Select, Table } from "antd";
import Heading from "components/admin/common/Header";
import { QUERY_PRODUCT_ADMIN, getQueryHelper } from "helpers";
import React from "react";
import { useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { columns } from "./column";
import { getProductQuery } from "./query";
import _ from "lodash";

export type TProduct = {
 productId: string;
 productName: string;
 productCode: string;
 productHref: string;
 productTrademark: string | null;
 productImages: string;
 productParameter: string;
 productDescription: string;
 productActive: 0 | 1;
 categoryTitle: string;
 categoryCode: string;
};
type TCategoryOption = {
 label: string;
 value: string;
};
type TValueSearch = {
 name: string;
 category: string;
 status: string;
};

const optionStatus: TCategoryOption[] = [
 {
  label: "Tất cả",
  value: "2",
 },
 {
  label: "Chưa công khai",
  value: "0",
 },
 {
  label: "Công khai",
  value: "1",
 },
];
const Component = () => {
 const navigate = useNavigate();
 const [categoryOption, setCategoryOption] = React.useState<TCategoryOption[]>(
  []
 );
 const [dataProduct, setDataProduct] = React.useState<TProduct[]>([]);
 const [valueSearchProduct, setValueSearchProduct] =
  React.useState<TValueSearch>({
   category: "all",
   name: "",
   status: "2",
  });

 const { data, isLoading }: any = useQuery(QUERY_PRODUCT_ADMIN, () =>
  getProductQuery()
 );

 const onGetDataCategory = async () => {
  const categoryData: any = await getQueryHelper("/category/get-all-category");
  const options = categoryData.map(
   (category: { title: string; href: string; id: string }) => ({
    label: category.title,
    value: category.id,
   })
  );
  setCategoryOption([{ label: "Tất cả", value: "all" }, ...options]);
 };

 const handleSearchProduct = (dataProduct: TProduct[]) => {
  if (
   valueSearchProduct.name === "" &&
   valueSearchProduct.category === "all" &&
   Number(valueSearchProduct.status) === 2
  ) {
   return setDataProduct(dataProduct);
  }
  const resultName = dataProduct.filter(
   (e: TProduct) =>
    e.productName
     .split(" ")
     .join("")
     .includes(valueSearchProduct.name.split(" ").join("").trim()) &&
    (valueSearchProduct.category !== "all"
     ? e.categoryCode === valueSearchProduct.category
     : e.categoryCode) &&
    (valueSearchProduct.status !== "2"
     ? e.productActive === Number(valueSearchProduct.status)
     : e.productActive === 1 || e.productActive === 0)
  );
  setDataProduct(resultName);
 };

 React.useEffect(() => {
  if (data) {
   handleSearchProduct(data);
  }
  onGetDataCategory();
 }, [data]);

 return (
  <div>
   <Heading title='Tất cả sản phẩm'>
    <Button
     onClick={() => navigate("add")}
     type='primary'
     icon={<i className='fa-solid fa-plus'></i>}
    >
     Thêm sản phẩm
    </Button>
   </Heading>
   <Flex justify='start' style={{ margin: "20px 0" }} gap={10}>
    <Input
     placeholder='Tìm kiếm theo tên sản phẩm'
     style={{ height: 40, width: 200 }}
     onChange={(value) =>
      setValueSearchProduct((prev: TValueSearch) => ({
       ...prev,
       name: value.target.value,
      }))
     }
    />
    <Select
     style={{ height: 40, width: 200, cursor: "pointer" }}
     placeholder='Loại sản phẩm'
     optionFilterProp='children'
     options={categoryOption}
     onChange={(value) =>
      setValueSearchProduct((prev: TValueSearch) => ({
       ...prev,
       category: value,
      }))
     }
    />
    <Select
     style={{ height: 40, width: 200, cursor: "pointer" }}
     placeholder='Trạng thái'
     optionFilterProp='children'
     options={optionStatus}
     onChange={(value) =>
      setValueSearchProduct((prev: TValueSearch) => ({
       ...prev,
       status: value,
      }))
     }
    />
    <Button
     onClick={() => handleSearchProduct(data)}
     type='primary'
     style={{ height: 40 }}
    >
     Lọc sản phẩm
    </Button>
   </Flex>
   <Table loading={isLoading} columns={columns} dataSource={dataProduct} />
  </div>
 );
};

export default Component;
