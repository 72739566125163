import styled from "styled-components";
export const StyleHeaderAdmin = styled.div`
 display: flex;
 width: 100%;
 height: 100%;
 align-items: center;
 justify-content: space-between;
 .header {
  &__right {
   display: flex;
   align-items: center;
   gap: 30px;
   i {
    color: ${({ theme }) => theme.colors.gray[700]};
    font-size: 20px;
   }
  }
 }
`;
