import React from "react";
import LocationVN from "jsons/location_vn.json";
import { ContentModalStyled } from "assets/styles/V2/HouseHold/SolutionPackage";
import { Col, Form, Input, Modal, Row, Select, message } from "antd";
import PhoneInput from "antd-phone-input";
import TextArea from "antd/es/input/TextArea";
import _ from "lodash";
import moment from "moment";
import { postQueryHelper } from "helpers";
import LoadingV2 from "common/V2/LoadingV2";

type TProps = {
 setCloseModal: Function;
 visible: boolean;
 dataCustomize?: {
  [key: string]: string;
 } | null;
};

type TFormValues = {
 name: string;
 phoneNumber: {
  areaCode: string | null;
  phoneNumber: string | null;
 };
 email: string;
 notes: string;
 electricityAmount: string;
 city: string;
};

const Options = [
 {
  value: "<1",
  label: "Dưới 1.000.000đ",
 },
 {
  value: "1-1.5",
  label: "Từ 1.000.000đ - 1.500.000đ",
 },
 {
  value: "1.5-2",
  label: "Từ 1.500.000đ - 2.000.000đ",
 },
 {
  value: "2-2.5",
  label: "Từ 2.000.000đ - 2.500.000đ",
 },
 {
  value: "2.5-3",
  label: "Từ 2.500.000đ - 3.000.000đ",
 },
 {
  value: ">3",
  label: "Trên 3.000.000đ",
 },
];

const ContactModal = ({
 setCloseModal,
 visible,
 dataCustomize = null,
}: TProps) => {
 const [optionCity, setOptionCity] =
  React.useState<{ value: string; label: string }[]>();
 const [isLoading, setIsLoading] = React.useState<boolean>(false);
 const [form] = Form.useForm();

 const formatOptionsCity = () => {
  const location: any = LocationVN;
  const option = location.map((location: any) => {
   return {
    value: location.code,
    label: location.name,
   };
  });
  setOptionCity(option);
 };

 const formatValueSearch = (value: any) => {
  return value
   .toLowerCase()
   .normalize("NFD")
   .replace(/[\u0300-\u036f]/g, "")
   .replace(/đ/g, "d")
   .replace(/Đ/g, "D");
 };

 const onSendConsultation = async (values: TFormValues) => {
  if (!values.phoneNumber?.areaCode && !values.phoneNumber?.phoneNumber)
   return message.warning("Số điện thoại không đúng định dạng");
  setIsLoading(true);
  const address = _.find(optionCity, { value: values.city })?.label;
  const electricityAmount = _.find(Options, {
   value: values.electricityAmount,
  })?.label;
  const customerInformation = {
   "Họ tên": values.name,
   "Số điện thoại": `0${values.phoneNumber.areaCode}${values.phoneNumber.phoneNumber}`,
   "Địa chỉ": address,
   Email: values.email,
   "Số tiền điện hàng tháng": electricityAmount,
  };
  let messageSend: string = JSON.stringify({ "Tin nhắn": values.notes });
  if (dataCustomize)
   messageSend = JSON.stringify({ ...dataCustomize, "Tin nhắn": values.notes });

  const time = moment().format("DD/MM/YYYY HH:mm:ss");
  const parameters = {
   title: "TƯ VẤN VÀ BÁO GIÁ",
   customerInformation: JSON.stringify(customerInformation),
   message: messageSend,
   time,
  };
  const response: any = await postQueryHelper(
   "/message/send-information-to-server",
   parameters
  );
  if (response.type == "error") {
   return message.error(response.message);
  }
  form.resetFields();
  message.success(
   "Gửi thông tin thành công. Chúng tôi sẽ liên hệ lại bạn trong thời gian sớm nhất"
  );
  setIsLoading(false);
  setTimeout(() => {
   setCloseModal(false);
  }, 1000);
 };

 React.useEffect(() => {
  formatOptionsCity();
 }, []);

 return (
  <Modal
   className='modal-contact'
   open={visible}
   onCancel={() => setCloseModal(false)}
   footer={null}
  >
   <ContentModalStyled>
    <div className='modal-content__heading'>
     <h2>TƯ VẤN & BÁO GIÁ</h2>
    </div>
    {isLoading ? (
     <LoadingV2 />
    ) : (
     <Form
      form={form}
      onFinish={onSendConsultation}
      layout='vertical'
      className='modal-content__form'
      autoComplete='off'
     >
      <Row gutter={[12, 12]} className='modal-content__form-flex'>
       <Col span={12} className='modal-content__form-input'>
        <Form.Item label='Tên của bạn' name='name' rules={[{ required: true }]}>
         <Input className='form-input' />
        </Form.Item>
       </Col>
       <Col span={12} className='modal-content__form-input'>
        <Form.Item
         label='Số điện thoại'
         name='phoneNumber'
         rules={[{ required: true }]}
        >
         <PhoneInput className='form-input' onlyCountries={["vn"]} />
        </Form.Item>
       </Col>
      </Row>
      <Row gutter={[12, 12]} className='modal-content__form-flex'>
       <Col span={8} className='modal-content__form-input'>
        <Form.Item label='Email' name='email' rules={[{ required: true }]}>
         <Input className='form-input' type='email' />
        </Form.Item>
       </Col>
       <Col span={8} className='modal-content__form-input'>
        <Form.Item
         label='Tỉnh / Thành phố'
         name='city'
         rules={[{ required: true }]}
        >
         <Select
          showSearch
          placeholder='Tìm kiếm Tỉnh / Thành Phố'
          optionFilterProp='children'
          filterOption={(input, option) =>
           formatValueSearch(option?.label ?? "").includes(
            formatValueSearch(input)
           )
          }
          className='form-input'
          options={optionCity}
         />
        </Form.Item>
       </Col>
       <Col span={8} className='modal-content__form-input'>
        <Form.Item
         label='Số tiền điện mỗi tháng'
         name='electricityAmount'
         rules={[{ required: true }]}
        >
         <Select className='form-input' options={Options} />
        </Form.Item>
       </Col>
      </Row>
      <Form.Item label='Yêu cầu' name='notes'>
       <TextArea
        style={{
         height: 100,
        }}
        className='form-input'
       />
      </Form.Item>
      <div className='button-send'>
       <button>GỬI THÔNG TIN TƯ VẤN</button>
      </div>
     </Form>
    )}
   </ContentModalStyled>
  </Modal>
 );
};

export default ContactModal;
