import { MaxScreenDevice } from "assets/screens/DeviceScreens";
import styled from "styled-components";
export const NewsHomeStyled = styled.div`
 padding: 50px 0;
 background-color: #ececec;
 .news {
  &__heading {
   display: flex;
   align-items: center;
   justify-content: space-between;
   h2 {
    color: ${({ theme }) => theme.colors.main_green};
    font-size: 30px;
    font-weight: 600;
    text-align: center;
    text-transform: uppercase;
   }
  }
  &__navigation-custom {
   display: flex;
   align-items: center;
   gap: 10px;
   button {
    background-color: ${({ theme }) => theme.colors.main_green};
    padding: 7px;
    border: 1px solid ${({ theme }) => theme.colors.main_green};
    color: ${({ theme }) => theme.colors.white};
    border-radius: 3px;
    transition: all 0.4s ease;
    cursor: pointer;
    &:hover {
     background-color: transparent;
     color: ${({ theme }) => theme.colors.main_green};
    }
    i {
     font-size: 18px;
    }
   }
  }
  &__card {
   &-item {
    background-color: ${({ theme }) => theme.colors.white};
    margin: 50px 0;
    cursor: pointer;
    transition: all 0.4s ease;
    .image {
     img {
      width: 100%;
      height: 100%;
      object-fit: cover;
     }
    }
    .content {
     padding: 20px;
     &__title {
      h3 {
       font-size: 15px;
       font-weight: 600;
       color: ${({ theme }) => theme.colors.black};
       display: -webkit-box;
       -webkit-line-clamp: 2;
       -webkit-box-orient: vertical;
       overflow: hidden;
       text-overflow: ellipsis;
       transition: all 0.4 ease;
      }
     }
     &__date {
      p {
       margin: 15px 0;
       font-size: 13px;
       color: ${({ theme }) => theme.colors.gray[400]};
       font-weight: 400;
      }
     }
     &__subtitle {
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
     }
    }
    &:hover {
     border-radius: 10px;
     box-shadow: 1px 2px 5px -2px rgba(0, 0, 0, 0.75);
     -webkit-box-shadow: 1px 2px 5px -2px rgba(0, 0, 0, 0.75);
     -moz-box-shadow: 1px 2px 5px -2px rgba(0, 0, 0, 0.75);
     .content {
      &__title {
       h3 {
        color: ${({ theme }) => theme.colors.main_green};
       }
      }
     }
    }
   }
  }
 }
 @media only screen and (${MaxScreenDevice.custom(800)}) {
  margin-top: 10px;
  padding: 0 20px;
  .news {
   &__heading {
    flex-direction: column;
    justify-content: center;
    h2 {
     font-size: 23px;
    }
   }
   &__navigation-custom {
    margin-top: 10px;
    button {
     padding: 5px;
    }
   }
   &__card {
    &-item {
     margin: 20px 0;
    }
   }
  }
 }
`;
