import { Col, Form, FormInstance, Input, Row, message } from "antd";
import TextEditor from "common/TextEditor";
import { formatHref } from "components/admin/common/formatHref";
import React from "react";
import { addSolutionQuery, updateSolutionQuery } from "./query";
import { useQueryClient } from "react-query";
import { QUERY_SOLUTION_ADMIN } from "helpers";

type TProps = {
 form: FormInstance;
 onClose: () => void;
 type: "update" | "add";
 id: string;
};

type FormInputSolution = {
 solutionName: string;
 solutionHref: string;
};

const FormInputSolution = ({
 form,
 onClose,
 type = "add",
 id = "",
}: TProps) => {
 const onChangeSolutionName = (
  e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
 ) => {
  form.setFieldsValue({
   solutionHref: formatHref(e.target.value),
  });
 };

 const onFinishForm = async (values: FormInputSolution) => {
  let response: any;
  if (type === "add") {
   response = await addSolutionQuery({
    title: values.solutionName,
    href: values.solutionHref,
   });
  } else {
   response = await updateSolutionQuery(
    {
     title: values.solutionName,
     href: values.solutionHref,
    },
    id
   );
  }
  if (response?.type === "error") {
   return message.error(response.message);
  }
  message.success("Thêm danh mục thành công");
  setTimeout(() => {
   form.resetFields();
   onClose();
  }, 1000);
 };

 return (
  <Form form={form} onFinish={onFinishForm} layout='vertical'>
   <Form.Item
    label='Giải pháp:'
    name='solutionName'
    rules={[{ required: true }]}
   >
    <Input onChange={onChangeSolutionName} />
   </Form.Item>
   <Form.Item
    label='Đường dẫn:'
    name='solutionHref'
    rules={[{ required: true }]}
   >
    <Input />
   </Form.Item>
  </Form>
 );
};

export default FormInputSolution;
