export type TMenuChild = {
 id: string;
 idParent: string | null;
 title: string;
 href: string;
};
export type TMenuHeader = {
 headerTop: TMenuChild[];
 headerNavbar: TMenuChild[];
};

const MenuHeader: TMenuHeader = {
 headerTop: [
  {
   id: "85e82812-8fdd-4360-bb91-4caa0e5c5b93",
   idParent: null,
   title: "Giới thiệu",
   href: "gioi-thieu",
  },
 ],
 headerNavbar: [
  {
   id: "c6b819af-0c3d-49a1-bf78-460b7b4bddb3",
   idParent: null,
   title: "Doanh nghiệp",
   href: "doanh-nghiep",
  },
  // {
  //  id: "1072e5ff-0535-4aa4-8379-4f17d55d7b9b",
  //  idParent: null,
  //  title: "Hộ gia đình",
  //  href: "ho-gia-dinh",
  // },
  {
   id: "8d037115-1699-4c46-8feb-874166ea183f",
   idParent: null,
   title: "Tin tức",
   href: "tin-tuc",
  },
  {
   id: "deb4920a-5467-444d-8a78-b8f734004836",
   idParent: null,
   title: "Giải pháp",
   href: "giai-phap",
  },
  {
   id: "da0f52e2-3df2-4233-bf6f-dc3a18d236d7",
   idParent: null,
   title: "Sản phẩm",
   href: "san-pham",
  },
  {
   id: "c77098fe-79e1-4aac-8af2-8aae37808514",
   idParent: null,
   title: "Liên hệ",
   href: "lien-he",
  },
 ],
};
export default MenuHeader;
