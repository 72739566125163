import infoCompany from "assets/json/Infomation";
import { ButtonFloatPhoneStyled } from "assets/styles/page/buttonFloat/_ButtonFloat";
import ContactInfo from "common/ContactInfo";
import { IconPhone } from "icons";
import React from "react";
import { useNavigate } from "react-router-dom";

const ButtonFloatPhone = () => {
 return (
  <ButtonFloatPhoneStyled>
   <a href={`tel:+${infoCompany.phoneNumber}`} className='pulsate-bck'>
    <IconPhone className={"icon"} />
   </a>
  </ButtonFloatPhoneStyled>
 );
};

export default ButtonFloatPhone;
