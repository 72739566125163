import { TNews, TProduct, TSolution } from "@types";

import { CardsStyled } from "assets/styles/_Card";
import Card from "common/Card";
import Container from "common/Container";
import _ from "lodash";
import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { RootState } from "store";
import { ResultSearchPages } from "./styled";
import NewCard from "../news/Card";

type TArrayMergeData = {
 title: string;
 href: string;
 type: "Product" | "News";
 summary?: string;
 image: string | null;
};

interface TResultSearch extends TArrayMergeData {
 product?: TProduct;
 news?: TNews;
}

interface TResultState {
 product: TProduct[];
 news: TNews[];
}

const SearchAll = () => {
 const [resultSearch, setResultSearch] = React.useState<TResultState | null>(
  null
 );
 const { slug } = useParams();
 const { product } = useSelector((state: RootState) => state.product);
 const { solution } = useSelector((state: RootState) => state.solution);
 const { news } = useSelector((state: RootState) => state.news);

 const searchValue = (product: TProduct[], news: TNews[]) => {
  const array: TResultSearch[] = [];
  const result: TResultState = {
   product: [],
   news: [],
  };
  product.map((product: TProduct) =>
   array.push({
    title: product.productName,
    href: product.productHref,
    type: "Product",
    image: product.productImages[0],
    product,
   })
  );
  news.map((newItem: TNews) =>
   array.push({
    title: newItem.title,
    href: newItem.href,
    type: "News",
    image: newItem.imageThumbnail,
    summary: newItem.summary,
    news: newItem,
   })
  );
  array.map((item: TResultSearch) => {
   const includes = _.includes(
    item.title
     .toLowerCase()
     .normalize("NFD")
     .replace(/[\u0300-\u036f]/g, "")
     .replace(/đ/g, "d")
     .replace(/Đ/g, "D")
     .trim(),
    slug
     ?.toLowerCase()
     .normalize("NFD")
     .replace(/[\u0300-\u036f]/g, "")
     .replace(/đ/g, "d")
     .replace(/Đ/g, "D")
     .trim()
   );
   if (includes) {
    if (item.type === "Product" && item.product) {
     result.product.push(item.product);
    } else if (item.type === "News" && item.news) {
     result.news.push(item.news);
    }
   }
  });
  setResultSearch(result);
 };
 React.useEffect(() => {
  if (product && news) {
   searchValue(product, news);
  }
 }, [product, news, slug]);

 return (
  <ResultSearchPages>
   <Container>
    <h1>Kết quả tìm kiếm</h1>
    {resultSearch && (
     <div className='result'>
      {resultSearch.news.length > 0 && (
       <>
        <h3 className='result-title'>TIN TỨC</h3>
        <CardsStyled numberColumn={3}>
         {resultSearch.news.map((item: TNews) => {
          return <NewCard newItem={item} key={item.id} />;
         })}
        </CardsStyled>
       </>
      )}
      {resultSearch.product.length > 0 && (
       <>
        <h3 className='result-title'>SẢN PHẨM</h3>
        <CardsStyled numberColumn={3}>
         {resultSearch.product.map((item: TProduct) => {
          return <Card item={item} key={item.productId} />;
         })}
        </CardsStyled>
       </>
      )}
     </div>
    )}
   </Container>
  </ResultSearchPages>
 );
};

export default SearchAll;
