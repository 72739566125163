import React from "react";
import parse from "html-react-parser";
import { Empty, Tabs, TabsProps, Typography, Divider } from "antd";

const { Title } = Typography;

const Description = ({ description }: { description: string }) => {
 const itemsTabs: TabsProps["items"] = [
  {
   key: "1",
   label: (
    <Title level={3} style={{ textTransform: "uppercase" }}>
     Mô tả sản phẩm
    </Title>
   ),
   children: (
    <>
     {description ? (
      <>{parse(description)}</>
     ) : (
      <>
       <Empty description={<span>KHÔNG CÓ DỮ LIỆU</span>}></Empty>
       <Divider />
      </>
     )}
    </>
   ),
  },
 ];
 return (
  <div style={{ margin: "100px 0 50px" }}>
   <Tabs className='tabs' defaultActiveKey='1' items={itemsTabs} />
  </div>
 );
};

export default Description;
