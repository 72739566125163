import {
 deleteQueryHelper,
 getQueryHelper,
 patchQueryHelper,
 postQueryHelper,
} from "helpers";
import { TNewsDataAdmin } from "./ContentModal";

export const addNewsData = (parameters: TNewsDataAdmin) => {
 return postQueryHelper("/news/add", parameters);
};
export const getNewsData = () => {
 return getQueryHelper("/news/admin/get-all-news");
};
export const updateNewsData = (parameters: TNewsDataAdmin, id: string) => {
 return patchQueryHelper(`/news/update-news-data/${id}`, parameters);
};
export const updateActiveNewsData = (
 parameters: { active: number },
 id: string
) => {
 return patchQueryHelper(`/news/update-news-data/${id}`, parameters);
};
export const deleteData = (id: string) => {
 return deleteQueryHelper(`/news/delete-new-data/${id}`);
};
