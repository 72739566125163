import { MaxScreenDevice } from "assets/screens/DeviceScreens";
import styled from "styled-components";
export const BestSellerStyled = styled.div`
 margin: 100px 0;
 .bestseller {
  width: 100%;
  &__left {
   display: flex;
   align-items: center;
   justify-content: center;
   flex-direction: column;
   &-heading {
    h3 {
     text-align: center;
     font-size: 30px;
     font-weight: 600;
     text-transform: uppercase;
    }
    p {
     margin: 15px 0;
     font-size: 16px;
    }
   }
   &-tabs {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin-top: 20px;
    .item {
     cursor: pointer;
     transition: all 0 4s ease;
     padding: 20px;
     border-radius: 4px;
     border: 1px solid ${({ theme }) => theme.colors.gray[300]};
     .title {
      h3 {
       text-align: center;
       font-size: 16px;
       font-weight: 500;
       text-transform: uppercase;
      }
     }
     &:hover,
     &.active {
      border-color: ${({ theme }) => theme.colors.main_blue};
      h3 {
       color: ${({ theme }) => theme.colors.main_blue};
      }
     }
    }
   }
  }
  &__right {
   position: relative;
   margin-top: 50px;
   padding-left: 20px;
   display: grid;
   grid-template-columns: repeat(2, 1fr);
   gap: 20px;
   &-item {
    display: flex !important;
    align-items: start;
    justify-content: start;
    gap: 20px;
    border: 1px solid ${({ theme }) => theme.colors.gray[300]};
    padding: 20px 10px;
    margin-top: 0 !important;
    &-image {
     width: 35%;
     height: 100%;
    }
    &-content {
     width: calc(65% - 20px);
     height: 100%;
     display: flex;
     align-items: start;
     justify-content: space-around;
     flex-direction: column;
     gap: 10px;
     button {
      background-color: ${({ theme }) => theme.colors.main_yellow};
      padding: 10px 20px;
      border: none;
      border-radius: 10px;
      font-size: 13px;
      font-weight: 600;
      transition: all 0.4s ease;
      transform: translate(0);
      cursor: pointer;
      display: flex;
      align-items: center;
      gap: 10px;
      &:hover {
       transform: translateY(2px);
       background-color: ${({ theme }) => theme.colors.main_blue};
       color: ${({ theme }) => theme.colors.white};
      }
     }

     .title {
      h4 {
       font-size: 18px;
       font-weight: 500;
       text-transform: uppercase;
      }
     }
     .summary {
      p {
       font-size: 15px;
       display: -webkit-box;
       -webkit-line-clamp: 3;
       -webkit-box-orient: vertical;
       overflow: hidden;
       text-overflow: ellipsis;
      }
     }
     .category {
      p {
       margin: 10px 0;
      }
     }
    }
   }
  }
 }

 @media only screen and (${MaxScreenDevice.tablet}) {
  padding: 0 20px;
  width: calc(100% - 40px);
  .bestseller {
   &__left {
    &-tabs {
     grid-template-columns: repeat(2, 1fr);
    }
   }
   &__right {
    grid-template-columns: repeat(1, 1fr);
    &-item {
     &-image {
      width: 55% !important;

      .image {
       width: 100% !important;
       /* height: 150px !important; */
      }
     }
     &-content {
      button {
       font-size: 10px;
      }
     }
    }
   }
  }
 }
 @media only screen and (${MaxScreenDevice.mobileL}) {
  .bestseller {
   &__right {
    padding-left: 0;
    &-item {
     flex-direction: column;
     &-image {
      width: 100% !important;
      .ant-image {
       width: 100% !important;
       height: 300px !important;
      }
      img {
       height: 100% !important;
       width: 100% !important;
      }
     }
     &-content {
      width: 100%;
     }
    }
   }
  }
 }
`;
