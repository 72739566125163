import { ListProductsStyled } from "assets/styles/page";
import React from "react";
import ProductPortfolio from "./ProductPortfolio";
import Container from "common/Container";
import { CardsStyled } from "assets/styles/_Card";
import Card from "common/Card";
import { useSelector } from "react-redux";
import { RootState } from "store";
import Loading from "common/Loading";
import { TProduct } from "@types";
import _ from "lodash";
import { Empty, Flex, Pagination } from "antd";
import PaginationComponent from "common/Pagination";

const Components = () => {
 const [dataProductList, setDataProductList] = React.useState<TProduct[]>([]);
 const [dataCurrent, setDataCurrent] = React.useState<TProduct[]>([]);
 const { product, isLoading } = useSelector(
  (state: RootState) => state.product
 );

 const searchProduct = React.useCallback(
  (type: "category" | "name", value: string) => {
   if (type === "category") {
    if (value === "all") return setDataProductList(product);
    const result = _.filter(product, { categoryCode: value });
    setDataProductList(result);
   } else if (type === "name") {
    if (value === "") return setDataProductList(product);
    const result: TProduct[] = [];
    _.map(product, (p: TProduct) => {
     const includes = _.includes(
      p.productName.toLowerCase(),
      value.toLowerCase()
     );
     if (includes) {
      result.push(p);
     }
    });
    setDataProductList(result);
   }
  },
  [product]
 );
 const scrollToTop = () => {
  window.scrollTo({
   top: 0,
   left: 0,
   behavior: "smooth",
  });
 };

 React.useEffect(() => {
  scrollToTop();
 }, []);
 React.useEffect(() => {
  if (!isLoading && product) {
   setDataProductList(product);
  }
 }, [product, isLoading]);

 return (
  <ListProductsStyled>
   {isLoading ? (
    <Loading />
   ) : (
    <Container>
     <h1>DANH SÁCH SẢN PHẨM</h1>
     <div className='list-product'>
      <div className='list-product__left'>
       <div className='search'>
        <input
         type='text'
         onChange={(event) => searchProduct("name", event.target.value)}
         placeholder='Tìm kiếm sản phẩm'
        />
        <i className='fa-solid fa-magnifying-glass'></i>
       </div>
       <ProductPortfolio searchProduct={searchProduct} />
      </div>
      <div className='list-product__cards'>
       {dataProductList.length === 0 ? (
        <Empty description={<span>KHÔNG CÓ DỮ LIỆU</span>}></Empty>
       ) : (
        <>
         <CardsStyled numberColumn={3}>
          {dataCurrent?.map((product: TProduct) => {
           return <Card item={product} key={product.productId} />;
          })}
         </CardsStyled>
         <PaginationComponent
          dataAll={dataProductList}
          dataCurrent={setDataCurrent}
          total={dataProductList.length}
          numberView={9}
         />
        </>
       )}
      </div>
     </div>
    </Container>
   )}
  </ListProductsStyled>
 );
};

export default Components;
