/* eslint-disable import/no-anonymous-default-export */
export default {
 image:
  "https://images.unsplash.com/photo-1615506355925-dd0a54d099dd?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
 contactInformation: [
  {
   label: "Email",
   content: "nthao250900@gmail.com",
   href: "",
   icon: null,
  },
  {
   label: "Địa chỉ:",
   content: "408 Mai Đăng Chơn, Đà Nẵng",
   href: "",
   icon: null,
  },
  {
   label: "Số điện thoại:",
   content: "0559352509",
   href: "",
   icon: null,
  },
 ],
 social: [
  {
   icon: '<i class="fa-brands fa-facebook"></i>',
   href: "#",
  },
  {
   icon: '<i class="fa-brands fa-youtube"></i>',
   href: "#",
  },
 ],
};
