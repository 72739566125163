import { MaxScreenDevice } from "assets/screens/DeviceScreens";
import styled from "styled-components";

interface TNavbar {
 active: boolean;
 fontStyle: {
  fontSize: string;
  fontWeight: number;
  textTransform: string;
 };
 color: string;
 backgroundColor: string;
 colorHover: string;
 paddingRight: boolean;
}
export const NavbarStyled = styled.nav<TNavbar>`
 height: 50%;
 background-color: ${({ backgroundColor }) => backgroundColor};
 transition: all 0.3s ease;
 border-radius: 50px 0 0 0;
 padding-left: 100px;
 .navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 70px;
  &__menu {
   display: flex;
   align-items: center;
   gap: 20px;

   .nav {
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    background-color: ${({ backgroundColor }) => backgroundColor};
    gap: 20px;
    justify-content: flex-start;
    align-items: center;
    display: flex;
    &__item {
     position: relative;
     z-index: 99;
     border-right: ${({ paddingRight }) =>
      paddingRight ? "1px solid #000" : "none"};
     padding-right: 20px;
     &:last-child {
      border-right: none;
     }
     a,
     p {
      display: block;
      position: relative;
      text-decoration: none;
      color: ${({ color }) => color};
      font-size: ${({ fontStyle }) => fontStyle.fontSize};
      font-weight: ${({ fontStyle }) => fontStyle.fontWeight};
      margin: 0;
      text-transform: ${({ fontStyle }) => fontStyle.textTransform};
      z-index: 1;
      cursor: pointer;
      &::after {
       position: absolute;
       content: "";
       width: 0;
       bottom: 0;
       left: 0;
       height: 1px;
       transition: all 0.5s;
      }
      &:hover {
       text-decoration: none !important;
       color: ${({ theme }) => theme.colors.orange[500]};
      }
      &.active {
       color: ${({ theme }) => theme.colors.main_yellow};
      }
     }
     .transition {
      transition: 0.3s ease-out;
      &:hover {
       text-decoration: none !important;
      }
     }
     &:hover > a:after {
      width: calc(100% - 46px);
      left: 23px;
     }
    }
    .menu__line {
     position: static !important;
     &:hover .sub {
      opacity: 1;
      visibility: visible;
      transform: translateY(0);
     }
     .sub {
      position: absolute;
      top: 130px;
      background-color: ${({ backgroundColor }) => backgroundColor};
      visibility: hidden;
      opacity: 0;
      transition: all 0.4s ease;
      transform: translateY(-20px);
      &__ctt {
       background-color: ${({ backgroundColor }) => backgroundColor};
       &__item {
        width: 100%;
        background-color: ${({ backgroundColor }) => backgroundColor};
        border-bottom: 1px solid ${({ theme }) => theme.colors.gray[300]};
        &:last-child {
         border-bottom: none;
        }
        &-list {
         width: 100%;
         padding: 0px;
         margin: 0;
         list-style: none;
         text-transform: uppercase;
         li {
          position: relative;
          z-index: 99;
          list-style: none;
          float: none !important;
          padding: 10px 20px;
          padding: 0 !important;
          display: list-item;
          text-transform: uppercase;
          text-align: -webkit-match-parent;
          a {
           padding: 10px 20px;
          }
          & > a {
           display: block;
           position: relative;
           z-index: 1;
           padding: 10px 20px;
           background-color: ${({ backgroundColor }) => backgroundColor};
           text-transform: none;
           font-size: 14px;
           font-weight: 500;
           text-transform: capitalize;
           transition: all 0.4s ease;
           &:hover {
            background-color: ${({ theme }) => theme.colors.gray[100]};
           }
          }
         }
        }
       }
      }
     }
    }
    .search-icon {
     color: ${({ theme }) => theme.colors.green[400]};
     transition: color 0.4s ease;
     cursor: pointer;
     position: relative;
     height: 40px;
     input {
      height: 100%;
      width: 200px;
      outline: none;
      border-radius: 5px;
      background-color: transparent;
      border: 1px solid ${({ theme }) => theme.colors.gray[300]};
      padding: 0 10px;
     }
     i {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 0;
      height: 100%;
      width: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-left: 1px solid ${({ theme }) => theme.colors.gray[300]};
     }
     /* &:hover {
     color: ${({ theme }) => theme.colors.green[700]};
    } */
    }
   }
  }
 }
 @media only screen and (${MaxScreenDevice.tablet}) {
  .navbar {
   padding: 0 20px;

   &__menu {
    .nav {
     display: none;
    }
   }
  }
 }
`;
