import { MaxScreenDevice } from "assets/screens/DeviceScreens";
import styled from "styled-components";
export const NewsPageStyled = styled.div`
 margin: 200px 0;
 .new-page {
  display: flex;
  align-items: start;
  gap: 20px;
  &__category {
   width: 25%;
  }
  &__content {
   width: calc(75% - 20px);
  }
 }
 @media only screen and (${MaxScreenDevice.tablet}) {
  margin: 100px 0;
  padding: 0 20px;
  .new-page {
   flex-direction: column-reverse;
   &__category {
    width: 100%;
   }
   &__content {
    width: 100%;
   }
  }
 }
`;

export const NewsCardStyle = styled.div`
 width: 100%;
 height: 100%;
 cursor: pointer;
 transition: all 0.4s ease;
 background-color: ${({ theme }) => theme.colors.gray[100]};
 border: 1px solid ${({ theme }) => theme.colors.gray[200]};
 .image {
  height: 200px;
  img {
   width: 100%;
   height: 100%;
   object-fit: cover;
  }
 }
 .content {
  padding: 20px;
  &__title {
   h3 {
    font-size: 15px;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.black};
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    transition: all 0.4 ease;
   }
  }
  &__date {
   p {
    margin: 15px 0;
    font-size: 13px;
    color: ${({ theme }) => theme.colors.gray[400]};
    font-weight: 400;
   }
  }
  &__subtitle {
   display: -webkit-box;
   -webkit-line-clamp: 3;
   -webkit-box-orient: vertical;
   overflow: hidden;
   text-overflow: ellipsis;
  }
 }
 &:hover {
  border-radius: 10px;
  box-shadow: 1px 2px 5px -2px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 1px 2px 5px -2px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 1px 2px 5px -2px rgba(0, 0, 0, 0.75);
  .content {
   &__title {
    h3 {
     color: ${({ theme }) => theme.colors.main_green};
    }
   }
  }
 }
`;
