import { MaxScreenDevice } from "assets/screens/DeviceScreens";
import styled from "styled-components";
export const ProcessStyled = styled.section`
 margin: 100px 0;
 .navigation-custom {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  gap: 10px;
  z-index: 2;
  background-color: transparent;
  button {
   width: 40px;
   height: 40px;
   background-color: ${({ theme }) => theme.colors.main_yellow};
   border: 1px solid ${({ theme }) => theme.colors.main_yellow};
   border-radius: 50%;
   cursor: pointer;
   transition: all 0.4s ease;
   i {
    font-size: 18px;
   }
   &:hover {
    background-color: transparent;
   }
  }
 }
 .process-slide {
  padding: 80px 0px;
 }
 .process {
  &__heading {
   h2 {
    font-size: 30px;
    font-weight: 600;
    text-align: center;
    text-transform: uppercase;
   }
  }
  &__carousel {
   margin: 50px 0;
   &-item {
    position: relative;
    background-color: ${({ theme }) => theme.colors.green[100]};
    .triangle {
     position: absolute;
     top: 0;
     left: 0;
     border-top: 90px solid ${({ theme }) => theme.colors.main_green};
     border-right: 90px solid transparent;
    }
    .step {
     position: absolute;
     top: 12px;
     left: 10px;
     h3 {
      font-size: 30px;
      color: ${({ theme }) => theme.colors.white};
      /* background-color: ${({ theme }) => theme.colors.main_green}; */
     }
     /* h3 {
      position: absolute;
      top: 0;
      left: 0;
     } */
    }
    .image {
     height: 250px;
    }
    .content {
     padding: 20px;
     min-height: 250px;
     .title {
      h3 {
       font-size: 20px;
       text-align: center;
      }
     }
     .desc {
      margin-top: 20px;
      p {
       text-align: center;
       font-size: 16px;
       margin-top: 10px;
       line-height: 160%;
      }
     }
    }
   }
  }
 }
 @media only screen and (${MaxScreenDevice.tablet}) {
  padding: 0 20px;
  margin: 50px 0;
 }
`;
