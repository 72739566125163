import { MaxScreenDevice } from "assets/screens/DeviceScreens";
import styled from "styled-components";

export const StyledDetailProduct = styled.div`
 min-height: 100vh;
 margin-top: 100px;
 .container {
  background-color: ${({ theme }) => theme.colors.white};
  .content {
   padding: 50px 0 0;
   .product {
    &__detail {
     width: 100%;
     min-height: 800px;
     display: flex;
     align-items: flex-start;
     gap: 30px;
    }
   }
   .brands {
    margin: 50px 0;
   }
  }
 }
 @media only screen and (${MaxScreenDevice.laptop}) {
  /* margin-top: 200px; */
  .container {
   .content {
    padding: 0 20px;
    /* width: calc(100% - 40px); */
   }
  }
 }
 @media only screen and (${MaxScreenDevice.custom(800)}) {
  .container {
   .content {
    padding: 0 20px;
    width: calc(100% - 40px);
    .product {
     &__detail {
      flex-direction: column-reverse;
      height: 100%;
     }
    }
    .brands {
     margin: 0;
    }
    .ant-typography {
     font-size: 15px;
    }
   }
  }
 }
 /* @media only screen and (${MaxScreenDevice.mobileL}) {
  .container {
   .content {
    .product {
     &__detail {
      flex-direction: column;
      height: 100%;
      .left,
      .right {
       width: 100%;
      }
      .left {
       flex-direction: column;
       height: 50vh;
       .carousel {
        width: 100%;
        height: 80%;
       }
      }
      .right {
       height: calc(100% - 50vh);
       .bottom {
        position: unset;
        margin-top: 20px;
       }
      }
     }
    }
   }
  }
 } */
`;
